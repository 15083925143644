import React from 'react';
import { css } from '@emotion/core';

import { DropdownOption } from '../../../core/components/form/DropDown';
import { Theme } from "../../../theme";
import DropDown from "../../../core/components/form/DropDown";

type Props = {
    currentPlanType: DropdownOption,
    onChange: Function,
    options: Array<DropdownOption>,
    selected: DropdownOption
};

const PlanSelectionUsers: React.FC<Props> = ({
    currentPlanType,
    onChange,
    options,
    selected,
}) => (
    <div css={containerStyle(currentPlanType.id === 'personal')}>
        { currentPlanType.id === 'family' && (
            <>
                <div css={dropdownStyle}>
                    <DropDown
                        extraChevronStyles={extraChevronStyles}
                        extraOptionContainerStyles={extraOptionContainerStyles}
                        extraOptionStyles={extraOptionStyles}
                        hasBorder={false}
                        options={options}
                        searchable={false}
                        selected={selected}
                        setSelected={onChange}
                    />
                </div>
                Users
            </>
        )}
        { currentPlanType.id === 'personal' && (
            <div css={singleUserStyle}>1 User</div>
        )}
    </div>
);

const containerStyle = (isPersonal: boolean) => (theme: Theme) => css`
    padding-left: 10px;
    width: 150px;
    height: 32px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: ${theme.fonts.weights.base};

    ${isPersonal && css`
        ${theme.breakpoints.down('xs')} {
            width: 120px;
            text-align: right;
        }
    `}
`;

const dropdownStyle = (theme: Theme) => css`
    cursor: pointer;
    width: 70px;
    height: 32px;
    margin-right: 10px;
    background-color: white;
    border-radius: ${theme.borderAndShadow.smallRadius};
    border: 1px solid ${theme.colours.grey[450]};
    text-align: center;
    z-index: 5;
    padding: 0 5px 0 10px;

    ${theme.breakpoints.down('xs')} {
        margin-right: 5px;
    }
`;

const singleUserStyle = (theme: Theme) => css`
    text-align: right;
    width: 113px;

    ${theme.breakpoints.down('xs')} {
        width: 100%;
        padding-right: 10px;
    }
`;

const extraChevronStyles = css`
    right: 0;
`;

const extraOptionContainerStyles = css`
    width: 68px;
    left: -10px;
    top: 31px;
`;

const extraOptionStyles = css`
    height: inherit;
    padding: 5px 5px 5px 21px;

    p {
        font-size: 16px;
    }
`;

export default PlanSelectionUsers;
