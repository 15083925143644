import React from "react";

type Props = {
    children: React.ReactNode,
    href: string,
    className?: string,
};

const FooterSocialItem: React.FC<Props> = ({
    children,
    href,
    className = "",
}) => (
    <div className={className}>
        <a href={href} target={"_blank"} rel="noopener noreferrer">{children}</a>
    </div>
);

export default FooterSocialItem;
