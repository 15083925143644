import React, { memo } from 'react';
import { Theme } from "../../../theme";

type AlignType = string | 'left' | 'center' | 'right';

type Props = {
    align?: AlignType,
    children: React.ReactNode,
};

const DialogActions: React.FC<Props> = ({ align, children }) => {

    const getTextAlign = (align?: AlignType) => {
        switch (align) {
            case "center":
                return "center" as "center";
            case "left":
                return "left" as "left";
            case "right":
            default:
                return "right" as "right";
        }
    }

    const styles = (theme: Theme) => ({
        paddingTop: '20px',
        textAlign: getTextAlign(align),

        '> button': {
            marginLeft: '5px',
        }
    });

    return (
        <div css={styles}>{children}</div>
    );
};

DialogActions.defaultProps = {
    align: 'right'
};


export default memo(DialogActions);
