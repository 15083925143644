import React from "react";
import { css } from "@emotion/core";

import theme from "../../../theme";
import BurgerNavLinkButtons from "./BurgerNavLinkButtons";
import { NOOP } from "../../../config/constants";
import { useAppState } from "../../contexts/AppContext";

type Props = {
    closeMenuHandler?: Function,
    menuButton?: Button,
    hideCustomButton?: boolean,
    buttonFontSize?: number,
};

const BurgerNavButtonContainer: React.FC<Props> = ({
    closeMenuHandler = NOOP,
    menuButton = undefined,
    hideCustomButton = false,
    buttonFontSize = 18,
}) => {
    const [appState] = useAppState();

    const style = css`
        width: fit-content;

        ${theme.breakpoints.down("lg")} {
            margin-top: 20px;
            margin-left: 56px;
        }

        ${theme.breakpoints.down("md")} {
            margin: 40px auto 50px 55px;
        }

        // TODO: In the future, it should be checked between login page and logout page (with design)
        // ${appState?.user ? css`
        //     ${theme.breakpoints.only("lg")} {
        //         margin-top: 20px;
        //         margin-left: 56px;
        //     }
        //
        //     ${theme.breakpoints.down("md")} {
        //         margin: 20px auto 78px 47px;
        //     }
        // ` : css`
        //     ${theme.breakpoints.only("lg")} {
        //         margin: 100px auto 78px;
        //     }
        //
        //     ${theme.breakpoints.down("md")} {
        //         margin: 100px auto 78px 55px;
        //     }
        // `}
    `;

    return (
        <div css={style}>
            <BurgerNavLinkButtons
                menuButton={menuButton}
                closeMenuHandler={closeMenuHandler}
                hideCustomButton={hideCustomButton}
                buttonFontSize={buttonFontSize}
            />
        </div>
    );
};

export default BurgerNavButtonContainer;
