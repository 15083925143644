export const API_BASE_URL = process.env.REACT_APP_API_URL
    // use env if set
    ? process.env.REACT_APP_API_URL
    // default to toolbox.local for dev
    : (
        process.env.NODE_ENV !== 'production'
                ? 'http://localhost:8080'
                : ''
    );

export const API_CORPORATE_URL = process.env.REACT_APP_CORPORATE_API_URL
    // use env if set
    ? process.env.REACT_APP_CORPORATE_API_URL
    // default to corporate.local for dev
    : (
        process.env.NODE_ENV !== 'production'
            ? 'http://localhost:8082'
            : ''
    );

export const CORPORATE_URL: string = process.env.REACT_APP_CORPORATE_URL
    // use env if set
    ? process.env.REACT_APP_CORPORATE_URL
    // default to corporate.local for dev
    : (
        process.env.NODE_ENV !== 'production'
            ? 'http://localhost:3002'
            : ''
    );

export const LOGIN_URL: string = process.env.REACT_APP_LOGIN_URL
    // use env if set
    ? process.env.REACT_APP_LOGIN_URL
    // default to localhost:3006 for dev
    : (
        process.env.NODE_ENV !== 'production'
            ? 'http://localhost:3006'
            : ''
    );

export const SIGNUP_URL: string = process.env.REACT_APP_SIGNUP_URL
    // use env if set
    ? process.env.REACT_APP_SIGNUP_URL
    // default to localhost:3005 for dev
    : (
        process.env.NODE_ENV !== 'production'
            ? 'http://localhost:3005'
            : ''
    );

export const MANAGE_URL: string = process.env.REACT_APP_MANAGE_URL
    // use env if set
    ? process.env.REACT_APP_MANAGE_URL
    // default to localhost:3007 for dev
    : (
        process.env.NODE_ENV !== 'production'
            ? 'http://localhost:3007'
            : ''
    );

/**
 * Site title to be used in the title tag in head.
 *
 * @var {string}
 */
export const SITE_TITLE = "Writer's Toolbox";

/**
 * The google app id for logging in with google.
 *
 * @var {string}
 */
export const GOOGLE_APP_ID = process.env.REACT_APP_GOOGLE_APP_ID;

/**
 * The facebook app id for logging in with facebook.
 *
 * @var {string}
 */
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;

/**
 * Separator for site title and the page name.
 *
 * @var {string}
 */
export const SITE_TITLE_SEPARATOR = " - ";

export const getSiteTitle = (pageTitle: string) => pageTitle ? pageTitle + SITE_TITLE_SEPARATOR + SITE_TITLE : SITE_TITLE;
