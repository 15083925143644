import React from "react";
import { css } from "@emotion/core";
import theme from "../../../theme";

type Props = {
    userData: State,
}

type State = {
    firstName: string,
    surname: string,
    email: string,
    lastLoginType: string,
    socialAvatarUrl: string,
};

const BurgerProfile: React.FC<Props> = ({ userData }) => {
    const emailStyle = css`
        font-size: 14px;
        line-height: 17px;
        color: ${theme.colours.white};
        margin-top: 5px;
        ${theme.utils.oneLineEllipsis};
    `;

    const nameStyle = css`
        font-size: 30px;
        line-height: 37px;
        color: ${theme.colours.white};
        font-weight: ${theme.fonts.weights.bold};
        ${theme.utils.oneLineEllipsis};

        ${theme.breakpoints.only("xxl")} {
            font-size: 28px;
            line-height: 34px;
        }
    `;

    const textContainerStyle = css`
        margin-left: 16px;
    `;

    return (
        <div css={textContainerStyle}>
            <div css={nameStyle}>{userData.firstName} {userData.surname}</div>
            <div css={emailStyle}>{userData.email}</div>
        </div>
    );
}

export default BurgerProfile;
