import React, {memo} from 'react';
import {Theme} from "../../../theme";

type Props = {
    center?: boolean,
    children?: React.ReactNode,
    className?: string,
};

const DialogContent: React.FC<Props> = ({ center, children, className }) => {
    const styles = (theme: Theme) => ({
        textAlign: center ? 'center' as 'center' : 'left' as 'left',
        fontFamily: theme.fonts.frutiger,
        fontSize: "16px",
        lineHeight: "24px",
        color: theme.colours.doveGray2,
    });

    return (
        <div css={styles} className={className}>{children}</div>
    );
};

DialogContent.defaultProps = {
    center: false,
};

export default memo(DialogContent);
