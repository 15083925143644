import React from "react";
import { css } from "@emotion/core";
import theme from "../../../../theme";

const NavMenuTextCover: React.FC = () => {
    const style = css`
        top: ${-theme.sizes.menu.heightMain}px;
        position: relative;
        width: 100%;
        height: ${theme.sizes.menu.heightMain}px;
    `;

    return (
        <div css={style} />
    );
};

export default NavMenuTextCover;
