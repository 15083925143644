import React, { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { css } from "@emotion/core";

import theme from "../../../../theme";
import { AUTH_LOGIN_URL } from "../../../includes/pricingAuthRedirects";
import { sendCorporate } from "../../../includes/navigate";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { useAppState } from "../../../contexts/AppContext";
import * as storage from "../../../includes/localStorage";
import CorporateProfile from "../../profile/CorporateProfile";
import RoundTextButton from "../../buttons/RoundTextButton";

import CartIcon from "./CartIcon";

type Props = {
    menuButton?: Button,
    closeBurgerMenu: ({ forcedClose }: any) => void,
    hideCart?: boolean,
};

type State = {
    firstName: string,
    surname: string,
    email: string,
    lastLoginType: string,
    socialAvatarUrl: string,
};

const DEFAULT_STATE = {
    firstName: "",
    surname: "",
    email: "",
    lastLoginType: "",
    socialAvatarUrl: "",
};

const NavLinkButtons:React.FC<Props> = ({
    menuButton = undefined,
    closeBurgerMenu,
    hideCart = false
}) => {
    const [appState] = useAppState();
    const [userData, setUserData] = useState<State>(DEFAULT_STATE);
    const linkURL = menuButton?.innerURL || menuButton?.outerURL;

    const onClickHandler = () => {
        closeBurgerMenu({ forcedClose: true });
    };

    useEffect(() => {
        const socialAvatarUrl = appState?.user?.LastLoginType === "google"
        || appState?.user?.LastLoginType === "facebook" ? storage.retrieve("socialAvatarUrl") : null;

        setUserData({
            firstName: appState?.user?.FirstName,
            surname: appState?.user?.Surname,
            email: appState?.user?.Email,
            lastLoginType: appState?.user?.LastLoginType,
            socialAvatarUrl: socialAvatarUrl
        });
    }, [appState]);

    const { minWidth } = useBreakpoint();
    const [
        sidePaddingGetStart,
        sidePaddingLogin,
        buttonWidth,
        buttonHeight,
        buttonFontSize,
    ] = useMemo(() => {
        switch (true) {
            case minWidth >= theme.breakpoints.sizes["3xl"]:
                return [10, 10, 109, 36, 15];
            default:
                return [10, 10, 109, 36, 14];
        }
    }, [minWidth]);

    const style = css`
        flex-direction: row;
        display: flex;
    `;

    const getStartedStyle = css`
        ${userData?.email && css`
            margin-right: 23px;
        `}
    `;

    return (
        <div css={style}>
            {!hideCart && (
                <CartIcon to={menuButton?.cartURL || ''} closeBurgerMenu={closeBurgerMenu} />
            )}

            {(menuButton?.buttonText && linkURL) && (
                <div css={getStartedStyle} onClick={onClickHandler}>
                    <RoundTextButton
                        onClick={()=>{sendCorporate(linkURL)}}
                        sidePadding={sidePaddingGetStart}
                        minWidth={buttonWidth}
                        height={buttonHeight}
                        fontSize={buttonFontSize}
                        textColour={"black"}
                        backgroundColour={theme.colours.gold}
                        backgroundHoverColour={theme.colours.mySin}
                        useBoxShadow={false}
                        noButtonBorderHover
                    >
                        {menuButton.buttonText}
                    </RoundTextButton>
                </div>
            )}

            {(userData?.email || appState?.user?.Email) ? (
                <CorporateProfile userData={userData} isInHeaderMenu />
            ) : (
                <NavLink key={"/login"} to={AUTH_LOGIN_URL} onClick={onClickHandler}>
                    <RoundTextButton
                        sidePadding={sidePaddingLogin}
                        width={buttonWidth}
                        height={buttonHeight}
                        fontSize={buttonFontSize}
                        textHoverColour={theme.colours.white}
                        backgroundColour={theme.colours.curiousBlue}
                        backgroundHoverColour={theme.colours.malibu2}
                        useBoxShadow={false}
                        noButtonBorderHover
                    >
                        Log in
                    </RoundTextButton>
                </NavLink>
            )}
        </div>
    );
};

export default NavLinkButtons;
