import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import keycode from "keycode";
import useSetState from "../../hooks/useSetState";
import { css } from "@emotion/core";
import { Theme } from "../../../theme";

import Countries from "../../../core/components/country-dropdown/Countries";

type Variant = 'country' | 'phone' | 'phone number';

export type Option = {
    label: any,
    value: any,
};

type Props = {
    onOptionSelected: (option: Option) => void,
    options: Option[],
    value: any,
    searchString?: string,
    onClose: Function,
    greyOutOptionFunc?: (option: Option) => boolean,
    containsSearchBox?: boolean,
    onFilterChange?: (e: React.FormEvent<HTMLInputElement>) => void,
    variant?: Variant,
};

type State = {
    highlightedOption: number | null,
    search: string,
};

const DEFAULT_STATE: State = {
    highlightedOption: null,
    search: ''
};

const NO_OPTIONS_TEXT = 'No options could be found.';

const SearchableListMenu: React.FC<Props> = ({
    onOptionSelected,
    options,
    value,
    greyOutOptionFunc,
    searchString,
    containsSearchBox,
    onFilterChange,
    variant,
}) => {
    const [state, setState] = useSetState<State>(DEFAULT_STATE);
    const listRef = useRef<HTMLUListElement | null>(null);
    const searchField = useRef<HTMLInputElement | null>(null);

    // filter options and handle label function
    const filteredOptions = useMemo(() => {
        if (searchString) {

            // see if the label contains the search string (case insensitive)
            return options.filter(option => {
                return option.label.toString().toLowerCase().indexOf(searchString.toLowerCase()) !== -1;
            });
        }

        return options;
    }, [options, searchString]);

    /**
     * Convert a country code to its corresponding flag.
     */
    function countryToFlag(isoCode: string) {
        if (typeof String.fromCodePoint !== 'undefined') {
            const filteredCountries = Countries.filter(country => country.code === isoCode);
            if (filteredCountries.length === 1 && filteredCountries[0].phone !== 'none')
                return (
                    isoCode
                        .toUpperCase()
                        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
                );
            else return <span css={space}/>
        }
    }

    /**
     * Convert a country code to its phone area code.
     */
    function countryToPhoneCode(isoCode?: String) {
        const country = Countries.find(country => (
            country.code.toUpperCase() === isoCode?.toUpperCase()
        ));
        if (country?.phone !== 'none')
            return country?.phone;
    }

    /**
     * Handle arrow keys and enter
     */
    const onKeyDown = useCallback((e: Event) => {
        if (keycode(e) === 'up') {
            e.preventDefault();
            setState((prevState: State) => ({
                highlightedOption: moveUp(prevState.highlightedOption, filteredOptions.length)
            }));
        } else if (keycode(e) === 'down') {
            e.preventDefault();
            setState((prevState: State) => ({
                highlightedOption: moveDown(prevState.highlightedOption, filteredOptions.length)
            }));
        } else if (keycode(e) === 'enter' && state.highlightedOption !== null) {
            e.preventDefault();
            onOptionSelected(filteredOptions[state.highlightedOption]);
        }
    }, [onOptionSelected, filteredOptions, setState, state.highlightedOption]);

    /**
     * Check if option in dropdown is selected option
     */
    const checkIfSelected = (option: string, value: string) => {
        return option === value || (!!value && value === (countryToPhoneCode(option)));
    };

    /**
     * Check if option should be greyed out
     */
    const getIsGreyedOut = useCallback((option: Option) => {
        if (!greyOutOptionFunc) {
            return false;
        } else {
            return greyOutOptionFunc(option);
        }
    }, [greyOutOptionFunc]);

    /**
     * Scroll to highlighted option when it changes.
     */
    useEffect(() => {
        if (state.highlightedOption !== null && listRef.current) {
            const options = listRef.current.children;
            let option = options.item(state.highlightedOption);

            if (option) {
                option.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'nearest'
                });
            }
        }
    }, [state.highlightedOption]);

    /**
     * Listen for arrow keys
     */
    useEffect(() => {
        document.body.addEventListener('keydown', onKeyDown);
        return () => document.body.removeEventListener('keydown', onKeyDown);
    }, [onKeyDown]);

    return (
        <>
            <div css={containerStyle}>
                {containsSearchBox && (
                    <div css={filterStyle}>
                        <input
                            css={filterInputStyle}
                            type="text"
                            value={searchString}
                            onChange={onFilterChange}
                            ref={searchField}
                            autoComplete="off"
                            autoCapitalize="off"
                            autoCorrect="off"
                            spellCheck="false"
                            placeholder="Search"
                            autoFocus
                        />
                    </div>
                )}
                {!!filteredOptions?.length ? (
                    <ul css={optionsContainerStyle} ref={listRef}>
                        {filteredOptions.map((option, index) => (
                            <li
                                key={option.value}
                                title={option.label}
                                css={optionStyle(checkIfSelected(option.value, value), index === state.highlightedOption, getIsGreyedOut(option))}
                                onClick={() => onOptionSelected(option)}
                            >
                                {(variant === "phone" || variant === "country") && (
                                    <span css={flagStyle}>{countryToFlag(option.value)}</span>
                                )}
                                {option.label + " "}
                                {variant === 'phone' && (
                                    <span>{countryToPhoneCode(option.value)}</span>
                                )}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p css={noOptionsStyle}>{NO_OPTIONS_TEXT}</p>
                )}
            </div>
        </>
    );
};

/**
 * Calculate next index when moving up (decreasing index).
 *
 * @param currentIndex
 * @param numberOptions
 */
const moveUp = (currentIndex: number | null, numberOptions: number): number => {
    const max = numberOptions - 1;
    const min = 0;

    let newIndex = max;

    if (currentIndex !== null) {
        newIndex = currentIndex - 1;
    }

    // wrap if we've gone below min value
    if (newIndex < min) {
        newIndex = max;
    }

    return newIndex;
};

/**
 * Calculate next index when moving down (increasing index).
 *
 * @param currentIndex
 * @param numberOptions
 */
const moveDown = (currentIndex: number | null, numberOptions: number): number => {
    const max = numberOptions - 1;
    const min = 0;

    let newIndex = min;

    if (currentIndex !== null) {
        newIndex = currentIndex + 1;
    }

    // wrap if we've gone over the max value
    if (newIndex > max) {
        newIndex = min;
    }

    return newIndex;
}

const containerStyle = (theme: Theme) => css`
    position: absolute;
    z-index: 3;
    top: 88%;
    right: 0;
    width: 100%;
    background: ${theme.colours.white};
    margin: 0;
    padding: 0;
    border: 1px solid ${theme.colours.grey[450]};
    list-style: none;
    border-radius: 0 0 ${theme.borderAndShadow.smallRadius} ${theme.borderAndShadow.smallRadius};
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    cursor: auto;

    ${theme.breakpoints.down('sm')} {
        max-height: calc(500px - 34px);
    }
`;

const optionsContainerStyle = (theme: Theme) => css`
    max-height: 145px;
    overflow: auto;
    border-radius: 0 0 ${theme.borderAndShadow.smallRadius} ${theme.borderAndShadow.smallRadius};;

    ${theme.breakpoints.down('sm')} {
        max-height: calc(500px - 34px);
    }
`;

const optionStyle = (selected: boolean, highlighted: boolean, isGreyedOutOption?: boolean) => (theme: Theme) => css`
    min-height: 29px;
    max-height: 29px;
    padding: 5px;
    font-size: ${theme.forms.label.fontSize};
    font-family: ${theme.fonts.frutiger};
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
        background-color: ${theme.colours.solitude};
    }

    ${((selected || highlighted) && !isGreyedOutOption) && css`
        background-color: ${theme.colours.hawkesBlue};
        border-bottom: 2px solid ${theme.colours.cornflower};
    `}

    ${isGreyedOutOption && css`
        pointer-events: none;
        background-color: ${theme.colours.grey[100]};
        color: ${theme.colours.grey[800]};;
        border-radius: 0;
    `};
`;

const flagStyle = css`
    padding-left: 9px;
    padding-right: 11px;
    font-size: 18px;
`;

const noOptionsStyle = (theme: Theme) => css`
    margin: 0;
    padding: 11px 10px;
    font-family: ${theme.fonts.frutiger};
    font-size: 16px;
`;

const space = css`
    padding-left: 21px;
`;

const filterStyle = (theme: Theme) => css`
    padding: 5px;
    border-bottom: 1px solid ${theme.forms.defaults.borderColor};
`;

const filterInputStyle = (theme: Theme) => css`
    width: 100%;
    border-width: 0;
    font-weight: ${theme.fonts.weights.light};
    font-size: ${theme.forms.label.fontSize};
    font-family: ${theme.fonts.frutiger};
    padding: 2px 5px;
    display: block;
    outline: none;

    ${theme.mixins.placeholder(`
        font-family: ${theme.fonts.frutiger};
    `)};
`;

export default memo(SearchableListMenu);

