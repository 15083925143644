import React from "react";

import theme from "../../../../theme";
import BurgerNavSubTextBoxContainer from "./BurgerNavSubTextBoxContainer";
import NavMenuUnderLine from "../../header/corporate/NavMenuUnderLine";

type Props = {
    children: React.ReactNode,
    subMenu: StandardPage,
    selectedSegments?: string[],
    closeMenuHandler: () => void,
};

const BurgerNavSubTextBox: React.FC<Props> = ({
    children,
    subMenu,
    selectedSegments = undefined,
    closeMenuHandler,
}) => (
    <BurgerNavSubTextBoxContainer
        colour={theme.colours.doveGray2}
        showUnderline={!!selectedSegments?.includes(subMenu.uRLSegment)}
        clickHandler={closeMenuHandler}
    >
        <div>{children}</div>
        <NavMenuUnderLine width={100} height={4} />
    </BurgerNavSubTextBoxContainer>
);

export default BurgerNavSubTextBox;
