import React from 'react';
import { css } from '@emotion/core';

import theme from '../../theme';

type Props = {
    children: React.ReactNode,
    className?: string,
    isOverflowHidden?: boolean,
    showBreadcrumb?: boolean,
};

const PageContainer: React.FC<Props> = ({
    children,
    className = '',
    isOverflowHidden = true,
    showBreadcrumb = true,
}) => {
    const style = css`
        width: 100%;
        background-color: ${theme.colours.white};

        ${isOverflowHidden && css`
            overflow: hidden;
        `};
    `;

    const headerBlockerStyle = css`
        ${theme.breakpoints.up('xl')} {
            padding-top: ${theme.sizes.menu.heightMain + theme.sizes.menu.breadcrumbHeight}px;
        }

        ${theme.breakpoints.between("sm", "lg")} {
            padding-top: ${theme.sizes.menu.burgerHeightMain + theme.sizes.menu.breadcrumbBurgerHeight}px;
        }

        ${theme.breakpoints.down("xs")} {
            padding-top: ${
                theme.sizes.menu.burgerHeightMain +
                theme.sizes.menu.breadcrumbBurgerSmallHeight +
                (showBreadcrumb ? theme.sizes.menu.breadcrumbBurgerHeight : 0)
            }px;
        }
    `;

    return (
        <div css={style} className={className}>
            <div css={headerBlockerStyle} />
            { children }
        </div>
    );
};

export default PageContainer;
