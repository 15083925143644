import React from "react";
import { css } from "@emotion/core";
import theme from "../../../../theme";

type Props = {
    children: React.ReactNode,
    showSubMenu: boolean,
};

const BurgerNavSubContainer: React.FC<Props> = ({ children, showSubMenu }) => {
    const style = css`
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        padding: 20px 55px;
        width: 50%;
        background-color: ${showSubMenu ? theme.colours.white : theme.colours.curiousBlue};
        box-shadow: ${theme.borderAndShadow.boxInsetTopShadow};

        ${theme.breakpoints.down("md")} {
            display: none;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default BurgerNavSubContainer;
