import { useEffect, useRef, useState } from "react";
import { FocusedImage } from "image-focus";

type ContainerPositionType = "relative" | "fixed" | "absolute" | "sticky" | undefined;
type DisabledType = boolean | undefined;

const useFocusedImage = (
    x: number = 0,
    y: number = 0,
    containerPosition: ContainerPositionType = "relative",
    disabled: DisabledType = false
) => {
    const focusedImageRef = useRef<any>(null);

    const [img, setImg] = useState<any>(null);

    useEffect(() => {
        if (!img || disabled) return;

        focusedImageRef.current = new FocusedImage(
            img,
            {
                focus: { x, y },
                updateOnContainerResize: true,
                containerPosition,
            }
        );

        return () => {
            focusedImageRef.current.stopListening();
            focusedImageRef.current = null;
            if (img) {
                Object.assign(img.style, {
                    minHeight: null,
                    minWidth: null,
                    position: null,
                    inset: null,
                    maxHeight: null,
                });
            }
        }
    }, [img, x, y, disabled, containerPosition]);

    useEffect(() => {
        focusedImageRef.current?.applyShift();
    });

    return setImg;
}

export default useFocusedImage;
