import React, { useCallback, useEffect, useRef } from 'react';
import { Redirect, useHistory } from "react-router";
import gql from 'graphql-tag';
import jwt_decode from "jwt-decode";
import { css } from "@emotion/core";
import { isEmpty, trim } from "lodash";

import Form from "../form/Form";
import LabelledField from "../form/LabelledField";
import Input from "../form/Input";
import FormActions from "../form/FormActions";
import Button from "../buttons/Button";
import useForm, { FieldList } from "../../hooks/useForm";
import { login, needEmailVerification, socialLogin, SocialLoginType } from "../../includes/auth";
import { useMutation } from '@apollo/react-hooks';
import errorParser from '../../includes/errorParser';
import { useAppState } from "../../contexts/AppContext";
import SocialLogin from "../social/SocialLogin";
import EmailValidator from 'email-validator';
import Loading from "../utils/Loading";
import { Theme } from "../../../theme";
import TwoColumn from "../form/TwoColumn";
import useSetState from "../../hooks/useSetState";
import ErrorMessage from "../errors/ErrorMessage";
import * as storage from "../../includes/localStorage";
import { AUTH_LOGIN_URL } from "../../includes/pricingAuthRedirects";
import useElementDimensions from "../../hooks/useElementDimensions";

type Props = {
    onSignIn: () => void,
    redirectAfterSignUp: (user: any) => void,
    errorMessage?: string,
};

type DefaultState = {
    isSubmitting: boolean,
    errorMessage: string | null,
    isVisibleErrorMessage: boolean,
    areRequirementsVisible: boolean,
    socialButtonKey: number,
}

const MIN_PASSWORD_LENGTH = 10;

const INITIAL_STATE = {
    isSubmitting: false,
    errorMessage: "The provided details don't seem to be correct. Please try again.",
    isVisibleErrorMessage: false,
    areRequirementsVisible: false,
    socialButtonKey: Math.random(),
}

const SignUp: React.FC<Props> = ({ onSignIn, redirectAfterSignUp, errorMessage }) => {
    const history: any = useHistory();
    const redirectTo = history.location.state?.redirectTo;
    const [appState] = useAppState();
    const [saveData] = useMutation(CREATE_USER_MUTATION);
    const [state, setState] = useSetState<DefaultState>(INITIAL_STATE);
    const [fields, errors, onFieldChange,, validate] = useForm({
        fields: {
            firstName: appState?.user?.FirstName ?? '',
            surname: appState?.user?.Surname ?? '',
            email: '',
            password: '',
            repeatPassword: '',
        },
        validator,
    });

    const signUpButtonRef = useRef<HTMLButtonElement>(null);
    const signUpButtonDimensions = useElementDimensions(signUpButtonRef.current);

    useEffect(() => {
        if (!isEmpty(errorMessage)) {
            setState({ isVisibleErrorMessage: true, errorMessage: errorMessage || '' });
        }
    }, [errorMessage, setState]);

    useEffect(() => {
        if (state.isVisibleErrorMessage) {
            setState({ socialButtonKey: Math.random() });
        }
    }, [state.isVisibleErrorMessage, setState]);

    const submitSignUpForm = useCallback(() => {
        setState({ errorMessage: '', isVisibleErrorMessage: false });

        if (validate()) {
            setState({ isSubmitting: true });
            saveData({
                variables: {
                    authType: 'password',
                    email: fields.email,
                    password: fields.password,
                    repeatPassword: fields.repeatPassword,
                    firstName: fields.firstName,
                    surname: fields.surname,
                }
            }).then((data) => {
                login(fields.email, fields.password).then(() => {
                    appState.getUser().then((user: any) => {
                        redirectAfterSignUp(user);
                    });
                });
            }).catch((error) => {
                setState({ isSubmitting: false, errorMessage: errorParser(error), isVisibleErrorMessage: true });
            });
        }

    }, [appState, fields.email, fields.firstName, fields.password, fields.repeatPassword, fields.surname, saveData, validate, redirectAfterSignUp, setState]);

    const onSocialSignup = async (socialProfile: any, socialLoginType: SocialLoginType) => {
        setState({ isSubmitting: true });

        try {
            let email: string = '';
            let firstName: string = '';
            let surname: string = '';
            const credential = socialProfile["credential"];
            if (socialLoginType === "google") {
                const decodedData: any = jwt_decode(credential);
                email = decodedData.email;
                firstName = decodedData.given_name;
                surname = decodedData.family_name;
                // stores the avatar for header
                storage.persist('socialAvatarUrl', decodedData.picture);
                storage.persist('socialFirstName', firstName);
                storage.persist('socialLastName', surname);
            }
            // register account
            await saveData({
                variables: {
                    authType: 'social',
                    email,
                    firstName,
                    surname,
                }
            });

            // log the user in
            await socialLogin(
                email,
                credential,
                socialLoginType
            );

            // load user and redirect
            const user = await appState.getUser();
            setState({ isSubmitting: false });
            redirectAfterSignUp(user);
        } catch (e) {
            setState({ isSubmitting: false, errorMessage: errorParser(e), isVisibleErrorMessage: true });
        }
    };

    const handleSignIn = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        onSignIn();
        history.push({
            pathname: AUTH_LOGIN_URL,
            state: { redirectTo: redirectTo }
        })
    }, [onSignIn, history, redirectTo]);

    const setAreRequirementsVisible = useCallback(() => {
        setState({ areRequirementsVisible: true });
    }, [setState]);

    if (appState?.authorized && !needEmailVerification(appState?.user)) {
        return <Redirect from="*" to={"/"} />;
    }

    const needToSignUpAgain = appState?.authorized && needEmailVerification(appState?.user);

    return (
        <div css={authContent}>
            {state.isSubmitting && <Loading onTop overlay overlayColour={"white"} />}
            <div css={leftHeader}>Create Account</div>
            <div css={formContainerStyle}>
                <Form onSubmit={submitSignUpForm}>
                    <TwoColumn>
                        <LabelledField name="name" error={errors.firstName} label="First Name">
                            <Input
                                hasError={!!errors.firstName}
                                css={nameInput}
                                label="Type First Name" name="firstName"
                                onChange={onFieldChange}
                                value={fields.firstName}
                            />
                        </LabelledField>
                        <LabelledField name="surname" error={errors.surname} label="Last Name">
                            <Input
                                hasError={!!errors.surname}
                                css={nameInput}
                                label="Type Last Name"
                                name="surname"
                                onChange={onFieldChange}
                                value={fields.surname}
                            />
                        </LabelledField>
                    </TwoColumn>
                    <LabelledField name="email" error={errors.email} label="Email">
                        <Input
                            hasError={!!errors.email}
                            css={emailInput}
                            label="Enter email address"
                            name="email"
                            onChange={onFieldChange}
                            value={fields.email}
                            {...needToSignUpAgain && { autoFocus: true }}
                        />
                    </LabelledField>
                    <LabelledField name="password" error={errors.password} label="Password">
                        <Input
                            hasError={!!errors.password}
                            css={passwordInput}
                            label="Password"
                            type="password"
                            name="password"
                            onChange={onFieldChange}
                            onFocus={setAreRequirementsVisible}
                            value={fields.password}
                        />
                    </LabelledField>
                    {state.areRequirementsVisible && (
                        <div css={passwordRequirements}>
                            <p>Password must:</p>
                            <ul>
                                <li css={passwordRequirement(fields.password, !!errors.password)}>
                                    Have at least 10 characters
                                </li>
                            </ul>
                        </div>
                    )}
                    <LabelledField name="repeatPassword" error={errors.repeatPassword} label="Confirm Password">
                        <Input
                            hasError={!!errors.repeatPassword}
                            css={passwordInput}
                            label="Confirm password"
                            type="password"
                            name="repeatPassword"
                            onChange={onFieldChange}
                            value={fields.repeatPassword}
                        />
                    </LabelledField>
                    <div css={formActions}>
                        <FormActions customCss={customFormActionsStyles}>
                            <Button
                                css={formSubmitButton}
                                type="submit"
                                forceLoading={state.isSubmitting}
                                ref={signUpButtonRef}
                            >
                                Sign Up
                            </Button>
                        </FormActions>
                    </div>
                    <div css={lineContainerStyle}>
                        <div css={lineStyle} />
                        <span css={lineTextStyle}>Or</span>
                        <div css={lineStyle} />
                    </div>
                    <ErrorMessage
                        isVisible={state.isVisibleErrorMessage}
                        message={state.errorMessage}
                        css={errorMessageStyle}
                    />
                    <SocialLogin socialSignUp={onSocialSignup} buttonWidth={signUpButtonDimensions.width} isSignUp />
                    {!needToSignUpAgain && (
                        <div css={signInText}>
                            Already have an account? Please <a href="/signin" onClick={handleSignIn}>sign in.</a>
                        </div>
                    )}
                </Form>
            </div>
        </div>
    );
};

const isPasswordLongEnough = (password: string): boolean => trim(password).length >= MIN_PASSWORD_LENGTH;

const validator: any = (fields: FieldList, isSubmit: boolean = false) => {
    const errors: any = {};

    if (!fields.firstName) {
        errors.firstName = 'Please provide your first name';
    }

    if (!fields.surname) {
        errors.surname = 'Please provide your last name';
    }

    if (!fields.email) {
        errors.email = 'Please provide your email';
    }

    if (fields.email && !EmailValidator.validate(fields.email) && isSubmit) {
        errors.email = 'Please provide a valid email address';
    }

    if (!fields.password) {
        errors.password = 'Please provide a password';
    }

    if (fields.password && !isPasswordLongEnough(fields.password) && isSubmit) {
        errors.password = `Your password must be at least ${MIN_PASSWORD_LENGTH} characters`;
    }

    if (!fields.repeatPassword) {
        errors.repeatPassword = 'Please provide the confirm password';
    }

    if (isSubmit && fields.repeatPassword !== fields.password) {
        errors.repeatPassword = "Your passwords do not match";
    }

    return errors;
};

const CREATE_USER_MUTATION = gql`
    mutation ($firstName: String!, $authType: String!, $surname: String!, $email: String!, $password: String, $repeatPassword: String) {
        createHomeUser(FirstName: $firstName, AuthType: $authType, Surname: $surname, Email: $email, Password: $password, RepeatPassword: $repeatPassword) {
            id
        }
    }
`;

const lineContainerStyle = (theme:Theme) => css`
    display: flex;

    ${theme.breakpoints.up("5xl")} {
        margin: 68px 0;
    }

    ${theme.breakpoints.between("xxl", "4xl")} {
        margin-bottom: 38px;
    }

    ${theme.breakpoints.between("lg", "xl")} {
        margin-bottom: 28px;
    }

    ${theme.breakpoints.only('md')} {
        margin-bottom: 21px;
    }

    ${theme.breakpoints.only("sm")} {
        margin-bottom: 25px;
    }

    ${theme.breakpoints.down("xs")} {
        margin-bottom: 18px;
    }
`;

const lineTextStyle = (theme: Theme) => css`
    padding: 0 28px;
    margin-top: -8px;
    color: ${theme.colours.silverChalice2};

    ${theme.breakpoints.up("5xl")} {
        font-size: 18px;
        margin-top: -10px;
    }

    ${theme.breakpoints.between("xxl", "4xl")} {
        font-size: 14px;
    }

    ${theme.breakpoints.down("xl")} {
        font-size: 11px;
        margin-top: -6px;
    }
`;

const lineStyle = (theme: Theme) => css`
    height: 1px;
    flex-grow: 1;
    background: ${theme.colours.silverChalice2};
`;

const authContent = (theme: Theme) => css`
    margin-top: 40px;
    width: 510px;
    position: relative;

    ${theme.breakpoints.down('sm')} {
        width: 100%;
        max-width: 510px;
        margin: 40px auto 0;
    }

    ${theme.breakpoints.down('xs')} {
        padding: 0 20px;
    }
`;

const formContainerStyle = css`
    margin-top: 12px;
`;

const nameInput = css`
    margin-top: 0;
    box-sizing: border-box;
`;

const emailInput = css`
    margin-top: 0;
    box-sizing: border-box;
    width: 100%;
`;

const passwordInput = css`
    margin-top: 0;
    box-sizing: border-box;
    width: 100%;
`;

const passwordRequirements = css`
    margin-bottom: 19px;

    p {
        margin-bottom: 10px;
        font-size: 14px;
    }
`;

const passwordRequirement = (password: string, fieldHasError: boolean) => (theme: Theme) => css`
    font-size: 14px;
    list-style: disc inside;
    color: ${
        (password === '' && !fieldHasError)
            ? 'black'
            : isPasswordLongEnough(password) ? theme.colours.killarney : theme.colours.crimson
    }
`;

const signInText = (theme: Theme) => css`
    font-size: 14px;
    font-weight: 100;
    text-align: center;

    a {
        text-decoration: none;
        color: ${theme.colours.blue['400']}
    }
`;

const formActions = (theme: Theme) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    ${theme.breakpoints.up("5xl")} {
        margin: 63px 0 68px;
    }

    ${theme.breakpoints.between("xxl", "4xl")} {
        margin: 41px 0 45px;
    }

    ${theme.breakpoints.between("lg", "xl")} {
        margin: 29px 0 34px;
    }

    ${theme.breakpoints.only("md")} {
        margin: 21px 0 26px;
    }

    ${theme.breakpoints.only("sm")} {
        margin: 26px 0 31px;
    }

    ${theme.breakpoints.down("xs")} {
        margin: 19px 0 24px;
    }

    ${theme.breakpoints.down('md')} {
        margin-top: 20px;
        justify-content: center;
    }
`;

const customFormActionsStyles = css`
    width: 100%;
    justify-content: center;
`;

const formSubmitButton = (theme: Theme) => css`
    background-color: ${theme.colours.blue['400']};
    width: 400px;
    border-radius: 45px;
    height: 40px;
    padding: 0;

    ${theme.breakpoints.down('xs')} {
        width: 250px;
    }
`;

const leftHeader = (theme:Theme) => css`
    color: ${theme.colours.curiousBlue};
    font-weight: ${theme.fonts.weights.bold};

    ${theme.breakpoints.up("xxl")} {
        font-size: 50px;
        line-height: 75px;
    }

    ${theme.breakpoints.between("md", "xl")} {
        font-size: 43px;
        line-height: 65px;
    }

    ${theme.breakpoints.down("sm")} {
        font-size: 30px;
        line-height: 45px;
    }
`;

const errorMessageStyle = css`
    margin-top: 0;
    margin-bottom: 16px;
`;

export default SignUp;
