import React from "react";
import { css } from "@emotion/core";
import theme from "../../../../../theme";
import NavLinkTag from "../../../header/corporate/NavLinkTag";

type Props = {
    subMenu: StandardPage,
};

const FooterVerticalSubMenu: React.FC<Props> = ({ subMenu }) => {
    const style = css`
        margin-top: 29px;
        color: ${theme.colours.doveGray};

        &:last-of-type {
            margin-bottom: 19px;
        }

        &:hover {
            opacity: 0.7;
        }
    `;

    return (
        <div css={style}>
            <NavLinkTag css={style} data={subMenu}>
                {subMenu.titleForFooter ?? subMenu.title}
            </NavLinkTag>
        </div>
    );
};

export default FooterVerticalSubMenu;
