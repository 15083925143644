import React from 'react';
import {css} from '@emotion/core';
import {Theme} from '../../../theme';

type Props = {};

const OrderSummaryHeader: React.FC<Props> = () => <div css={style}>Order summary</div>;

const style = (theme: Theme) => css`
    width: 100%;
    height: 51px;
    line-height: 51px;
    font-size: 18px;
    font-weight: 400;
    padding-left: 20px;
    border-bottom: 1px solid ${theme.colours.grey['450']};
`;

export default OrderSummaryHeader;
