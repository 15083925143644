import React from "react";
import { css, SerializedStyles } from "@emotion/core";
import theme from "../../../theme";
import RoundTextButtonWrapper from "./RoundTextButtonWrapper";

type Props = {
    children: React.ReactNode,
    onClick?: (e: React.MouseEvent) => void,
    sidePadding?: number,
    width?: number,
    minWidth?: number,
    height?: number,
    fontSize?: number,
    isTextBold?: boolean,
    textColour?: string,
    textHoverColour?: string,
    buttonLineColour?: string,
    backgroundColour?: string,
    backgroundHoverColour?: string,
    useBoxShadow?: boolean,
    buttonBorder?: number,
    noButtonBorderHover?: boolean,
    className?: string,
    buttonTextCustomStyle?: SerializedStyles,
}

const RoundTextButton: React.FC<Props> = ({
    children,
    onClick,
    sidePadding,
    width,
    minWidth = 0,
    height = 32,
    fontSize,
    isTextBold = true,
    textColour,
    textHoverColour,
    buttonLineColour,
    backgroundColour,
    backgroundHoverColour,
    noButtonBorderHover= false,
    useBoxShadow = true,
    buttonBorder = 0,
    className,
    buttonTextCustomStyle,
}) => {
    const style = css`
        position: relative;
        display: flex;
        align-items: center;
        height: ${height}px;
        border: 0;
        background-color: transparent;
        z-index: ${theme.zIndex.zIndexOne};
        cursor: pointer;
    `;

    return (
        <button css={style} onClick={onClick} className={className}>
            <RoundTextButtonWrapper
                sidePadding={sidePadding}
                width={width}
                minWidth={minWidth}
                fontSize={fontSize}
                isTextBold={isTextBold}
                textColour={textColour}
                textHoverColour={textHoverColour}
                buttonLineColour={buttonLineColour}
                backgroundColour={backgroundColour}
                backgroundHoverColour={backgroundHoverColour}
                useBoxShadow={useBoxShadow}
                buttonBorder={buttonBorder}
                noButtonBorderHover={noButtonBorderHover}
                css={buttonTextCustomStyle}
            >
                {children}
            </RoundTextButtonWrapper>
        </button>
    );
};

export default RoundTextButton;
