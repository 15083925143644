import React, { useRef } from "react";
import { css } from "@emotion/core";

import theme from "../../../theme";
import BreadcrumbList from "./BreadcrumbList";
import NavCountryDropdown from "../header/corporate/NavCountryDropdown";

type Props = {
    currentURLSegment?: string,
    selectedSegments?: string[],
    getLinkBySegment: (selectedSegment: string) => { label: string, link: string } | null,
    countries?: [CountryItem],
    selectedCountry?: string,
    setCountryHandler: (countryCode: string) => void,
    headerRef: React.RefObject<HTMLDivElement>,
};

const BreadcrumbContainer: React.FC<Props> = ({
    currentURLSegment = "",
    selectedSegments = undefined,
    getLinkBySegment,
    countries = undefined,
    selectedCountry = '',
    setCountryHandler,
    headerRef
}) => {
    const breadcrumbs = [{ label: "Home", link: "" }];
    const headerWidth = headerRef?.current?.offsetWidth || theme.sizes.menu.sideMargin;
    const dropdownRef = useRef<HTMLDivElement>(null);

    selectedSegments?.forEach((segment: string) => {
        if (segment !== 'home' && segment !== '') {
            const breadcrumb = getLinkBySegment(segment);
            if (breadcrumb) {
                breadcrumbs.push(breadcrumb);
            }
        }
    });

    const style = css`
        position: relative;
        display: flex;
        justify-content: space-between;
        height: ${theme.sizes.menu.breadcrumbHeight}px;
        border-top: 1px solid rgba(0, 0, 0, 0.16);
        font-weight: ${theme.fonts.weights.normal};

        ${theme.breakpoints.up("5xl")} {
            padding-left: calc((100% - ${headerWidth}px) / 2 + 200px);
            padding-right: calc((100% - ${headerWidth}px) / 2 + 200px);
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            padding-left: calc((100% - ${headerWidth}px) / 2 + 80px);
            padding-right: calc((100% - ${headerWidth}px) / 2 + 80px);
        }

        ${theme.breakpoints.only("xl")} {
            padding-left: calc((100% - ${headerWidth}px) / 2 + 38px);
            padding-right: calc((100% - ${headerWidth}px) / 2 + 38px);
        }

        ${theme.breakpoints.down("lg")} {
            padding-left: ${headerWidth}px;
            height: ${theme.sizes.menu.breadcrumbBurgerHeight}px;
        }
    `;

    const breadcrumbStyle = css`
        position: relative;
        display: flex;
        padding-right: 10px;
        align-items: center;
        width: calc(100% - ${dropdownRef?.current?.offsetWidth || 0}px);
        font-size: 12px;

        ${theme.breakpoints.down('xs')} {
            width: calc(100% - ${theme.sizes.menu.sideMargin / 2}px);
        }
    `;

    const countryDropdownStyle = css`
        ${theme.breakpoints.down("xs")} {
            display: none;
        }
    `;

    return (
        headerWidth > 0 ? (
            <div css={style}>
                <BreadcrumbList
                    currentURLSegment={currentURLSegment}
                    breadcrumbs={breadcrumbs}
                    css={breadcrumbStyle}
                />
                <div css={countryDropdownStyle} ref={dropdownRef}>
                    <NavCountryDropdown
                        countries={countries}
                        selectedCountry={selectedCountry}
                        setCountryHandler={setCountryHandler}
                    />
                </div>
            </div>
        ) : null
    );
};

export default BreadcrumbContainer;
