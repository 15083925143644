import React from "react";
import { css } from "@emotion/core";

import theme from "../../../../theme";
import FooterSocialBox from "./FooterSocialBox";

const FooterTopRightSection: React.FC = () => {
    const style = css`
        display: flex;
        align-self: end;

        ${theme.breakpoints.only("md")} {
            margin-top: 23px;
        }

        ${theme.breakpoints.down("sm")} {
            margin-top: 21px;
            align-self: start;
        }

        ${theme.breakpoints.down("md")} {
            width: 100%;
            justify-content: end;
        };
    `;

    return (
        <div css={style}>
            <FooterSocialBox />
        </div>
    );
};

export default FooterTopRightSection;
