import React, {useCallback, useEffect, useState} from 'react';
import jwt_decode from "jwt-decode";
import { css } from "@emotion/core";
import { Redirect, useHistory, useLocation } from "react-router";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import {
    socialLogin,
    needEmailVerification,
    needSocialConfirmation,
    SocialLoginType,
} from "../../includes/auth";
import { useAppState } from "../../contexts/AppContext";
import * as storage from "../../includes/localStorage";
import Loading from "../utils/Loading";
import { AUTH_SIGNUP_URL, SUCCESS_URL } from "../../includes/pricingAuthRedirects";
import LoginError from "../../includes/LoginError";
import { Theme } from "../../../theme";

type UrlsType = {
    socialConfirmationURL: string,
    emailVerificationURL: string,
    successURL: string,
};

type Props = {
    urls: UrlsType,
    onLoginDialogClose?: () => void,
    isLoginDialog?: boolean,
    showSignUp?: boolean,
};

const Auth: React.FC<Props> = ({ urls, isLoginDialog, showSignUp = true, onLoginDialogClose }) => {
    const [isSignUp, setIsSignUp] = useState(showSignUp);
    const [errorMessage, setErrorMessage] = useState('');
    const history: any = useHistory();
    const [loading, setLoading] = useState(false);
    const [appState, setAppState] = useAppState();
    const location = useLocation();
    const redirectTo = history.location.state?.redirectTo;

    const signUpToggle = () => {
        setIsSignUp(!isSignUp);
    }

    useEffect(() => {
        if (appState?.user) {
            if (needSocialConfirmation(appState?.user)) {
                history.push({
                    pathname: urls.socialConfirmationURL,
                    state: { redirectTo: redirectTo }
                });
            } else if (needEmailVerification(appState?.user)) {
                if (appState?.user?.Subscription?.IsValid) {
                    history.push("/manage");
                } else if (location.pathname !== AUTH_SIGNUP_URL && !isLoginDialog) {
                    history.push({
                        pathname: urls.emailVerificationURL,
                        state: { redirectTo: redirectTo }
                    });
                }
            }
        }
    }, [appState, history, urls, location, isLoginDialog, redirectTo]);

    const redirectAfterAuth = useCallback((userInfo: any) => {
        setAppState({ authorized: true });

        if (isLoginDialog) {
            if (needEmailVerification(userInfo)) {
                history.push({
                    pathname: urls.emailVerificationURL,
                    state: { redirectTo: redirectTo },
                });
            }
            return;
        }
        if (needSocialConfirmation(userInfo)) {
            history.push({
                pathname: urls.socialConfirmationURL,
                state: { redirectTo: redirectTo },
            });
        } else if (needEmailVerification(userInfo)) {
            if (userInfo?.Subscription?.IsValid) {
                history.push("/manage");
            } else {
                history.push({
                    pathname: urls.emailVerificationURL,
                    state: { redirectTo: redirectTo },
                });
            }
        } else if (userInfo?.Subscription) {
            history.push(urls.successURL);
        } else {
            history.push(redirectTo ? redirectTo : SUCCESS_URL);
        }
    }, [history, urls, isLoginDialog, redirectTo, setAppState]);

    const socialSignUp = useCallback((socialProfile: any, socialLoginType: SocialLoginType) => {
        setLoading(true);
        setErrorMessage("");

        let email: string = '';
        let firstName: string = '';
        let lastName: string = '';
        let picture: string = '';
        const credential = socialProfile["credential"];
        if (socialLoginType === "google") {
            const decodedData: any = jwt_decode(credential);
            email = decodedData.email;
            firstName = decodedData.given_name;
            lastName = decodedData.family_name;
            picture = decodedData.picture;
        } else {
            email = socialProfile.email;
        }

        socialLogin(
            email,
            credential,
            socialLoginType
        ).then(() => {
            storage.persist('socialFirstName', firstName);
            storage.persist('socialLastName', lastName);
            storage.persist('socialAvatarUrl', picture);
            appState.getUser().then(redirectAfterAuth);
        }).catch((e: LoginError) => {
            setErrorMessage(e.message);
            setLoading(false);
        });
    },[appState, redirectAfterAuth]);

    if (!appState.hasUserBeenLoaded) {
        return <Loading />;
    }

    if (appState?.authorized && !isSignUp && !isLoginDialog) {
        return <Redirect from="*" to={"/"} />;
    }

    return (
        <div css={authContainerStyle}>
            {loading && (<Loading onTop overlay overlayColour={"white"} />)}
            {isSignUp ? (
                <SignUp onSignIn={signUpToggle} errorMessage={errorMessage} redirectAfterSignUp={redirectAfterAuth}/>
            ) : (
                <SignIn
                    socialSignUp={socialSignUp}
                    errorMessage={errorMessage}
                    redirectAfterSignIn={redirectAfterAuth}
                    isLoginDialog={isLoginDialog}
                    onLoginDialogClose={onLoginDialogClose}
                />
            )}
        </div>
    );
};

const authContainerStyle = (theme: Theme) => css`
    padding: 0;
    margin: 0;
    position: relative;

    ${theme.breakpoints.down('sm')} {
        width: 100%;
    }
`

export default Auth;
