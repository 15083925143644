import { useCallback, useEffect, useRef, useState } from 'react';

const useOnce = (callback: Function) => {
    const ref = useRef<Function>()
    const [called, setCalled] = useState(false)

    useEffect(() => {
        ref.current = callback
    }, [callback])

    const once = useCallback((force?: boolean) => {
        if (called && !force) return
        setCalled(true)
        ref.current && ref.current();
    }, [called])

    return once
};

export default useOnce