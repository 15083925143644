import React from "react";
import { css } from "@emotion/core";
import theme from "../../../../theme";

type Props = {
    children: React.ReactNode,
};

const BurgerNavLeft: React.FC<Props> = ({ children }) => {
    const style = css`
        padding: 25px 0 0 55px;
        width: 50%;
        font-weight: ${theme.fonts.weights.normal};
        background-color: ${theme.colours.curiousBlue};
        box-shadow: ${theme.borderAndShadow.boxInsetTopShadow};

        ${theme.breakpoints.down("md")} {
            display: none;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default BurgerNavLeft;
