import React from "react";
import { css } from "@emotion/core";

import theme from  "../../../../theme";
import { sendCorporate } from "../../../includes/navigate";

type Props = {
    children: React.ReactNode,
    headerMenu: StandardPage,
    currentURLSegment?: string,
    selectedHeaderMenu?: StandardPage,
    selectedSegments?: string[],
    hasSubMenu: boolean,
    setSubMenu: Function,
    closeMenuHandler: Function,
    wasBurgerMenuClicked: boolean,
};

const BurgerNavHeaderMenuContainer: React.FC<Props> = ({
    children,
    headerMenu,
    currentURLSegment = "",
    selectedHeaderMenu = undefined,
    selectedSegments = undefined,
    hasSubMenu,
    setSubMenu,
    closeMenuHandler,
    wasBurgerMenuClicked,
}) => {
    const setSubMenuHandler = () => {
        if (hasSubMenu) {
            setSubMenu({ headerMenu, keepHeader: true });
        } else if (headerMenu?.isClickable && currentURLSegment !== headerMenu?.uRLSegment) {
            sendCorporate(headerMenu?.uRLSegment);
            closeMenuHandler();
        }
    };

    const style = css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
        padding: 0 40px 0 62px;
        width: 100%;
        height: 56px;
        font-size: 20px;
        border-radius: ${hasSubMenu ? "25px 0 0 25px" : "25px"};
        color: ${theme.colours.white};
        cursor: pointer;

        // nav-menu-underline-tablet should be defined for the clicked menu only.
        ${selectedSegments?.includes(headerMenu?.uRLSegment) && css`
            .nav-menu-underline-tablet {
                margin-top: 3px;
                width: 100%;
                height: 4px;
                border-radius: 2px;
                background-color: ${wasBurgerMenuClicked ? theme.colours.curiousBlue : "white"};
            }
        `}

        ${(wasBurgerMenuClicked && headerMenu?.uRLSegment === selectedHeaderMenu?.uRLSegment) ? css`
            color: ${theme.colours.curiousBlue};
            background-color: white;
        ` : css`
            .nav-menu-underline-tablet {
                background-color: white;
            }
        `}

        ${headerMenu.uRLSegment !== selectedHeaderMenu?.uRLSegment && css`
            &:hover {
                div {
                    color: ${theme.colours.gold};

                    .nav-menu-underline-tablet {
                        background-color: ${theme.colours.gold};
                    }
                }
            }
        `}
    `;

    return (
        <div css={style} onClick={setSubMenuHandler}>
            {children}
        </div>
    );
};

export default BurgerNavHeaderMenuContainer;
