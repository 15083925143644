import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/core";

import theme from "../../../theme";
import { AUTH_LOGIN_URL } from "../../includes/pricingAuthRedirects";
import { sendCorporate } from "../../includes/navigate";
import useBreakpoint from "../../hooks/useBreakpoint";
import { useAppState } from "../../contexts/AppContext";
import * as storage from "../../includes/localStorage";
import { NOOP } from "../../../config/constants";

import RoundTextButton from "../buttons/RoundTextButton";
import ProfileIcon from "../icons/ProfileIcon";
import BurgerProfile from "./BurgerProfile";
import { logout } from "../../includes/auth";

type Props = {
    setSubMenuPosition?: Function,
    closeMenuHandler?: Function,
    menuButton?: Button,
    hideCustomButton?: boolean,
    buttonFontSize?: number,
};

type State = {
    firstName: string,
    surname: string,
    email: string,
    lastLoginType: string,
    socialAvatarUrl: string,
};

const DEFAULT_STATE = {
    firstName: "",
    surname: "",
    email: "",
    lastLoginType: "",
    socialAvatarUrl: "",
};

const BurgerNavLinkButtons: React.FC<Props> = ({
    setSubMenuPosition = NOOP,
    closeMenuHandler = NOOP,
    menuButton = undefined,
    hideCustomButton = false,
    buttonFontSize = 18,
}) => {
    const [appState] = useAppState();
    const [userData, setUserData] = useState<State>(DEFAULT_STATE);
    const history = useHistory();
    const { minWidth } = useBreakpoint();
    const linkURL = menuButton?.innerURL || menuButton?.outerURL;
    const buttonWidth = 167, buttonHeight = 48;

    useEffect(() => {
        const socialAvatarUrl = ["google", "facebook"].some(
            el => appState?.user?.LastLoginType.includes(el)
        ) ? storage.retrieve("socialAvatarUrl") : null;

        setUserData({
            firstName: appState?.user?.FirstName,
            surname: appState?.user?.Surname,
            email: appState?.user?.Email,
            lastLoginType: appState?.user?.LastLoginType,
            socialAvatarUrl: socialAvatarUrl
        });
    }, [appState]);

    const onClickHandler = () => {
        history.push({
            pathname: AUTH_LOGIN_URL,
            state: {redirectTo: history.location.pathname},
        });
        closeMenuHandler();
    };

    const onLogoutClick = useCallback(() => {
        logout().then(() => {
            appState.clearUser();
            sendCorporate("");
        })
    }, [appState]);

    const onAccountClick = useCallback(() => {
        history.push("/manage");
    }, [history]);

    useEffect(() => {
        if (minWidth >= theme.breakpoints.sizes["xl"]) {
            setSubMenuPosition();
        }
    }, [minWidth, setSubMenuPosition]);

    const style = css`
        flex-direction: row;
        display: flex;
        align-items: center;

        ${appState?.user && css`
            padding-left: 17px;
        `};

        ${theme.breakpoints.down("lg")} {
            flex-direction: column;

            a:last-child {
                margin-top: 12px;
            }
        }
    `;

    const buttonLayoutStyle = css`
        ${appState?.user && css`
            button:first-of-type {
                margin-top: 23px;
            }
        `}
    `;

    const buttonMarginStyle = css`
        margin-top: 12px;
    `;

    const profileIconStyle = css`
        width: 43px;
        height: 43px;
    `;

    const userContainerStyle = css`
        display: flex;
        flex-direction: row;
        margin-bottom: 28px;
    `;

    return (
        <div css={style}>
            <div css={userContainerStyle}>
                {appState?.user && (
                    userData.socialAvatarUrl
                        ? (<img css={profileIconStyle} src={userData.socialAvatarUrl} alt="" />)
                        : (
                            <ProfileIcon
                                colour={theme.colours.curiousBlue}
                                backgroundColour={theme.colours.white}
                                css={profileIconStyle}
                            />
                        )
                    )
                }
                <div>
                    {appState?.user && (
                        <BurgerProfile userData={userData} />
                    )}
                    <div css={buttonLayoutStyle}>
                        {(!hideCustomButton && menuButton?.buttonText && linkURL) && (
                            <RoundTextButton
                                onClick={()=>{sendCorporate(linkURL)}}
                                minWidth={buttonWidth}
                                height={buttonHeight}
                                fontSize={buttonFontSize}
                                textColour={"black"}
                                backgroundColour={theme.colours.gold}
                                backgroundHoverColour={theme.colours.mySin}
                                buttonBorder={0}
                                useBoxShadow={false}
                                noButtonBorderHover
                            >
                                {menuButton.buttonText}
                            </RoundTextButton>
                        )}
                        {(userData?.email || appState?.user?.Email) ? (
                            <>
                                {appState?.user?.Subscription && (
                                    <RoundTextButton
                                        onClick={onAccountClick}
                                        width={buttonWidth}
                                        height={buttonHeight}
                                        fontSize={buttonFontSize}
                                        backgroundColour={theme.colours.frenchRose}
                                        backgroundHoverColour={theme.colours.pinkSalmon}
                                        css={buttonMarginStyle}
                                    >
                                        Account
                                    </RoundTextButton>
                                )}
                                <RoundTextButton
                                    onClick={onLogoutClick}
                                    width={buttonWidth}
                                    height={buttonHeight}
                                    fontSize={buttonFontSize}
                                    textColour={"black"}
                                    textHoverColour={theme.colours.curiousBlue}
                                    backgroundColour={"white"}
                                    backgroundHoverColour={"white"}
                                    noButtonBorderHover
                                    css={buttonMarginStyle}
                                >
                                    Log out
                                </RoundTextButton>
                            </>
                        ) : (
                            <RoundTextButton
                                onClick={onClickHandler}
                                width={buttonWidth}
                                height={buttonHeight}
                                fontSize={buttonFontSize}
                                textColour={"black"}
                                textHoverColour={theme.colours.curiousBlue}
                                backgroundColour={"white"}
                                backgroundHoverColour={"white"}
                                noButtonBorderHover
                                css={buttonMarginStyle}
                            >
                                Log in
                            </RoundTextButton>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BurgerNavLinkButtons;
