import React from "react";
import { css } from "@emotion/core";
import ReactCollapse from "@kunukn/react-collapse";

type Props = {
    children: React.ReactNode,
    isOpen: boolean,
    slideEffect?: boolean,
    className?: string,
    collapseHeight?: string,
    transitionTime?: string,
};

const Collapse: React.FC<Props> = ({
    isOpen,
    children,
    slideEffect = true,
    className = "",
    collapseHeight = "0px",
    transitionTime = "500ms",
}) => {
    const collapseStyle = css`
        ${slideEffect && css`
            transition: height ${transitionTime} cubic-bezier(0.4, 0, 0.2, 1);
        `};
    `;

    return (
        <ReactCollapse
            isOpen={isOpen}
            css={collapseStyle}
            className={className}
            collapseHeight={collapseHeight}
        >
            {children}
        </ReactCollapse>
    );
};

export default Collapse;
