import React, { memo } from 'react';
import { css, SerializedStyles } from "@emotion/core";

const styles = (customCss?: SerializedStyles, customButtonCss?: SerializedStyles) => (theme: any) => css`
    margin-top: ${theme.forms.spacing.margin};
    display: flex;
    justify-content: right;

    button {
        margin-left: ${theme.forms.spacing.margin};
        &:first-of-type {
            margin-left: 0;
        }

        ${!!customButtonCss && customButtonCss}
    }

    ${!!customCss && customCss}
`;

type Props = {
    children: React.ReactNode,
    customCss?: SerializedStyles,
    customButtonCss?: SerializedStyles,
};

const FormActions: React.FC<Props> = ({ children, customCss, customButtonCss }) => (
    <div css={styles(customCss, customButtonCss)}>{children}</div>
);

export default memo(FormActions);
