import React from "react";
import { css } from "@emotion/core";

type Props = {
    children: React.ReactNode,
};

const FooterContainer: React.FC<Props> = ({ children }) => {
    const style = css`
        padding: 21.5px 0 45px;
        width: 100%;

        a {
            color: inherit;
            text-decoration: none;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default FooterContainer;
