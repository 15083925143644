import React, { lazy, Suspense, useState, useCallback } from "react";
import { Route, Switch } from "react-router-dom";
import { css } from "@emotion/core";
import { isEmpty } from "lodash";

import { route } from "../core/includes/routes";
import TermsAndConditions from "./terms-and-conditions/TermsAndConditions";
import AuthPage from "./auth-page/AuthPage";
import PricingPage from "./pricing-page/PricingPage";
import Header from "../core/components/header/corporate/Header";
import Loading from "../core/components/utils/Loading";
import Footer from "../core/components/footer/corporate/Footer";

const ForHome = lazy(() => import("./for-home/ForHome"));
const ForSchool = lazy(() => import("./for-school/ForSchool"));
const About = lazy(() => import("./about/About"));
const FAQS = lazy(() => import("./faqs/FAQS"));
const Subscriptions = lazy(() => import("./subscriptions/Subscriptions"));
const ContactUs = lazy(() => import("./contact-us/ContactUs"));
const Features = lazy(() => import("./features/Features"));
const HomePage = lazy(() => import("./home-page/HomePage"));
const WtbEvent = lazy(() => import("./wtb-events/WtbEvent"));
const LandingPage = lazy(() => import("./landing-page/LandingPage"));
const BlogNavigation = lazy(() => import("./blog/BlogNavigation"));

const MainSite = () => {
    const [footerMenu, setFooterMenu] = useState<{ menu: [any], side: [any], bottom: [any], all: [StandardPage] }>();
    const [showBreadcrumb, setShowBreadcrumb] = useState(true);

    // All menu information of the Footer
    const footerMenuHandler = useCallback((pages: any) => {
        if (!isEmpty(pages)) {
            setFooterMenu(pages);
        }
    }, []);

    const breadcrumbHandler = useCallback((showBreadcrumb: boolean) => {
        setShowBreadcrumb(showBreadcrumb);
    }, [setShowBreadcrumb]);

    return (
        <div css={containerStyles}>
            <Header footerMenuHandler={footerMenuHandler} breadcrumbHandler={breadcrumbHandler} />
            <Suspense fallback={<Loading delay={300} />}>
                <Switch>
                    <Route path={route('auth')}>
                        <AuthPage />
                    </Route>
                    <Route path={route('pricing')}>
                        <PricingPage showBreadcrumb={showBreadcrumb} />
                    </Route>
                    <Route path={route('for-home')}>
                        <ForHome />
                    </Route>
                    <Route path={route('for-school')}>
                        <ForSchool />
                    </Route>
                    <Route path={route('about')}>
                        <About />
                    </Route>
                    <Route path={route('faqs')}>
                        <FAQS />
                    </Route>
                    <Route path={route('subscriptions')}>
                        <Subscriptions />
                    </Route>
                    <Route path={route('contact-us')}>
                        <ContactUs />
                    </Route>
                    <Route path={route('features')}>
                        <Features />
                    </Route>
                    <Route path={route('terms-and-conditions')}>
                        <TermsAndConditions />
                    </Route>
                    <Route path={route('wtb-event')}>
                        <WtbEvent />
                    </Route>
                    <Route path={route('free-trial-writers-toolbox-improve-writing-skills')}>
                        <LandingPage />
                    </Route>
                    <Route path={route('blog-base')}>
                        <BlogNavigation />
                    </Route>
                    <Route path={route('')}>
                        <HomePage key={'homepage'} />
                    </Route>
                </Switch>
                <Footer footerMenu={footerMenu} />
            </Suspense>
        </div>
    );
};

const containerStyles = css`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

export default MainSite;
