import React from "react";
import { css } from "@emotion/core";

import theme from "../../../../theme";
import { sendCorporate } from "../../../includes/navigate";

import FooterLink from "./FooterLink";

type Props = {
    footerBottomMenu: [any] | undefined,
};

const FooterBottomSection: React.FC<Props> = ({ footerBottomMenu }) => {
    const style = css`
        display: flex;
        justify-content: end;
        margin-top: 16px;
        width: 100%;
        cursor: pointer;

        ${theme.breakpoints.down("md")} {
            justify-content: start;
            flex-direction: column;
        }
    `;

    return (
        <div css={style}>
            {footerBottomMenu?.map((menu: StandardPage) => (
                <FooterLink
                    key={"footer" + menu.id}
                    onClickHandler={()=>sendCorporate(menu.uRLSegment)}
                    isBottomMenu
                >
                    {menu.titleForFooter ?? menu.title}
                </FooterLink>
            ))}
        </div>
    );
};

export default FooterBottomSection;
