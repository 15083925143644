import React from 'react';
import { css } from '@emotion/core';

import { Theme } from '../../../theme';

const OrderSummaryLine: React.FC = () => <div css={style} />;

const style = (theme: Theme) => css`
    width: calc(100% - 20px);
    margin: 0 10px;
    height: 1px;
    background-color: ${theme.colours.grey[450]};
`;

export default OrderSummaryLine;
