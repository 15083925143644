import React, { useCallback } from 'react';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/core';
import { useAppState } from "../../contexts/AppContext";
import Form from "../form/Form";
import Input from "../form/Input";
import FormActions from "../form/FormActions";
import Button from "../buttons/Button";
import useForm, { FieldList } from "../../hooks/useForm";
import { useMutation } from "@apollo/react-hooks";
import LabelledField from "../form/LabelledField";
import * as storage from "../../includes/localStorage";
import Loading from "../utils/Loading";
import { Theme } from "../../../theme";
import { isEmpty, trim } from "lodash";
import useSetState from "../../hooks/useSetState";
import validationErrorParser, { ValidationErrorType } from "../../includes/validationErrorParser";
import useToast from "../../hooks/useToast";
import useRedirectHomeIfOnRestrictedPage from "../../hooks/useRedirectHomeIfOnRestrictedPage";

type DefaultState = {
    isSubmitting: boolean,
}

const INITIAL_STATE = {
    isSubmitting: false,
}

type Props = {
    successUrl?: string
};

const SocialSignUp: React.FC<Props> = ({successUrl = '/pricing/select-plan'}) => {
    useRedirectHomeIfOnRestrictedPage();
    const socialFirstName = storage.retrieve('socialFirstName');
    const socialLastName = storage.retrieve('socialLastName');
    const history = useHistory();
    const [, errorToast] = useToast();
    const [state, setState] = useSetState<DefaultState>(INITIAL_STATE);
    const [appState] = useAppState();

    const [fields, errors, onFieldChange,, validate, setFormErrors] = useForm({
        fields: {
            firstName: socialFirstName,
            surname: socialLastName
        },
        validator,
    });

    const [saveData] = useMutation(CONFIRM_SOCIAL_MUTATION);

    const submitSocialSignUpForm = useCallback(() => {

        if (validate()) {
            setState({ isSubmitting: true })
            saveData({
                variables: {
                    firstName: fields.firstName,
                    surname: fields.surname,
                }
            }).then((data) => {
                appState.refetchUser().then(() => {
                    history.push(successUrl);
                });
            }).catch((e) => {
                setState({ isSubmitting: false });

                const validationErrors: ValidationErrorType = validationErrorParser(e)
                if (!isEmpty(validationErrors)) {
                    setFormErrors(validationErrors);
                }

                if (validationErrors["errorMessage"]) {
                    errorToast(validationErrors["errorMessage"]);
                }
            });
        }
    }, [appState, fields, history, saveData, validate, successUrl, setState, setFormErrors, errorToast]);

    if (!appState.hasUserBeenLoaded) {
        return <Loading />;
    }

    return (
        <div css={socialSignUpContent}>
            <div css={socialSignUpHeader}>
                <div css={leftHeader}>What should we call you?</div>
                <div css={rightSubHeader}>All fields required</div>
            </div>
            <div css={formContainerStyle}>
                <Form onSubmit={submitSocialSignUpForm}>
                    <LabelledField name="firstName" error={errors.firstName} label="First name">
                        <Input hasError={!!errors.firstName} css={firstNameInput} label="First name" name="firstName" onChange={onFieldChange} value={fields.firstName} />
                    </LabelledField>
                    <LabelledField name="surname" error={errors.surname} label="Surname">
                        <Input hasError={!!errors.surname} css={surnameInput} label="Surname" name="surname" onChange={onFieldChange} value={fields.surname} />
                    </LabelledField>
                    <div css={formActions}>
                        <FormActions>
                            <Button
                                css={formSubmitButton}
                                type="submit"
                                forceLoading={state.isSubmitting}
                            >
                                Continue
                            </Button>
                        </FormActions>
                    </div>
                </Form>
            </div>
        </div>
    );
};

type ErrorsType = {
    firstName?: string,
    surname?: string,
}

const validator = (fields:FieldList) => {
    const errors: ErrorsType = {};

    if (!trim(fields.firstName)) {
        errors.firstName = 'Please provide your first name';
    }

    if (!trim(fields.surname)) {
        errors.surname = 'Please provide your surname';
    }

    return errors;
};

const CONFIRM_SOCIAL_MUTATION = gql`
  mutation confirmSocialHomeUser($firstName: String!, $surname: String!) {
      confirmSocialHomeUser(firstName: $firstName, surname: $surname) {
        id
      }
}
`;

const socialSignUpContent = css`
    margin-top: 40px;
    width: 510px;
`;

const formContainerStyle = css `
    margin-top: 12px;
`;

const firstNameInput = css`
    margin-top: 0;
    box-sizing: border-box;
    width: 100%;
`;

const surnameInput = css`
    margin-top: 0;
    box-sizing: border-box;
    width: 100%;
`;

const formActions = css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const formSubmitButton  = (theme: Theme) => css`
    background-color: ${theme.colours.blue['400']};
    width: 124px;
`;

const socialSignUpHeader = (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${theme.colours.grey['90']};
    width: 510px;
    height: 50px;
`;

const leftHeader = css`
    font-size: 26px;
    margin-left: 20px;
    font-weight: 400;
`;

const rightSubHeader = css`
    font-size: 16px;
    margin-right: 34px;
    font-weight: 400;
`;

export default SocialSignUp;
