import { StripePlan } from "../hooks/useStripePlans";

export const getNiceUnitAmount = (unitAmount: number) => unitAmount / 100;

export const getNicePriceFromTier = (plan: StripePlan, numberOfUsers: string) => {
    const upToValue = parseInt(numberOfUsers) === 1 ? 1 : null; // UpTo value will be 1 for 1 account, or null for multiple
    const unitAmount = plan.Tiers.filter( tier => tier.UpTo === upToValue)[0].UnitAmount;
    return getNiceUnitAmount(unitAmount);
};

export const getNiceTotalPriceFromTier = (plan: StripePlan, numberOfUsers: string) => {
    const nicePrice = getNicePriceFromTier(plan, numberOfUsers)
    return (nicePrice * parseInt(numberOfUsers)).toFixed(2)
};

export const getPlanByInterval = (plans: Array<StripePlan>, interval: string) => {
    return plans.filter( plan => plan.Interval === interval)[0];
};

export const getPriceByInterval = (plan: StripePlan, numberOfUsers: string) => {
    const price = getNicePriceFromTier(plan, numberOfUsers);

    return plan.Interval === 'month' ? price : price / 12;
};

export default {
    getNiceUnitAmount,
    getNicePriceFromTier,
    getNiceTotalPriceFromTier,
    getPlanByInterval,
    getPriceByInterval,
};
