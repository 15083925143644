import React, { useMemo } from "react";
import { css } from "@emotion/core";
import theme from "../../../../theme";
import FooterMenuBox from "./FooterMenuBox";
import FooterVerticalMenuBox from "./vertical/FooterVerticalMenuBox";
import useBreakpoint from "../../../hooks/useBreakpoint";

type Props = {
    footerMenu: [any] | undefined,
};

const FooterMainSection: React.FC<Props> = ({ footerMenu }) => {
    const { minWidth } = useBreakpoint();

    const showHorizontalMenu = useMemo(() => {
        switch (true) {
            case minWidth >= theme.breakpoints.sizes["lg"]:
                return true;
            default:
                return false;
        }
    }, [minWidth]);

    const style = css`
        display: flex;
        justify-content: space-between;
        width: 100%;
    `;

    return (
        <div css={style}>
            {showHorizontalMenu ? (
                <FooterMenuBox footerMenu={footerMenu} />
            ) : (
                <FooterVerticalMenuBox footerMenu={footerMenu} />
            )}
        </div>
    );
};

export default FooterMainSection;
