import React, { useMemo } from "react";
import { css } from "@emotion/core";

import theme from "../../../theme";
import BurgerNavMenuIconLines from "./BurgerNavMenuIconLines";
import useBreakpoint from "../../hooks/useBreakpoint";

type Props = {
    showBurgerMenu?: boolean,
    toggleBurgerMenu: Function,
};

const BurgerNavMenuIcon: React.FC<Props> = ({ showBurgerMenu = false, toggleBurgerMenu }) => {
    const { minWidth } = useBreakpoint();

    const onClickHandler = () => {
        toggleBurgerMenu({ forcedClose: false });
    };

    const iconSize = useMemo(() => {
        switch (true) {
            case minWidth >= theme.breakpoints.sizes["md"]:
                return 28;
            default:
                return 24;
        }
    }, [minWidth]);


    const style = css`
        margin-left: ${theme.sizes.menu.sideMargin}px;
        width: ${iconSize}px;
        height: ${iconSize}px;
        cursor: pointer;
        z-index: ${theme.zIndex.zIndexHamburgerMenu};

        ${theme.mixins.bloop};
    `;

    return (
        <div css={style} onClick={onClickHandler}>
            <BurgerNavMenuIconLines showBurgerMenu={showBurgerMenu} iconSize={iconSize} />
        </div>
    );
};

export default BurgerNavMenuIcon;
