import React from "react";
import { css } from "@emotion/core";

import theme from "../../../../theme";
import { NOOP } from "../../../../config/constants";

type Props = {
    children: React.ReactNode,
    isMainMenu?: boolean,
    isBottomMenu?: boolean,
    parentID?: number,
    onClickHandler?: (e: React.MouseEvent) => void,
};

const FooterLink: React.FC<Props> = ({
    children,
    isMainMenu = false,
    isBottomMenu = false,
    parentID = -1,
    onClickHandler = NOOP,
}) => {
    const style = css`
        color: ${theme.colours.doveGray};

        ${isMainMenu && css`
            margin-top: 20px;

            ${theme.breakpoints.down("xl")} {
                font-size: 12px;
            }
        `}

        ${isBottomMenu && css`
            ${theme.breakpoints.up("lg")} {
                margin-left: 55px;
            }

            ${theme.breakpoints.down("md")} {
                margin-top: 29px;
            }
        `}

        ${isMainMenu && parentID === 0 && css`
            margin-top: 0;
            color: ${theme.colours.black};
            font-size: 16px;
            font-weight: ${theme.fonts.weights.bold};

            ${theme.breakpoints.down("xl")} {
                font-size: 14px;
            }
        `};
    `;

    return (
        <div css={style} onClick={onClickHandler}>
            {children}
        </div>
    );
};

export default FooterLink;
