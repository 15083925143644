import {ApolloError} from "apollo-client";

const errorParser = (error: any): string => {
    let message = '';

    if (error instanceof ApolloError) {
        if (error.graphQLErrors && error.graphQLErrors.length) {
            message = error.graphQLErrors[0].message;
        } else if(error.networkError) {
            const errorObj: any = JSON.parse(JSON.stringify(error.networkError));
            message = `${errorObj.statusCode}`;
        } else {
            message = error.message;
        }

        // generic error
    } else if (error instanceof Error) {
        message = error.message;

        // default, assume string
    } else {
        message = error;
    }

    return message;
};

export default errorParser;
