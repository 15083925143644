import colours from "./colours";
import { css } from "@emotion/core";

// frutiger light
import frutigerLightEot from '../fonts/81e3653a-53bd-49d9-a1f8-f924811b6f02.eot';
import frutigerLightTtf from '../fonts/83c94878-06c8-442c-a7df-d520b0ced00b.ttf';
import frutigerLightWoff2 from '../fonts/087d8a95-3f25-4bae-a1d1-db9ddffd72f5.woff2';
import frutigerLightWoff from '../fonts/ab1bcdf7-9570-4cba-a8e3-3e39ddd861db.woff';

// frutiger light italic
import frutigerLightItalicEot from '../fonts/381ea1e1-9a08-4929-a402-63be7fd76549.eot';
import frutigerLightItalicTtf from '../fonts/1149176e-a9be-41bf-822e-275d9da199e5.ttf';
import frutigerLightItalicWoff2 from '../fonts/db6ed4c5-5248-41f3-a6b5-4e8ff1d5ab06.woff2';
import frutigerLightItalicWoff from '../fonts/38f88aba-a7d0-4a78-9323-ac58226d277e.woff';

// frutiger normal
import frutigerNormalEot from '../fonts/fe232554-5489-4874-bb45-bd966c8c5932.eot';
import frutigerNormalTtf from '../fonts/caedf2e6-fbef-4928-b08e-1f975981ae8d.ttf';
import frutigerNormalWoff2 from '../fonts/0811514e-6660-4043-92c1-23d0b8caaa2f.woff2';
import frutigerNormalWoff from '../fonts/aeae8cac-ba4d-40d2-bbcf-936f2c7dcfe6.woff';

// frutiger normal italic
import frutigerNormalItalicEot from '../fonts/f8bbb037-530d-4e04-a651-4134f7031164.eot';
import frutigerNormalItalicTtf from '../fonts/ba125970-2d75-413e-a466-324153755f20.ttf';
import frutigerNormalItalicWoff2 from '../fonts/f5a95cb0-6d5b-4eb4-b6a3-a529d5ccde25.woff2';
import frutigerNormalItalicWoff from '../fonts/01f463dc-dba9-419f-9c9f-80ac2ee9f427.woff';

// frutiger 700
import frutiger700Eot from '../fonts/eb06b4c3-c0fd-4cd0-a9d9-a2954f6839a7.eot';
import frutiger700Ttf from '../fonts/5dae3a6e-9b07-4c2f-aed2-1f5b8951261b.ttf';
import frutiger700Woff2 from '../fonts/8c92eb68-ce37-4962-a9f4-5b47d336bd1c.woff2';
import frutiger700Woff from '../fonts/9bdfdcac-bbd4-46a9-9280-056c9a4438a2.woff';

// frutiger 700 italic
import frutiger700ItalicEot from '../fonts/36c26121-ef21-4e44-9f00-0388185c96aa.eot';
import frutiger700ItalicTtf from '../fonts/db4ea3f5-223b-4821-957f-6eb868db73b1.ttf';
import frutiger700ItalicWoff2 from '../fonts/9e60a7b4-389b-4630-9fa6-1abd9be2035f.woff2';
import frutiger700ItalicWoff from '../fonts/3048913e-d0c8-4b82-978a-aa862248bea4.woff';

// frutiger 800
import frutiger800Eot from '../fonts/fb1e4ad2-83fa-4735-aa1b-900f4a36749f.eot';
import frutiger800Ttf from '../fonts/558394f3-8981-4be9-8a8a-c23488eb9b6d.ttf';
import frutiger800Woff2 from '../fonts/221b3252-186e-4a32-a90b-cc0c3a4817d7.woff2';
import frutiger800Woff from '../fonts/a061c7a6-3b14-451e-883c-0f1661ac3d76.woff';

// frutiger 800 italic
import frutiger800ItalicEot from '../fonts/ad058bb8-7a08-4adb-81be-b97f0d00b951.eot';
import frutiger800ItalicTtf from '../fonts/61770e26-a01e-4032-83a6-32308bb7be52.ttf';
import frutiger800ItalicWoff2 from '../fonts/1ca1f0ee-8bf4-4a8f-9e47-f322036921a9.woff2';
import frutiger800ItalicWoff from '../fonts/0d1f6923-8720-40fa-a8c9-5ba166ae2b98.woff';

// frutiger 900
import frutiger900Eot from '../fonts/2c0f416b-892b-4223-b90e-a0986b832d52.eot';
import frutiger900Ttf from '../fonts/3fbefead-675a-4932-a670-f91da78ceece.ttf';
import frutiger900Woff2 from '../fonts/b3b6a774-8da3-4970-baa7-426572975164.woff2';
import frutiger900Woff from '../fonts/145ceb9b-3d3e-4e3f-b5a8-9c9cf876cd0d.woff';

// Kentucky Fried
import kentuckyFriedEot from '../fonts/kentuckyfried/3A31A6_0_0.eot';
import kentuckyFriedTtf from '../fonts/kentuckyfried/3A31A6_0_0.ttf';
import kentuckyFriedWoff from '../fonts/kentuckyfried/3A31A6_0_0.woff';
import kentuckyFriedWoff2 from '../fonts/kentuckyfried/3A31A6_0_0.woff2';

export const fontImports = css`

@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=5835e2d3-307b-4233-b69b-fc4f93174765");

@font-face {
    font-family:"Frutiger"; /* font-family:"Frutiger LT W01_45 Ligh1475730*/
    src:url("${frutigerLightEot}?#iefix");
    src:url("${frutigerLightEot}?#iefix") format("eot"),url("${frutigerLightWoff2}") format("woff2"),url("${frutigerLightWoff}") format("woff"),url("${frutigerLightTtf}") format("truetype");
    font-weight: 100;
}

@font-face {
    font-family:"Frutiger"; /* font-family:"Frutiger LT W01_46 Ligh1475734*/
    src:url("${frutigerLightItalicEot}?#iefix");
    src:url("${frutigerLightItalicEot}?#iefix") format("eot"),url("${frutigerLightItalicWoff2}") format("woff2"),url("${frutigerLightItalicWoff}") format("woff"),url("${frutigerLightItalicTtf}") format("truetype");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family:"Frutiger"; /* font-family:"Frutiger LT W01_55 Roma1475738*/
    src:url("${frutigerNormalEot}?#iefix");
    src:url("${frutigerNormalEot}?#iefix") format("eot"),url("${frutigerNormalWoff2}") format("woff2"),url("${frutigerNormalWoff}") format("woff"),url("${frutigerNormalTtf}") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family:"Frutiger"; /* font-family:"Frutiger LT W01_56 Italic*/
    src:url("${frutigerNormalItalicEot}?#iefix");
    src:url("${frutigerNormalItalicEot}?#iefix") format("eot"),url("${frutigerNormalItalicWoff2}") format("woff2"),url("${frutigerNormalItalicWoff}") format("woff"),url("${frutigerNormalItalicTtf}") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family:"Frutiger"; /* font-family:"Frutiger LT W01_65 Bold1475746*/
    src:url("${frutiger700Eot}?#iefix");
    src:url("${frutiger700Eot}?#iefix") format("eot"),url("${frutiger700Woff2}") format("woff2"),url("${frutiger700Woff}") format("woff"),url("${frutiger700Ttf}") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family:"Frutiger"; /* font-family:"Frutiger LT W01_66 Bold Italic*/
    src:url("${frutiger700ItalicEot}?#iefix");
    src:url("${frutiger700ItalicEot}?#iefix") format("eot"),url("${frutiger700ItalicWoff2}") format("woff2"),url("${frutiger700ItalicWoff}") format("woff"),url("${frutiger700ItalicTtf}") format("truetype");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family:"Frutiger"; /* font-family:"Frutiger LT W01_75 Black*/
    src:url("${frutiger800Eot}?#iefix");
    src:url("${frutiger800Eot}?#iefix") format("eot"),url("${frutiger800Woff2}") format("woff2"),url("${frutiger800Woff}") format("woff"),url("${frutiger800Ttf}") format("truetype");
    font-weight: 800;
}

@font-face {
    font-family:"Frutiger"; /* font-family:"Frutiger LT W01_76 Blac1475758*/
    src:url("${frutiger800ItalicEot}?#iefix");
    src:url("${frutiger800ItalicEot}?#iefix") format("eot"),url("${frutiger800ItalicWoff2}") format("woff2"),url("${frutiger800ItalicWoff}") format("woff"),url("${frutiger800ItalicTtf}") format("truetype");
    font-weight: 800;
    font-style: italic;
}

@font-face{
    font-family:"Frutiger"; /* font-family:"Frutiger LT W01_95 Ultra Black*/
    src:url("${frutiger900Eot}?#iefix");
    src:url("${frutiger900Eot}?#iefix") format("eot"),url("${frutiger900Woff2}") format("woff2"),url("${frutiger900Woff}") format("woff"),url("${frutiger900Ttf}") format("truetype");
    font-weight: 900;
}

@font-face{
    font-family:"Kentuckyfried"; // font-family:"Kentuckyfried";
    src:url("${kentuckyFriedEot}?#iefix");
    src:url("${kentuckyFriedEot}?#iefix") format("eot"),url(${kentuckyFriedWoff2}) format("woff2"),url(${kentuckyFriedWoff}) format("woff"),url(${kentuckyFriedTtf}) format("truetype");
}
`;

export const fonts = {
    frutiger: '"Frutiger", "helvetica neue", helvetica, arial, sans-serif',
    primary: '"Frutiger", "helvetica neue", helvetica, arial, sans-serif',
    arial: 'arial, sans-serif',
    kentuckyFried: '"Kentuckyfried", "helvetica neue", helvetica, arial, sans-serif',

    baseSize: '14px',
    colour: colours.grey[900],
    lineHeight: 1.25,
    weights: {
        base: 400,
        lighter: 100,
        light: 300,
        normal: 400,
        medium: 500,
        bold: 700,
        bolder: 'bolder'
    },
};
