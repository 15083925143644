const BASE_PATH = 'event';

const routes = {
    'wtb-event-base': {
        link: `/${BASE_PATH}`
    },
    'wtb-event': {
        link: `/${BASE_PATH}/:title`
    },
    'wtb-event-success': {
        link: `/${BASE_PATH}/:title/success`
    },
    'wtb-event-expired': {
        link: `/${BASE_PATH}/:title/expired`
    },
}

export default routes;
