import React from "react";
import { ToastContainer, ToastContainerProps } from "react-toastify";
import { Theme } from "../../../theme";
import { css } from "@emotion/core";
import { ReactComponent as CrossIcon } from "../../../images/icons/cross-no-fill.svg";
import "react-toastify/dist/ReactToastify.css";

const ToastMessage: React.FC = () => (
    <ToastContainer
        newestOnTop
        closeButton={<ToastCloseButton />}
        css={toastStyle}
    />
)

type ToastCloseButtonProps = {
    closeToast?: (e: React.MouseEvent) => void,
}

const ToastCloseButton: React.FC<ToastCloseButtonProps> = ({ closeToast }) => (
    <CrossIcon css={closeButtonStyle} onClick={closeToast} />
)

const closeButtonStyle = (theme: Theme) => css`
    margin: auto;
    height: 12px;
    width: 12px;
    opacity: 1;
    filter: invert(1);

    ${theme.mixins.bloop};
`

const toastStyle = (theme: Theme) => css`
    .Toastify {
        &__toast {
            display: flex;
            position: relative;
            font-weight: 100;
            font-size: 14px;
            min-height: 48px;
            font-family: ${theme.fonts.frutiger};
            color: ${theme.colours.white};
            border-radius: ${theme.borderAndShadow.smallRadius};
            
            &--no-pointer {
                cursor: default;
            }

            &--info {
                background-color: ${theme.colours.dodgerBlue};
                border: 1px solid ${theme.colours.denim};
            }

            &--success {
                background-color: ${theme.colours.emerald3};
                border: 1px solid ${theme.colours.chateauGreen};
            }

            &--error {
                background-color: ${theme.colours.cinnabar2};
            }
        }

        &__toast-container {
            z-index: ${theme.zIndex.zIndexHighest} + 1; // for make it higher than modal
        }

        &__toast-body {
            margin: 5px 22px 5px 10px;
            line-height: 21px;
        }
    }
`

const toastDuration: number = 5000;

export const defaultToastOptions: ToastContainerProps = {
    position: "top-right",
    autoClose: toastDuration,
    newestOnTop: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
};

export const errorToastOptions: ToastContainerProps = {
    position: "top-right",
    autoClose: toastDuration,
    newestOnTop: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true
};

export default ToastMessage;