import React, {memo, useCallback, useEffect, useMemo, useRef} from 'react';
import useSetState from "../../hooks/useSetState";
import SearchableListMenu, { Option } from "./SearchableListMenu";
import Input from "./Input";
import { css } from "@emotion/core";
import { Theme } from "../../../theme";
import DropdownChevron from "./DropdownChevron";
import { ReactComponent as CrossIcon } from "../../../images/icons/cross-no-fill.svg";
import Countries from "../country-dropdown/Countries";
import { OnFieldChange } from "../../hooks/useForm";
import { countryToPhoneCode } from "../../../theme/utils";

type GenericOption = {
    [valueOrLabel: string]: any,
};

type LabelFunction = (option: GenericOption) => string;

type Variant = 'country' | 'phone' | 'phone number';
type ExternalInputRef = ((instance: (HTMLInputElement | null)) => void) | React.RefObject<HTMLInputElement> | null | undefined;

type Props = {
    clearable?: boolean,
    disableSearch?: boolean,
    hasError?: boolean,
    labelField?: string | LabelFunction,
    name: string,
    options: GenericOption[],
    onChange: OnFieldChange,
    greyOutOptionFunc?: (option: Option) => boolean,
    placeholder?: string,
    value: string,
    valueField?: string,
    containsSearchBox?: boolean,
    onOptionSelectedFunc?: (option: string) => void,
    variant?: Variant,
    externalInputRef?: any,
    phoneCode?: string,
    className?: string,
};

type State = {
    active: boolean,
    search: string,
};

const DEFAULT_STATE: State = {
    active: false,
    search: '',
};

const SearchableList: React.FC<Props> = ({
    clearable = false,
    disableSearch = false,
    hasError = false,
    labelField = 'ID',
    name,
    options,
    onChange,
    greyOutOptionFunc,
    placeholder,
    value,
    valueField = 'Title',
    containsSearchBox = false,
    onOptionSelectedFunc,
    variant,
    externalInputRef,
    phoneCode,
    className,
}) => {
    const [state, setState] = useSetState<State>(DEFAULT_STATE);
    const dropdownRef = useRef<HTMLInputElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setState({
                    active: false,
                });
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setState, dropdownRef]);

    // generate label, values
    const labelledOptions: Option[] = useMemo(() => {
        // get labels
        return options?.map((option: GenericOption) => ({
            label: typeof labelField === "function" ? labelField(option) : option[labelField],
            value: option[valueField],
        }));
    }, [labelField, options, valueField]);

    const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
        setState({
            search: e.currentTarget.value,
        });
    };

    // determine the label of the current value
    const valueLabel = useMemo(() => {
        if (!value) {
            return '';
        }
        const selectedOption = labelledOptions.find((option) => option.value === value);
        return selectedOption ? selectedOption.label : value;
    }, [labelledOptions, value]);

    /**
     * Clear the value.
     */
    const onClear = useCallback(() => {
        onChange(name, '');
        setState({ search: "" });
    }, [onChange, name, setState]);

    /**
     * Handle show/hide of dropdown menu
     */
    const toggleDropdown = useCallback(() => {
        const isActive = !state.active;

        setState((prevState: State) => ({
            active: isActive,
        }));
    }, [setState, state.active]);

    const openDropdown = useCallback(() => {
        setState({
            active: true,
        });
    }, [setState]);

    const closeDropdown = useCallback(() => {
        setState({
            active: false,
        });
    }, [setState]);

    /**
     * Handle option being selected (change in value)
     */
    const onOptionSelected = useCallback((option: Option) => {
        onChange(name, option.value);
        externalInputRef?.current?.focus?.()

        if (onOptionSelectedFunc) {
            onOptionSelectedFunc(option.value);
        }

        closeDropdown();
        if (inputRef.current !== null)
            inputRef.current.blur();
    }, [name, onChange, closeDropdown, externalInputRef, onOptionSelectedFunc]);

    /**
     * Convert a country code to its corresponding flag.
     */
    function countryToFlag(code: string) {
        if (typeof String.fromCodePoint !== 'undefined') {
            const phoneCodeRegEx = /^\+[0-9]+$/;
            const isPhoneCode = phoneCodeRegEx.test(code);
            const country = isPhoneCode ? Countries.find(country => country.phone === code) : Countries.find(country => country.code === code);
            if (country && country.phone !== 'none') {
                const code = country?.code;
                return (
                    code
                        ?.toUpperCase()
                        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
                );
            } else return <span />
        }
    }

    return (
        <div ref={dropdownRef} className={className}>
            <div css={containerStyle(variant)}>
                {variant !== 'phone' && (
                    <Input
                        name={name}
                        css={placeholderStyle(variant)}
                        placeholder={placeholder}
                        onFocus={openDropdown}
                        onChange={onChange}
                        onFilterChange={onFilterChange}
                        value={valueLabel}
                        hasError={hasError}
                        hasIconOnError={false}
                        variant={variant}
                        autoComplete="nope"
                        innerRef={inputRef}
                        readOnly={disableSearch}
                    />
                )}
                {variant === 'phone' && (
                    <div
                        onClick={toggleDropdown}
                        css={placeholderStyle(variant)}
                    >
                        {!!phoneCode && countryToFlag(phoneCode)}
                    </div>
                )}
                {!!value && clearable && (
                    <span css={clearableStyle} title="Clear" aria-label="Clear" onClick={onClear}><CrossIcon css={crossIconStyle}/></span>
                )}
                <DropdownChevron dropped={state.active} css={dropdownChevronStyle(variant)} hasError={hasError} onClick={toggleDropdown}/>
                {variant === 'phone' && (
                    <div
                        css={phoneCodeStyle}
                        onClick={toggleDropdown}
                    >
                        {countryToPhoneCode(phoneCode)}
                    </div>
                )}
            </div>
            {state.active && (
                <SearchableListMenu
                    options={labelledOptions}
                    onOptionSelected={onOptionSelected}
                    onClose={closeDropdown}
                    value={value}
                    greyOutOptionFunc={greyOutOptionFunc}
                    searchString={state.search}
                    containsSearchBox={containsSearchBox}
                    onFilterChange={onFilterChange}
                    variant={variant}
                />
            )}
        </div>
    );
};

const containerStyle = (variant?: Variant) => css`
    position: relative;
    width: 100%;

    ${variant === 'phone' && css`
        position: absolute;
        top: 0;
        width: 70px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
    `}
`;

const clearableStyle = (theme: Theme) => css`
    display: block;
    position: absolute;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    z-index: 2;
    left: calc(100% - 56px);
    font-size: 12px;
    color: #AAAAAA;
    top: calc(50% - 12px);
    cursor: pointer;
    translateY(-50%);

    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
`;

const placeholderStyle = (variant?: Variant) => (theme: Theme) => css`
    font-size: ${theme.forms.label.fontSize};
    cursor: pointer;

    ${variant === 'phone' && css`
        min-height: 61px;
        padding-left: 9px;
        padding-top: 15px;
        font-size: 32px;
    `};
`;

const phoneCodeStyle = (theme: Theme) => css`
    position: absolute;
    left: 70px;
    height: 100%;
    padding-top: 23px;
    font-size: 16px;
    font-family: ${theme.fonts.frutiger};
`;

const crossIconStyle = (theme: Theme) => css`
    position: absolute;
    top: calc(50% - 6px);
    left: calc(50% - 6px);
    height: 10px;
    z-index: ${theme.zIndex.zIndexMedium};
    fill: black;
    opacity: 0.54;
`;

const dropdownChevronStyle = (variant?: Variant) => css`
    right: 15px;
    cursor: pointer;

    ${variant === 'phone' && css`
        right: 8px;
    `};
`;

export default memo(SearchableList);


