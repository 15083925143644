import React, { memo } from 'react';
import { Theme } from "../../../theme";

type Props = {
    children: React.ReactNode,
};

const DialogHeader: React.FC<Props> = ({ children }) => {
    return (
        <h2 css={styles}>{children}</h2>
    );
};

const styles = (theme: Theme) => ({
    fontSize: '20px',
    fontWeight: theme.fonts.weights.base,
    fontFamily: theme.fonts.frutiger,
    color: theme.colours.grey[900],
    marginBottom: "12px",
});

export default memo(DialogHeader);
