const BASE_PATH = 'manage';

const routes = {
    [`${BASE_PATH}`]: {
        link: `/${BASE_PATH}`
    },
    [`${BASE_PATH}/account-overview`]: {
        link: `/${BASE_PATH}/account-overview`
    },
    [`${BASE_PATH}/getting-started`]: {
        link: `/${BASE_PATH}/getting-started`
    },
    [`${BASE_PATH}/email-verification`]: {
        link: `/${BASE_PATH}/email-verification`
    },
    [`${BASE_PATH}/edit-name`]: {
        link: `/${BASE_PATH}/edit-name`
    },
    [`${BASE_PATH}/change-email`]: {
        link: `/${BASE_PATH}/change-email`
    },
    [`${BASE_PATH}/change-password`]: {
        link: `/${BASE_PATH}/change-password`
    },
    [`${BASE_PATH}/edit-billing-address`]: {
        link: `/${BASE_PATH}/edit-billing-address`
    },
    [`${BASE_PATH}/subscription-details`]: {
        link: `/${BASE_PATH}/subscription-details`
    },
    [`${BASE_PATH}/change-subscription-plan`]: {
        link: `/${BASE_PATH}/change-subscription-plan`
    },
    [`${BASE_PATH}/cancel-subscription`]: {
        link: `/${BASE_PATH}/cancel-subscription`
    },
    [`${BASE_PATH}/cancel-subscription-reason`]: {
        link: `/${BASE_PATH}/cancel-subscription-reason`
    },
    [`${BASE_PATH}/view-receipt`]: {
        link: `/${BASE_PATH}/view-receipt`
    },
    [`${BASE_PATH}/user-profiles`]: {
        link: `/${BASE_PATH}/user-profiles/:action?/:id?`
    },
    [`${BASE_PATH}/payment-billing`]: {
        link: `/${BASE_PATH}/payment-billing`
    },
    [`${BASE_PATH}/edit-payment-card`]: {
        link: `/${BASE_PATH}/edit-payment-card/:action?`
    },
    [`${BASE_PATH}/support`]: {
        link: `/${BASE_PATH}/support`
    },
    [`${BASE_PATH}/authenticate-payment`]: {
        link: `/${BASE_PATH}/authenticate-payment`
    },
    [`${BASE_PATH}/add-payment-details`]: {
        link: `/${BASE_PATH}/add-payment-details`
    },
};

export default routes;
