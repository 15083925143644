import React from "react";
import { css } from "@emotion/core";

import theme from "../../../theme";

type Props = {
    children: React.ReactNode,
};

const BurgerNavHeaderContainer: React.FC<Props> = ({
    children,
}) => {
    const style = css`
        background-color: ${theme.colours.white};
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default BurgerNavHeaderContainer;
