import React from "react";
import { css } from "@emotion/core";

import theme from "../../../../theme";

type Props = {
    position?: string,
};

const FooterDivideLine: React.FC<Props> = ({
    position = "",
}) => {
    const style = css`
        width: 100%;
        border-bottom: 1px solid ${theme.colours.alto};

        ${position === "top" && css`
            ${theme.breakpoints.up("xxl")} {
                margin: 35px 0;
            }

            ${theme.breakpoints.between("lg", "xl")} {
                margin: 27px 0;
            }
        `}

        ${position === "bottom" && css`
            ${theme.breakpoints.up("xxl")} {
                margin: 15px 0;
            }

            ${theme.breakpoints.between("lg", "xl")} {
                margin: 27px 0;
            }

            ${theme.breakpoints.down("md")} {
                border-bottom: 0;
            }
        `}
    `;

    return (
        <div css={style} />
    );
};

export default FooterDivideLine;
