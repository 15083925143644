/**
 * The URL segments of hiding breadcrumb
 *
 * @param currentURLSegment
 */
const isIncludeInBreadcrumbTargets = (currentURLSegment: string = "") => {
    // When target segments are set, the breadcrumb will be hidden for the set segment
    // const targetHideSegments = ["home", "auth/*"];
    const targetHideSegments: any = [];

    if (currentURLSegment) {
        const segments = currentURLSegment.split("/");
        return !targetHideSegments.find((segment: string) => segment.startsWith(segments[0]));
    }
    return false;
};

export {
    isIncludeInBreadcrumbTargets
}
