import React from "react";
import { css } from "@emotion/core";

import Image from "../../Image";
import theme from "../../../../theme";
import wtbLogo from "../../../../images/wtb-single-line-blue-v1.png";

type Props = {
    onMoveToHome: () => void,
};

const NavLogo:React.FC<Props> = ({ onMoveToHome }) => {
    const style = css`
        width: 200px;
        height: unset;

        ${theme.breakpoints.up("5xl")} {
            width: 280px;
        }

        ${theme.breakpoints.only('lg')} {
            position: absolute !important;
            // 28px: Hamburger icon size
            // 20px: Margin between icon and logo
            left: calc(${theme.sizes.menu.sideMargin}px + 28px + 20px);
        }

        ${theme.breakpoints.between("md", "lg")} {
            width: 235px;
        }

        ${theme.breakpoints.down("sm")} {
            width: 193px;
        }
    `;

    return (
        <Image image={wtbLogo} onClickHandler={onMoveToHome} css={style} />
    );
}

export default NavLogo;
