import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { Helmet } from "react-helmet";
import '@babel/polyfill';
import { Global, css } from '@emotion/core';
import { ThemeProvider } from 'emotion-theming';
import emotionReset from 'emotion-reset';
import theme, { Theme } from './theme';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { route } from './core/includes/routes';
import apolloClient from './core/includes/apollo';
import useSetState from "./core/hooks/useSetState";
import AppContext from "./core/contexts/AppContext";
import {StripePlan} from "./core/hooks/useStripePlans";
import ToastMessage from "./core/components/utils/ToastMessgae";
import { HomeUser } from "./core/components/utils/UserSession";
import ErrorBoundary from "./core/components/errors/ErrorBoundary";
import { getSiteTitle, MANAGE_URL } from "./config/config";
import MainSite from './pages/MainSite';
import LoginDialog from "./core/components/auth/LoginDialog";
import ScrollToTop from "./core/components/scroll-to-top/ScrollToTop";
import RedirectPage from "./pages/RedirectPage";
import { hasTokenExpired } from "./core/includes/auth";

const UserSession = lazy(() => import('./core/components/utils/UserSession'));
const Localisation = lazy(() => import('./core/components/utils/Localisation'));

const styles = (theme: Theme) => css`
    ${emotionReset};
    //restore sup and sub.
    sup, sub {
        font-size: smaller;
    }
    sup {
        vertical-align: super;
    }
    sub {
        vertical-align: sub;
    }

    ${theme.fontImports};
    ${theme.tippy.whiteTheme};

    html {
        font-size: ${theme.fonts.baseSize};
        line-height: ${theme.fonts.lineHeight};
        font-family: ${theme.fonts.primary};
        font-weight: ${theme.fonts.weights.light};
    }

    html,
    body,
    #root {
        width: 100%;
        height: 100%;
    }

    * {
        box-sizing: border-box;
    }

    .body-hide-content {
        @media (max-width: 1074.92px) {
            height: 100vh;
            overflow: hidden;
        }
    }
`;

type DEFAULT_STATE = {
    hasUserBeenLoaded: boolean,
    user: HomeUser|null,
    country: {
        defaultCode: string,
        code: string,
        codeFromIP: string,
        name: string,
    },
    currentURLSegment?: string,
    currentPageName?: string,
    plans: StripePlan[]|null,
};

const DEFAULT_APP_STATE = {
    hasUserBeenLoaded: false,
    user: null,
    country: {
        defaultCode: '',
        code: '',
        codeFromIP: '',
        name: '',
    },
    currentURLSegment: '',
    currentPageName: '',
    plans: null,
};

function App() {
    const appState: [any, any] = useSetState<DEFAULT_STATE>(DEFAULT_APP_STATE);
    const [loginDialogOpen, setLoginDialogOpen] = useState(false);

    const skippedDialog = useRef(false);

    useEffect(() => {
        const storageChange = (e: any) => {
            if (e.key === 'authToken' && e.oldValue && e.newValue === null) {
                window.location.reload();
            }
        }

        const openLoginDialog = (e: any) => {
            e.preventDefault();
            // Only show the login dialog if the cookie exists and it is expired.
            if (hasTokenExpired() && !skippedDialog.current) {
                setLoginDialogOpen(true);
            }
        }

        const checkForTokenExpire = () => {
            const shouldOpenLoginDialog = hasTokenExpired();
            if (shouldOpenLoginDialog) {
                setLoginDialogOpen(true);
            }
        }

        setInterval(checkForTokenExpire, 60000);

        window.addEventListener('storage', storageChange, false);
        window.addEventListener('authError', openLoginDialog, false);
        window.addEventListener('focus', checkForTokenExpire, false);

        return () => {
            window.removeEventListener('storage', storageChange, false);
            window.removeEventListener('authError', openLoginDialog, false);
            window.removeEventListener('focus', checkForTokenExpire, false);
        }
    });

    const onCloseLoginDialog = () => {
        setLoginDialogOpen(false);
        skippedDialog.current = true;
    };

    return (
        <ApolloProvider client={apolloClient}>
            <AppContext.Provider value={appState}>
                <ThemeProvider theme={theme}>
                    <ErrorBoundary>
                        <Suspense fallback={null}>
                            <UserSession />
                            <Localisation />
                        </Suspense>

                        <ToastMessage />
                        <Helmet>
                            <title>{getSiteTitle('')}</title>
                        </Helmet>

                        <BrowserRouter>
                            <Global styles={styles}/>
                            <ScrollToTop>
                                <Switch>
                                    <Route path={route('manage')}>
                                        <RedirectPage url={MANAGE_URL} />
                                    </Route>
                                    <Route path={route('')}>
                                        <MainSite />
                                    </Route>
                                </Switch>
                            </ScrollToTop>
                            {
                                loginDialogOpen && (
                                    <LoginDialog onClose={onCloseLoginDialog}/>
                                )
                            }
                        </BrowserRouter>
                    </ErrorBoundary>
                </ThemeProvider>
            </AppContext.Provider>
        </ApolloProvider>
    );
}

export default App;
