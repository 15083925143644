import React from 'react';
import { css } from '@emotion/core';
import { useHistory } from "react-router";
import { Helmet } from "react-helmet";

import laptopImg from "../../../images/laptop.jpg";
import { Theme } from "../../../theme";
import {getSiteTitle} from "../../../config/config";
import Button from "../../../core/components/buttons/Button";


const PaymentSuccess: React.FC = () => {
    const history = useHistory();
    const manage = () => {
        history.push('/manage/getting-started');
    };

    return (
        <div css={content}>
            <Helmet>
                <title>{getSiteTitle("Payment Successful")}</title>
                <meta
                    name="description"
                    content="Thanks for signing up for a free trial of Writer’s Toolbox! You’ve taken the first step to helping your child master the skills of great writing."
                />
            </Helmet>
            <h1 css={headerStyle}>Thanks for signing up for a free trial of Writer’s Toolbox!</h1>
            <div css={textStyle}>
                <p>
                    Congratulations!  Watch your child’s confidence and grades improve as they master sentence and paragraph design, essay writing, grammar, and more.
                </p>
                <p>
                    Click the button below to enter the world of Writer’s Toolbox.
                </p>
            </div>
            <Button type="button" onClick={manage} color={"secondary"}>Start now</Button>
            <div css={letTheJourneyBeginStyle}>Let the journey begin!</div>
            <img css={laptopImage} src={laptopImg} alt="laptop" />
        </div>
    );
};

const content = (theme: Theme) => css`
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: ${theme.fonts.frutiger};
    text-align: center;
`;

const headerStyle = (theme: Theme) => css`
    font-size: 30px;
    color: ${theme.colours.denim};
    line-height: 42px;
    font-weight: 600;
    margin-top: 10px;
`;

const textStyle = (theme: Theme) => css`
    font-size: 18px;
    color: ${theme.colours.denim};
    line-height: 24px;
    margin-top: 10px;
    margin-bottom: 12px;

    p {
        padding: 8px 0;
    }
`;

const letTheJourneyBeginStyle = (theme: Theme) => css`
    ${textStyle(theme)};
    margin-top: 20px;
`;

const laptopImage = (theme: Theme) => css`
    margin-top: 10px;
    width: 774px;
    height: 555px;

    ${theme.breakpoints.down('md')} {
        width: 100%;
        height: auto;
    }
`;

export default PaymentSuccess;
