const BASE_PATH = '/blog';

const routes = {
    'blog-base': {
        link: `${BASE_PATH}`,
    },
    'blog-page' : {
        route: `${BASE_PATH}/:category/page/:pageNumber`,
        link: `${BASE_PATH}/$category/page/$pageNumber`,
    },
    'blog-post': {
        route: `${BASE_PATH}/:category/:url`,
        link: `${BASE_PATH}/$category/$url`,
    }
};

export default routes;
