import { useCallback } from 'react';
import { toast } from "react-toastify";
import errorParser from "../includes/errorParser";
import { defaultToastOptions, errorToastOptions } from "../components/utils/ToastMessgae";

const useToast = () => {
    const success = useCallback((message) => {
        toast.success(message, defaultToastOptions);
    }, []);

    const error = useCallback((error) => {
        const message = errorParser(error);
        toast.error(message, errorToastOptions);
    }, []);

    const info = useCallback((message) => {
        toast.info(message, defaultToastOptions);
    }, []);

    return [success, error, info];
};

export default useToast;
