import React from "react";
import { css } from "@emotion/core";

import theme from "../../../../theme";
import Arrow from "../../icons/Arrow";

type Props = {
    size: number,
    showOptions?: boolean,
};

const DropdownChevron: React.FC<Props> = ({ size, showOptions = false }) => {
    const style = css`
        width: ${size}px;
        height: ${size}px;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
        ${showOptions && css`
            transform: rotate(180deg);
        `};
    `;

    const arrowColour = showOptions ? theme.colours.curiousBlue : "black";

    return (
        <Arrow css={style} colour={arrowColour} />
    );
};

export default DropdownChevron;
