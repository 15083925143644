import React from "react";
import { css } from "@emotion/core";

import theme from "../../../../theme";

type Props = {
    children: React.ReactNode,
};

const NavContainer: React.FC<Props> = ({
    children,
}) => {
    const style = css`
        position: fixed;
        width: 100%;
        z-index: ${theme.zIndex.zIndexHigh};

        ${theme.breakpoints.down("lg")} {
            display: none;
        }

        a {
            color: inherit;
            text-decoration: none;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
}

export default NavContainer;
