import React, { useMemo } from "react";
import { css } from "@emotion/core";

import BurgerNavSmallHeaderMenuBox from "./BurgerNavSmallHeaderMenuBox";
import BurgerNavSmallSubMenuBox from "./BurgerNavSmallSubMenuBox";
import Collapse from "../../Collapse";
import useBreakpoint from "../../../hooks/useBreakpoint";
import theme from "../../../../theme";

type Props = {
    headerMenu: StandardPage,
    selectedHeaderMenu?: StandardPage,
    currentURLSegment?: string,
    selectedSegments?: string[],
    getSubMenu: Function,
    setSubMenu: Function,
    closeMenuHandler: Function,
};

const BurgerNavSmallMenuBox: React.FC<Props> = ({
    headerMenu,
    selectedHeaderMenu = undefined,
    currentURLSegment = "",
    selectedSegments = undefined,
    getSubMenu,
    setSubMenu,
    closeMenuHandler,
}) => {
    const { minWidth } = useBreakpoint();
    const subMenu = getSubMenu(headerMenu); // Get submenu list
    const tabbedCurrentMenu = selectedHeaderMenu?.uRLSegment === headerMenu?.uRLSegment;

    const submenuBoxHeight = useMemo(() => {
        switch (true) {
            case minWidth >= theme.breakpoints.sizes["md"]:
                return 72;
            case minWidth >= theme.breakpoints.sizes["sm"]:
                return 75;
            default:
                return 70;
        }
    }, [minWidth]);

    const hasSubMenu = subMenu?.standardPage && (
        subMenu.standardPage.length > 1 || (subMenu.standardPage.length === 1 && subMenu.standardPage[0].showInMenus)
    );

    const submenuCountToBeShown = hasSubMenu ? subMenu?.standardPage?.filter((menu: StandardPage) => menu.showInMenus)?.length : 0;

    const submenuListBoxHeight = submenuCountToBeShown * submenuBoxHeight
        + (hasSubMenu && headerMenu.isClickable ? submenuBoxHeight : 0);

    const style = css`
        ${tabbedCurrentMenu && css`
            height: ${submenuListBoxHeight}px;
        `}
    `;

    return (
        <div>
            <BurgerNavSmallHeaderMenuBox
                headerMenu={headerMenu}
                setSubMenu={setSubMenu}
                hasSubMenu={hasSubMenu}
                tabbedMenu={tabbedCurrentMenu}
                currentURLSegment={currentURLSegment}
                closeMenuHandler={closeMenuHandler}
            />

            <Collapse isOpen={tabbedCurrentMenu} css={style}>
                <BurgerNavSmallSubMenuBox
                    menu={headerMenu}
                    currentURLSegment={currentURLSegment}
                    selectedSegments={selectedSegments}
                    closeMenuHandler={closeMenuHandler}
                    isHeaderMenu
                />

                {subMenu?.standardPage?.map((menu: StandardPage) => (
                    menu.showInMenus ? (
                        <BurgerNavSmallSubMenuBox
                            key={"burgerSmallSumMenuBox" + menu.id}
                            menu={menu}
                            currentURLSegment={currentURLSegment}
                            selectedSegments={selectedSegments}
                            closeMenuHandler={closeMenuHandler}
                        />
                    ) : null
                ))}
            </Collapse>
        </div>
    );
};

export default BurgerNavSmallMenuBox;
