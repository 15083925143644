import React, { ReactNode } from "react";
import { css } from "@emotion/core";

import theme from "../../../../theme";

type Props = {
    children: ReactNode,
};

const BurgerNavSmallCountryDropdownContainer: React.FC<Props> = ({ children }) => {
    // Only the breakpoint xs displays this component.
    const style = css`
        display: none;
        padding-left: ${theme.sizes.menu.sideMargin}px;
        height: ${theme.sizes.menu.breadcrumbBurgerSmallHeight}px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.16);

        ${theme.breakpoints.down("xs")} {
            display: flex;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default BurgerNavSmallCountryDropdownContainer;
