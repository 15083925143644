import React from 'react';
import { css } from '@emotion/core';
import theme, { Theme } from "../../../theme";

import Dialog from "../dialog/Dialog";
import DialogContent from "../dialog/DialogContent";
import WritersToolboxLogo from '../../../images/writers-toolbox-logo-grey-blue.png';
import WritersToolboxLogoSmallBreakpoints from '../../../images/writers-toolbox-blue-logo-two-lines.png';
import Auth from "./Auth";
import {EMAIL_VERIFICATION_URL, SOCIAL_CONFIRMATION_URL, SUCCESS_URL} from "../../includes/pricingAuthRedirects";

type Props = {
    onClose: () => void,
}

const LoginDialog: React.FC<Props> = ({ onClose }) => (
    <Dialog onClose={onClose} extraStyle={dialogStyles(theme)} showCloseButton={false}>
        <DialogContent>
            <img src={WritersToolboxLogo} alt={"Company Logo"} css={logoStyle}/>
            <img src={WritersToolboxLogoSmallBreakpoints} alt={"Company Logo"} css={logoStyleSmallBreakpoints}/>
            <div css={errorStyles}>Your session has expired</div>
            <div>
                <Auth
                    urls={{
                        socialConfirmationURL: SOCIAL_CONFIRMATION_URL,
                        emailVerificationURL: EMAIL_VERIFICATION_URL,
                        successURL: SUCCESS_URL,
                    }}
                    showSignUp={false}
                    onLoginDialogClose={onClose}
                    isLoginDialog
                />
            </div>
        </DialogContent>
    </Dialog>
);

const logoStyle = (theme: Theme) => css`
    height: 40px;

    ${theme.breakpoints.down('xs')} {
        display: none;
    }
`;

const logoStyleSmallBreakpoints = (theme: Theme) => css`
    height: auto;
    width: 100%;
    padding: 0 30px;
    margin: 0;

    ${theme.breakpoints.up('sm')} {
        display: none;
    }
`;

const dialogStyles = (theme: Theme) => css`
    min-width: 480px;
    z-index: ${theme.zIndex.zIndexHighest};
    max-height: 100%;

    ${theme.breakpoints.down('xs')} {
        min-width: 368px;
        max-height: 100%;
    }

    @media (max-width: 616px) {
        padding: 24px;
    }
`;

const errorStyles = (theme: Theme) => css`
    margin-top: 10px;
    font-size: 22px;
    font-weight: ${theme.fonts.weights.light};
    color: ${theme.colours.wellRead};

    ${theme.breakpoints.down('xs')} {
        margin-top: 9px;
        margin-left: 20px;
    }
`;

export default LoginDialog;
