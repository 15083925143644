import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Form from "../form/Form";
import LabelledField from "../form/LabelledField";
import Input from "../form/Input";
import FormActions from "../form/FormActions";
import Button from "../buttons/Button";
import useForm, { FieldList } from "../../hooks/useForm";
import { css } from "@emotion/core";

import { login, SocialLoginType } from "../../includes/auth";
import SocialLogin from "../social/SocialLogin";
import Loading from "../utils/Loading";
import { useAppState } from "../../contexts/AppContext";
import { Theme } from "../../../theme";
import { isEmpty } from "lodash";
import { AUTH_PASSWORD_RECOVERY } from "../../includes/pricingAuthRedirects";
import useElementDimensions from "../../hooks/useElementDimensions";
import { API_BASE_URL } from '../../../config/config';

type Props = {
    socialSignUp: (user: any, socialLoginType: SocialLoginType) => void,
    redirectAfterSignIn: (user: any) => void,
    errorMessage?: string,
    onLoginDialogClose?: () => void,
    isLoginDialog?: boolean,
};

const SignIn: React.FC<Props> = ({
    socialSignUp,
    redirectAfterSignIn,
    errorMessage,
    onLoginDialogClose,
    isLoginDialog,
}) => {
    const [fields, errors, onFieldChange,, validate] = useForm({
        fields: { email: '', password: '' },
        validator,
    });
    const [loginError, setLoginError] = useState({ isVisible: false, errorMessage: '' });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [appState, setAppState] = useAppState();
    const history: any = useHistory();
    const redirectTo = history.location.state?.redirectTo;
    const logInButtonRef = useRef<HTMLButtonElement>(null);
    const logInButtonDimensions = useElementDimensions(logInButtonRef.current);

    useEffect(() => {
        if (!isEmpty(errorMessage)) {
            setLoginError({ isVisible: true, errorMessage: errorMessage || '' });
        }
    }, [errorMessage]);

    const submitSignInForm = () => {
        setLoginError({ isVisible: false, errorMessage: '' });
        let closeDialog = true;
        if (validate()) {
            setIsSubmitting(true);
            login(fields.email, fields.password).then((data: {[key: string]: any}|null) => {
                const redirect: string = data?.redirect;

                if (redirect && !redirect.startsWith(API_BASE_URL)) {
                    appState.getUser().then((user: any) => {
                        redirectAfterSignIn(user);
                    });
                }

            }).then(() => {
                setAppState({ authorized: true });
                appState.refetchUser();
            }).catch((e: any) => {
                setIsSubmitting(false);
                setLoginError({ isVisible: true, errorMessage: e.message });
                closeDialog = false;
            }).finally(() => {
                if (closeDialog && !!onLoginDialogClose) {
                    onLoginDialogClose();
                }
            });
        }
    }

    const onClickClose = (e: any) => {
        e.preventDefault();
        onLoginDialogClose && onLoginDialogClose();

        appState.clearUser();
    }

    const pickASubscription = () => {
        history.push({
            pathname: '/subscriptions',
            state: { redirectTo: redirectTo }
        })
    }

    const clickForgotPassword = () => {
        history.push({
            pathname: AUTH_PASSWORD_RECOVERY,
            state: { redirectTo: redirectTo }
        })
    }

    return (
        <div css={authContent(isLoginDialog)}>
            {isSubmitting && <Loading onTop overlay overlayColour={"white"} />}
            <div css={leftHeader}>Log in</div>
            <div css={loginErrorSection(loginError.isVisible)}>
                <span>{loginError.errorMessage}</span>
            </div>
            <div css={formContainerStyle(isLoginDialog)}>
                <Form onSubmit={submitSignInForm}>
                    <LabelledField
                        name="email"
                        error={errors.email}
                        label="Email / Username"
                        version={isLoginDialog ? 'loginDialog' : 'normal'}
                    >
                        <Input
                            hasError={!!errors.email}
                            css={emailInput}
                            label="Enter email address or username"
                            name="email"
                            onChange={onFieldChange}
                            value={fields.email}
                            hasBorder
                        />
                    </LabelledField>
                    <LabelledField
                        name="password"
                        error={errors.password}
                        label="Password"
                        version={isLoginDialog ? 'loginDialog' : 'normal'}
                    >
                        <Input
                            hasError={!!errors.password}
                            css={passwordInput}
                            label="Password"
                            type="password"
                            name="password"
                            onChange={onFieldChange}
                            value={fields.password}
                            hasBorder
                        />
                    </LabelledField>
                    <div css={formActions(isLoginDialog)}>
                        {isLoginDialog ?
                            <div css={loginDialogButtonsStyle}>
                                <Button
                                    css={formSubmitButtonLoginDialogStyle}
                                    type="submit"
                                    forceLoading={isSubmitting}
                                >
                                    Log in
                                </Button>
                                <Button
                                    color={'secondary'}
                                    onClick={onClickClose}
                                    forceLoading={isSubmitting}
                                    css={formSubmitButtonLoginDialogStyle}
                                >
                                    Close
                                </Button>
                            </div>
                            :
                            <FormActions customCss={customFormActionsStyles}>
                                <Button
                                    css={formSubmitButton}
                                    type="submit"
                                    forceLoading={isSubmitting}
                                    ref={logInButtonRef}
                                >
                                    Log in
                                </Button>
                            </FormActions>
                        }
                    </div>
                    <div css={lineContainerStyle}>
                        <div css={lineStyle} />
                        <span css={lineTextStyle}>Or</span>
                        <div css={lineStyle} />
                    </div>
                    <SocialLogin socialSignUp={socialSignUp} buttonWidth={logInButtonDimensions.width} />
                    {!isLoginDialog &&
                        <div css={signInTextContainer}>
                            <div css={signInText}>
                                Don't have an account? Check out
                                our <div onClick={pickASubscription} css={signUpStyle}>subscriptions.</div>
                            </div>
                            <div css={signInText}>
                                Forgot your password?
                                Please <div onClick={clickForgotPassword} css={signUpStyle}>click here.</div>
                            </div>
                        </div>
                    }
                </Form>
            </div>
        </div>
    );
};

const lineContainerStyle = (theme: Theme) =>css`
    display: flex;

    ${theme.breakpoints.up("5xl")} {
        margin: 68px 0;
    }

    ${theme.breakpoints.between("xxl", "4xl")} {
        margin-bottom: 38px;
    }

    ${theme.breakpoints.between("lg", "xl")} {
        margin-bottom: 28px;
    }

    ${theme.breakpoints.only('md')} {
        margin-bottom: 21px;
    }

    ${theme.breakpoints.only("sm")} {
        margin-bottom: 25px;
    }

    ${theme.breakpoints.down("xs")} {
        margin-bottom: 18px;
    }
`;

const lineTextStyle = (theme: Theme) => css`
    padding: 0 28px;
    margin-top: -8px;
    color: ${theme.colours.silverChalice2};

    ${theme.breakpoints.up("5xl")} {
        font-size: 18px;
        margin-top: -10px;
    }

    ${theme.breakpoints.between("xxl", "4xl")} {
        font-size: 14px;
    }

    ${theme.breakpoints.down("xl")} {
        font-size: 11px;
        margin-top: -6px;
    }
`;

const lineStyle = (theme: Theme) => css`
    height: 1px;
    flex-grow: 1;
    background: ${theme.colours.silverChalice2};
`;

const validator = (fields: FieldList) => {
    const errors: any = {};

    if (!fields.email) {
        errors.email = 'Please provide a email';
    }

    if (!fields.password) {
        errors.password = 'Please provide a password';
    }

    return errors;
};

const authContent = (isLoginDialog?: boolean) => (theme: Theme) => css`
    margin-top: 40px;
    width: 510px;
    position: relative;

    ${theme.breakpoints.down('sm')} {
        width: 100%;
        max-width: 510px;
        margin: 40px auto 0;
    }

    ${theme.breakpoints.down('xs')} {
        padding: 0 20px;
    }

    ${isLoginDialog && css`
        ${theme.breakpoints.up('sm')} {
            width: 100%;
            margin-top: 20px;
        }

        ${theme.breakpoints.down('xs')} {
            margin-top: 14px;
        }
    `}
`;

const formContainerStyle = (isLoginDialog?: boolean) => (theme: Theme) => css`
        ${isLoginDialog && css`
            ${theme.breakpoints.down("xs")} {
                margin-top: 0;
            }
        `};

        ${theme.breakpoints.up("5xl")} {
            margin-top: 18px;
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            margin-top: 21px;
        }

        ${theme.breakpoints.between("lg", "xl")} {
            margin-top: 13px;
        }

        ${theme.breakpoints.only("md")} {
            margin-top: 16px;
        }

        ${theme.breakpoints.only("sm")} {
            margin-top: 8px;
        }

        ${theme.breakpoints.down("xs")} {
            margin-top: 6px;
        }
`;

const loginErrorSection = (isVisible: boolean) => (theme: Theme) => css`
    ${isVisible ? 'display: flex' : 'display: none'};
    width: 100%;
    background-color: ${theme.colours.chablis};
    border-radius: 3px;
    border: 1px solid ${theme.colours.crimson};
    color: ${theme.colours.red[999]};
    margin-top: 6px;
    margin-bottom: 20px;

    span {
        margin: 10px;
    }
`;

const emailInput = css`
    margin-top: 0;
    box-sizing: border-box;
    width: 100%;
`;

const passwordInput = css`
    margin-top: 0;
    box-sizing: border-box;
    width: 100%;
`;

const signInTextContainer = (theme: Theme) => css`
    ${theme.breakpoints.up("3xl")} {
        margin-top: 67px;

        > div {
            margin-bottom: 22px;
        }
    }

    ${theme.breakpoints.between("lg", "xxl")} {
        margin-top: 53px;
    }

    ${theme.breakpoints.between("sm", "xxl")} {
        > div {
            margin-bottom: 17px;
        }
    }

    ${theme.breakpoints.only("md")} {
        margin-top: 32px;
    }

    ${theme.breakpoints.only("sm")} {
        margin-top: 53px;

    }

    ${theme.breakpoints.down("xs")} {
        margin-top: 46px;

        > div {
            margin-bottom: 28px;
        }
    }
`;

const signInText = (theme: Theme) => css`
    font-size: 14px;
    font-weight: 100;
    text-align: center;

    a {
        text-decoration: none;
        color: ${theme.colours.blue['400']}
    }

    a:hover {
        text-decoration: underline;
    }

    ${theme.breakpoints.down('sm')} {
        width: 100%;
    }
`;

const formActions = (isLoginDialog?: boolean) => (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    ${theme.breakpoints.up('sm')} {
        margin-top: 20px;
        justify-content: center;
    }

    ${theme.breakpoints.up("5xl")} {
        margin: 63px 0 68px;
    }

    ${theme.breakpoints.between("xxl", "4xl")} {
        margin: 41px 0 45px;
    }

    ${theme.breakpoints.between("lg", "xl")} {
        margin: 29px 0 34px;
    }

    ${theme.breakpoints.only("md")} {
        margin: 21px 0 26px;
    }

    ${theme.breakpoints.only("sm")} {
        margin: 26px 0 31px;
    }

    ${theme.breakpoints.down("xs")} {
        margin: 19px 0 24px;
    }

    ${isLoginDialog && css`
        justify-content: center;

        ${theme.breakpoints.down('xs')} {
            margin-top: 6px;
        }
    `};
`;

const customFormActionsStyles = css`
    width: 100%;
    justify-content: center;
`;

const loginDialogButtonsStyle = (theme: Theme) => css`
    width: 100%;
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ${theme.breakpoints.down('xs')} {
        height: 89px;
        margin-top: 20px;
        flex-direction: column;
    }
`;

const formSubmitButton = (theme: Theme) => css`
    background-color: ${theme.colours.blue['400']};
    width: 400px;
    border-radius: 45px;
    height: 40px;
    padding: 0;

    ${theme.breakpoints.down('xs')} {
        width: 250px;
    }
`;

const formSubmitButtonLoginDialogStyle = (theme: Theme) => css`
    width: 49%;
    height: 42px;
    background-color: ${theme.colours.blue['400']};
    font-size: 20px;

    ${theme.breakpoints.down('xs')} {
        width: 100%;
    }
`;

const leftHeader = (theme: Theme) => css`
    color: ${theme.colours.curiousBlue};
    font-weight: ${theme.fonts.weights.bold};

    ${theme.breakpoints.up("xxl")} {
        font-size: 50px;
        line-height: 75px;
    }

    ${theme.breakpoints.between("md", "xl")} {
        font-size: 43px;
        line-height: 65px;
    }

    ${theme.breakpoints.down("sm")} {
        font-size: 30px;
        line-height: 45px;
    }
`;

const signUpStyle = (theme: Theme) => css`
    display: inline-block;
    color: ${theme.colours.curiousBlue};
    cursor: pointer;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

export default SignIn;
