/**
 * Number of seconds before expiry to refresh the token.
 *
 * @type {number}
 */
export const AUTH_REFRESH_LEEWAY = 60 * 5;

export const PLAN_PERIOD_MONTHLY = 'monthly';
export const PLAN_PERIOD_ANNUAL = 'annual';

export const FIRST_PAYMENT_STATUS_SUCCESS = "payment_success";
export const PAYMENT_INTENT_STATUS_SUCCEEDED = "succeeded";

export const PAYMENT_INTENT_STATUS_AUTHENTICATION_REQUIRED = "authentication_required";
export const PAYMENT_INTENT_STATUS_REQUIRES_CONFIRMATION = "requires_confirmation";
export const PAYMENT_INTENT_STATUS_REQUIRES_ACTION = "requires_action";
export const PAYMENT_INTENT_STATUS_REQUIRES_PAYMENT_METHOD = "requires_payment_method";

export const REQUIRES_ACTIONS = [
    PAYMENT_INTENT_STATUS_REQUIRES_ACTION,
    PAYMENT_INTENT_STATUS_REQUIRES_CONFIRMATION,
    PAYMENT_INTENT_STATUS_REQUIRES_PAYMENT_METHOD
];

export const CHARGE_ERROR_AUTHENTICATION_REQUIRED = "authentication_required";
export const CHARGE_ERROR_CARD_DECLINED = "card_declined";

export const PAYMENT_STATUS_COMPLETE = "complete";
export const PAYMENT_STATUS_INCOMPLETE = "incomplete";

export const CARD_ACTION_AUTHENTICATE_PAYMENT = "authenticate-payment";

export const MODAL_STAGE_CONFIRM = "confirm";
export const MODAL_STAGE_LOADING = "loading";
export const MODAL_STAGE_COMPLETE = "complete";

export const WRITING_STRENGTH_LEVELS = {
    low: {
        percentage: 20,
        label: 'Low'
    },
    average: {
        percentage: 50,
        label: 'Average'
    },
    strong: {
        percentage: 65,
        label: 'Strong'
    },
    excellent: {
        percentage: 80,
        label: 'Excellent'
    },
};
