import { useCallback, useEffect, useReducer, useState } from 'react';
import { isEmpty } from "lodash";

export type FieldList = {
    [key: string]: any,
};

export type OnFieldChange = (name: string, value: any, companionField?: string, fieldToClear?: string,) => void;

export type OnReset = () => void;

export type Validator = (fields: FieldList, isSubmit?: boolean) => {};

export type ValidateFunction = () => boolean;

export type SetFormErrorsFunction = (fields: FieldList) => void;

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'set_form_field':
            return {
                ...state,
                [action.payload.name]: action.payload.value
            };

        case 'set_form_fields':
            return {
                ...state,
                ...action.payload,
            }
    }
};

const useForm = ({
    fields,
    validator,
    extraErrorFields,
} : {
    fields: FieldList,
    validator?: Validator,
    extraErrorFields?: any,
}): [FieldList, FieldList, OnFieldChange, OnReset, ValidateFunction, SetFormErrorsFunction] => {
    const [formState, dispatch] = useReducer(reducer, fields || {});
    const [formErrors, setFormErrors] = useState<FieldList>({});
    const errorMessage = extraErrorFields?.errorMessage;

    const onReset = useCallback(() => {
        dispatch({
            type: 'set_form_fields',
            payload: fields
        });
    }, [fields]);

    /**
     * Validates all fields.
     *
     * @returns {boolean}
     */
    const validate = () => {
        if (validator) {
            const errors = validator(formState, true);
            if (Object.keys(errors).length) {
                setFormErrors(errors);
                scrollToError();
                return false;
            }

            // clear form errors
            setFormErrors({});
        }

        return true;
    };

    /**
     * Handle field change
     */
    const onFieldChange = useCallback((name: string, value: any, companionField?: string, fieldToClear?: string) => {
        // validate the field if validator provided
        if (validator) {
            const errors: FieldList = validator({[name]: value});

            // set the error
            if (errors && errors[name]) {
                setFormErrors({
                    ...formErrors,
                    [name]: errors[name],
                });

                // clear the error
            } else if (formErrors[name]) {
                const errors = {...formErrors};
                delete errors[name];

                if (!!companionField) {
                    delete errors[companionField];
                }

                setFormErrors({
                    ...errors,
                });
            }
        }

        if (fieldToClear) {
            dispatch({
                type: 'set_form_fields',
                payload: {
                    [name]: value,
                    [fieldToClear]: "",
                }
            });
        } else {
            dispatch({
                type: 'set_form_field',
                payload: {
                    name,
                    value: value,
                }
            });
        }
    }, [formErrors, validator]);

    const scrollToError = useCallback(() => {
        let id;

        if (!!extraErrorFields
            &&
            !isEmpty(extraErrorFields)
            &&
            Object.entries(extraErrorFields).find(([, value]) => value)
        ) {
            const activeErrorKeyInExtraErrorFields = Object.entries(extraErrorFields).find(([, value]) => value);
            id = activeErrorKeyInExtraErrorFields?.[0];
        } else if (!isEmpty(formErrors)) {
            id = Object.keys(formErrors)[0];
        } else {
            return;
        }

        if (id) {
            document.getElementById(id)?.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
            });
        }
    }, [formErrors, extraErrorFields]);

    useEffect(() => {
        if (errorMessage) {
            scrollToError();
        }
    }, [errorMessage, scrollToError]);

    return [formState, formErrors, onFieldChange, onReset, validate, setFormErrors];
};

export default useForm;
