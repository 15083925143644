import React from "react";

import NavMenuUnderLine from "../../header/corporate/NavMenuUnderLine";

type Props = {
    children: React.ReactNode,
};

const BurgerNavHeaderMenuTitle: React.FC<Props> = ({ children }) => (
    <div>
        {children}
        <NavMenuUnderLine className={"nav-menu-underline-tablet"} width={100} height={4} useDefaultStyle={false} />
    </div>
);

export default BurgerNavHeaderMenuTitle;
