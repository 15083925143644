import React, { memo } from 'react';
import { css, SerializedStyles } from '@emotion/core';
import { Theme } from "../../../theme";

const styles = {
    container: (version?: Version, name?: string) => (theme: Theme) => css`
        position: relative;
        margin-bottom: ${theme.forms.spacing.marginBottom};

        &:last-child {
            margin-bottom: 0;
        }

        ${version === 'loginDialog' && css`
            margin-bottom: 2px;

            ${name === "password" && css` margin-top: 22px; `};
        `}
    `,
    error: (extraErrorStyles?: SerializedStyles) => (theme: Theme) => css`
        position: absolute;
        top: 100%;
        font-size: 14px;
        color: ${theme.colours.crimson};
        white-space: nowrap;
        font-weight: ${theme.fonts.weights.base};

        ${extraErrorStyles};
    `,
    label: (theme: Theme) => css`
        ${theme.forms.label};
        font-weight: ${theme.fonts.weights.bold};
     `,

    asterisk: (errorAsterisk?: boolean, hasError?: string) => (theme: Theme) => css`
        display: ${errorAsterisk ? 'unset' : 'none'};
        color: ${hasError ? theme.colours.crimson : 'unset'};
    `,
};


type Version = 'normal' | 'loginDialog';


type Props = {
    children?: React.ReactNode,
    error?: string,
    label?: string,
    name: string,
    version?: Version,
    errorAsterisk?: boolean,
    extraErrorStyles?: SerializedStyles,
    className?: string,
    customLabel?: any,
};

const LabelledField: React.FC<Props> = ({ className, children, error, label, name, version = 'normal', errorAsterisk = false, extraErrorStyles, customLabel = ""}) => {
    return (
        <div className={className} css={styles.container(version, name)}>
            {!!label && (<label css={styles.label} htmlFor={name}>{label}<span css={styles.asterisk(errorAsterisk, error)}>*</span></label>)}
            {!!customLabel && (<label css={styles.label} htmlFor={name}><span dangerouslySetInnerHTML={{__html: customLabel}} /><span css={styles.asterisk(errorAsterisk, error)}>*</span></label>)}
            {children}
            {error && (
                <label css={styles.error(extraErrorStyles)} htmlFor={name}>{error}</label>
            )}
        </div>
    );
};

export default memo(LabelledField);
