import React, { useEffect, useRef, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

import stripeConfig from '../../../config/stripeConfig';
import { useAppState } from '../../contexts/AppContext';

type Props = {
    children?: React.ReactNode,
};

const StripeLoader:React.FC<Props> = ({ children }) => {
    const [stripePromise, setStripePromise] = useState();
    const [appState] = useAppState();
    const stripeKey = useRef('none');

    useEffect(
        () => {
            const countryCode = appState.user?.CountryCodeFromIp || appState.country.codeFromIP;

            if (countryCode) {
                stripeKey.current = countryCode.toUpperCase() === 'AU'
                    ? stripeConfig.publicKeyAU
                    : stripeConfig.publicKeyGlobal;

                setStripePromise(loadStripe(stripeKey.current));
            }
        },
        [appState, setStripePromise]
    );

    if (!stripePromise) {
        return <div />;
    }

    return (
        // key is necessary to ensure Stripe updates country on country change.
        <Elements key={stripeKey.current} stripe={stripePromise}>
            {children}
        </Elements>
    );
};

export default StripeLoader;
