import React from 'react';
import ContentContainer from "../../../core/components/content/ContentContainer";
import {css} from '@emotion/core';
import {Theme} from "../../../theme";
import {useTheme} from "emotion-theming";
import HeaderContentTitle from "../../../core/components/header/HeaderContentTitle";

const TermsAndConditionsContent: React.FC = () => {
    const theme: Theme = useTheme();

    return (
        <ContentContainer extraStyles={extraStyles(theme)} extraContainerStyles={extraContainerStyles}>
            <HeaderContentTitle
                colour={theme.colours.blue[400]}
                css={headerStyle}
                extraStyles={headerFontSizeStyle(theme)}
                hasBar
                title={`Terms & Conditions / Privacy Policy`}
            />
            <p><strong>IMPORTANT</strong>: To use this website, select either&nbsp;<strong>Chrome</strong>,&nbsp;
                <strong>Firefox</strong>,&nbsp;<strong>Safari</strong>,&nbsp;or&nbsp;<strong>Edge</strong>&nbsp;as your
                Internet browser.&nbsp;<br/>Like a number of organisations, we no longer recommend or support the use
                of&nbsp;<strong>Internet Explorer</strong>&nbsp;using interactive web applications like this
                tool.&nbsp;&nbsp;<strong>This browser will reduce functionality of this site</strong>.&nbsp;&nbsp;</p>
            <p>Thanks for using our website. The following are the terms and conditions of use of our website. Please
                read them carefully. Using our site indicates your agreement to these terms. If you do not agree to
                these terms, please exit the site and contact your school or educational institution to have your
                account removed, or contact us directly at support@wtbox.com.</p>
            <p>These Terms and Conditions of Use (the &ldquo;Terms and Conditions&rdquo;) govern your use of the
                Writer&rsquo;s Toolbox web site (<a href="http://www.wtbox.com">wtbox.com</a> and writerstoolbox.com)
                including without limitation use of all services, content, data, images, information and other materials
                posted on or available through this site (collectively, the &ldquo;site&rdquo;). &ldquo;Writer&rsquo;s
                Toolbox&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo; &ldquo;our&rdquo; are used synonymously in this
                document to refer to Writer&rsquo;s Toolbox and its licensors.</p>
            <h3>1. About Us</h3>
            <p>Writer&rsquo;s Toolbox is the online educational learning site owned and operated by&nbsp;Advanced
                Learning Limited&nbsp;(the &ldquo;licensor&rdquo;) a New Zealand limited company registered in New
                Zealand (company number 1131735), with offices at 395A Manukau Road, Epsom, Auckland 1023, New Zealand. Mailing
                address: PO Box 24687, Royal Oak, Auckland 1345.</p>
            <p>&nbsp;In New Zealand, the company markets itself under the brand Write that Essay and this brand is used
                in all its school-based writing programmes.&nbsp; In Australia, both Writer&rsquo;s Toolbox and the
                company&rsquo;s school writing improvement programmes are delivered by the wholly-owned subsidiary
                company Write that Essay Pty Ltd, an Australian company with the ABN 59 616 763 207.&nbsp; The company
                offices are at 14/481 Logan Road Greenslopes, Brisbane QLD 4120, telephone 07 38476278.</p>
            <p>The Site is protected by New Zealand and international copyright and trademark laws. You are licensed to
                use this site only if you accept all the terms and conditions set out below.</p>
            <h3>2. General Use</h3>
            <p>By purchasing a licence and/or using this site, your school or educational institution has agreed to be
                bound by the terms and conditions set forth below. Writer&rsquo;s Toolbox hereby grants you a
                nonexclusive license to use the site solely for your personal, educational, non-commercial use. As a
                condition of your use of this site, you warrant that you will not use the site for any purpose that is
                unlawful or prohibited by these Terms and Conditions.</p>
            <p>Your continued use of this site as a user will indicate your agreement to be bound by the terms and
                conditions set forth below. If you do not agree to these terms and conditions, promptly contact your
                school or educational institution and exit this site. Writer&rsquo;s Toolbox will remove your access
                privileges and discontinue your account.</p>
            <p>This site may not be used or accessed for: (a) Any use or activity which is illegal or prohibited by any
                applicable law (b) In any way that violates the rights of the licensor or otherwise exposes the licensor
                or its affiliates to liability (c) In any way that is prohibited by the terms and conditions herein. In
                whole or in part, you may not use information or material from the Writer&rsquo;s Toolbox website for
                any commercial purpose.</p>
            <p>You may not vary, delete or obscure any notices of proprietary rights, or any product identification or
                restrictions associated with the Writer&rsquo;s Toolbox product or its website, and must ensure that any
                intellectual property notices remain untouched or unaltered on the site</p>
            <h3>3. Intellectual Property</h3>
            <p>All information, materials, functions and other content contained on our site are our copyrighted
                property or the copyrighted property of our licensors or licensees. All such rights are reserved. All
                trademarks, service marks, and trade names are proprietary to us and/or our licensors. The contents of
                the Site may not be distributed, modified, reproduced, or used, in whole or in part, without the prior
                written consent of Writer&rsquo;s Toolbox, except as permitted by these Terms and Conditions, provided
                you keep intact all copyright, trademark, and other proprietary notices.</p>
            <h3>4. User Content</h3>
            <p>Teachers, students, and others use our site to produce their own content with the assistance of our
                website. The copyright in any original material you generate on the site is retained by you (the user).
                By using our site, you grant to us the right to publish, re-use, archive, modify and delete the contents
                that you upload as necessary to provide the services of the site. This right includes the ability to use
                anonymous versions of your work as exemplars within the site services. In all other ways the contributor
                remains the owner of all intellectual property rights to their contributions to the site.</p>
            <p>When we publish user content, such as examples of essays or other written work as examples of student
                work and/or exemplars we will take steps to preserve the anonymity of the user.</p>
            <h3>5. Back-ups of user Content</h3>
            <p>You (the user) should make regular back-ups of your content stored and used on our site. If you delete
                material on our site, or otherwise lose material, we may not be able to recover this information.
                Individual recovery requests will likely incur hourly charges.</p>
            <h3>6. Conclusion of Service</h3>
            <p>Upon finishing Writer&rsquo;s Toolbox, all users should remove content that they wish to keep.
                Writer&rsquo;s Toolbox does not offer an on-going retrieval service.</p>
            <h3>7. Transfer of licence</h3>
            <p>Access to the Writer&rsquo;s Toolbox website and its associated educational programmes is licensed to a
                school or educational institution, or individual user. You may not rent, lease, sub-licence, sell or
                assign, pledge, transfer or otherwise dispose of such rights of access, or the information thereby
                downloaded without the prior written consent of the Licensor.</p>
            <h3>8. Our Liability</h3>
            <p>While we will make every effort to ensure that the educational material accessed on the Writer&rsquo;s
                Toolbox website is appropriate, relevant and accurate, we do not accept any liability for loss or damage
                arising from your access to the Writer&rsquo;s Toolbox website, including loss of content users generate
                or any omissions from the website content.</p>
            <p>Content of the Writer&rsquo;s Toolbox website is provided "as is" without warranties of any kind, express
                or implied, including warranties as to effectiveness or accuracy of the educational material accessed
                therein. While we will make every effort to ensure that the Writer&rsquo;s Toolbox website is maintained
                in working order, there may be times when it is temporarily closed for maintenance or is closed for
                reasons beyond our control; and if that happens we will make every effort to have the website up and
                running again as soon as practicably possible, but accept no liability or responsibility for the period
                the website is down.</p>
            <h3>9. Viruses, Hacking, etc.</h3>
            <p>As a user you undertake to maintain updated anti-virus software and scan all internet downloads to be
                installed on the computer equipment on which our website is to be accessed. You may not reverse
                engineer, deconstruct, disassemble or decompile any software or technology underlying the Site or
                provided through the Site. If you breach any of these Terms and Conditions, your authorization to use
                this Site shall automatically terminate. Intentional misuse or abuse the Writer&rsquo;s Toolbox website
                by any means, including without limitation using obscene language, hacking or cyber bullying,
                Writer&rsquo;s Toolbox reserves the right to revoke access to the Site immediately.</p>
            <h3>10. Term</h3>
            <p>The licence period is for an academic year, unless otherwise arranged or terminated for some other
                reason. You may terminate it at any time by written notice. Apart from our obligations under relevant
                New Zealand legislation, we do not provide a refund for licence terminations. We have the right to
                terminate access to this site without notice if you fail to comply with any term or condition of this
                agreement. These general conditions will nevertheless survive any such termination.</p>
            <h3>11. Governing Law</h3>
            <p>The terms of this licence agreement are governed by New Zealand law. You hereby agree to submit to the
                non-exclusive jurisdiction of the Courts of New Zealand. Any dispute or claim shall be subject to the
                non-exclusive jurisdiction of the courts of New Zealand although we retain the right to bring
                proceedings against you for breach of these conditions in your country of residence or any other
                relevant country.</p>
            <h3>12. Variations</h3>
            <p>We may revise these terms of use at any time by amending this page. You are expected to check this page
                from time to time to take notice of any changes we made, as they are binding on you.</p>
            <h3>Writer&rsquo;s Toolbox Privacy Policy</h3>
            <p>Data Privacy and Use for UK and European Customers</p>
            <p>Writer&rsquo;s Toolbox policies and practices comply with the GDPR requirements regarding personal
                data. &nbsp;Personal data can only be transferred out of the EU if:</p>
            <table cellSpacing="0" cellPadding="0">
                <tbody>
                <tr>
                    <td css={tdNumberStyle}>
                        <p>i</p>
                    </td>
                    <td>
                        <p>The data is transferred to a country that has been determined by the European Commission to
                            have an adequate level of protection (commonly referred to as having &ldquo;adequacy
                            status&ldquo;).</p>
                    </td>
                </tr>
                </tbody>
            </table>
            <p>New Zealand is one of a small number of non-EU countries that enjoys adequacy status so the limited
                personal data required to operate and use Writer&rsquo;s Toolbox can be transferred to New Zealand.</p>
            <p>This website is operated:</p>
            <table css={tableStyle}>
                <tbody>
                <tr >
                    <td css={tdNumberStyle}>
                        <p>a</p>
                    </td>
                    <td>
                        <span>In New Zealand, by Advanced Learning Limited a New Zealand registered company; and</span>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <p>b</p>
                    </td>
                    <td>
                        <span>In Australia, by Write that Essay PTY Limited an Australian registered proprietary
                            company.</span>
                    </td>
                </tr>
                </tbody>
            </table>
            <p><strong>Writer&rsquo;s Toolbox</strong>,&nbsp;<strong>we</strong>,&nbsp;<strong>us</strong>, or&nbsp;
                <strong>our</strong>, are used in this privacy policy to refer to those entities.</p>
            <p>Writer&rsquo;s Toolbox provides this online web based platform to assist students in developing writing
                skills through an academic writing program.&nbsp; Writer&rsquo;s Toolbox is concerned for the safety,
                security and privacy of our users.&nbsp; We actively protect our users&rsquo; privacy, particularly since many
                of our users are children and young adults.<br/>In this policy we explain how we collect, use and
                disclose your personal information.&nbsp; By using this site you confirm that you accept the terms of
                this policy.&nbsp;</p>
            <table css={tableStyle}>
                <tbody>
                <tr>
                    <td css={tdNumberStyle}>
                        <h3>1</h3>
                    </td>
                    <td>
                        <h3>Collection of your Information</h3>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <p>1.1</p>
                    </td>
                    <td css={tdNumberStyle}>
                        <span>We will only ask you for personal information required for the effective functioning of the
                            Writer&rsquo;s Toolbox platform and your use of it.</span>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <p>1.2</p>
                    </td>
                    <td css={tdNumberStyle}>
                        <span>In collecting, holding, using, disclosing and otherwise managing your personal information,
                            Writer&rsquo;s Toolbox will, amongst other requirements, comply with relevant privacy
                            legislation and privacy principles.</span>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <p>1.3</p>
                    </td>
                    <td css={tdNumberStyle}>
                        <span>Use of the Writer&rsquo;s Toolbox platform or our other products or services by students may
                            require us to collect personal information from the student.</span>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <p>1.4</p>
                    </td>
                    <td css={tdNumberStyle}>
                        <span>If a student is aged under 13 years, we will only collect personal information from that
                            student where the student&rsquo;s school has undertaken to us to obtain a consent from a parent or
                            guardian of the student for that student to use Writer&rsquo;s Toolbox or our other products
                            and services and disclose personal information to us.</span>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <p>1.5</p>
                    </td>
                    <td css={tdNumberStyle}>
                        <span>The student&rsquo;s school should only provide class passwords (which enable students to access
                            Writer&rsquo;s Toolbox) to students aged under 13 years where such a consent has been
                            obtained in relation to that student.</span>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <p>1.6</p>
                    </td>
                    <td css={tdNumberStyle}>
                        <span>If you are a student aged under 13 years, please do not provide personal information to us or
                            otherwise use Writer&rsquo;s Toolbox or our other products or services unless such a consent
                            has been provided by your parent or guardian. If you are unsure, speak with your parent or
                            guardian.</span>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <h3>2</h3>
                    </td>
                    <td css={tdNumberStyle}>
                        <h3>Information we collect</h3>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <p>2.1</p>
                    </td>
                    <td css={tdNumberStyle}>
                        <span>We collect personal information about you from various sources, including:</span>
                        <ol>
                            <li>Information your school supplies to us;</li>
                            <li>Information you supply to us; and</li>
                            <li>Information gathered through your use of the site, such as the content you
                                access.&nbsp;&nbsp;</li>
                        </ol>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <p>2.2</p>
                    </td>
                    <td css={tdNumberStyle}>
                        <span>We require the following information to create student and/or teacher accounts:</span>
                        <ol>
                            <li>Name;</li>
                            <li>A unique identifier such as a school ID number (so that student profiles can be carried
                                over from year to year);
                            </li>
                            <li>A unique email address; and</li>
                            <li>Classes and year that a student is enrolled in</li>
                            <li>Gender and ethnicity information that your school uses in its reporting.</li>
                        </ol>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <h3>3</h3>
                    </td>
                    <td css={tdNumberStyle}>
                        <h3>Use of your Information</h3>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <p>3.1</p>
                    </td>
                    <td css={tdNumberStyle}>
                        <span>We use personal information we collect about you to:</span>
                        <ol>
                            <li>Create user accounts;</li>
                            <li>Provide services to you;</li>
                            <li>Reset a user&rsquo;s password, if required;</li>
                            <li>Gather and respond to feedback and reports of technical problems;</li>
                            <li>Enforce or apply our terms and conditions;</li>
                            <li>Protect our rights or the rights of others; and</li>
                            <li>Comply with laws.</li>
                        </ol>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <p>3.2</p>
                    </td>
                    <td css={tdNumberStyle}>
                        <span>We also collect statistics about how you use our site for the purposes of making improvements
                            to the site and our services, and for educational research and marketing.&nbsp; For example,
                            we may record which learning modules are more popular, or the aggregate scores of students
                            undertaking the learning modules.&nbsp; This information will only be used as aggregate
                            (grouped) data and will not contain personally-identifying information.</span>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <h3>4</h3>
                    </td>
                    <td>
                        <h3>Storage of your Information</h3>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <p>4.1</p>
                    </td>
                    <td css={tdNumberStyle}>
                        <span>The security of your personal information is important to us.&nbsp; We store your personal
                            information electronically on our secure servers in Sydney hosted by Amazon.&nbsp; These are
                            full service backups which give us the ability to quickly restore the service in case of an
                            emergency.</span>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <p>4.2</p>
                    </td>
                    <td css={tdNumberStyle}>
                        <span>We follow generally accepted industry standards to protect the personal information submitted
                            to us.&nbsp; However, no data storage or transfer mechanism is 100% secure so we cannot
                            absolutely guarantee the security of your information.&nbsp; It is your responsibility to
                            ensure that your access details (username and password) are kept private and to inform us if
                            you believe that the security of your account has been breached.</span>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <p>4.3</p>
                    </td>
                    <td css={tdNumberStyle}>
                        <span>We may retain your personal information for up to three years after you cease using our
                            services, unless you request that your personal information be deleted, and the erasure is
                            in accordance with relevant laws (including the GDPR).</span>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <h3>5</h3>
                    </td>
                    <td css={tdNumberStyle}>
                        <h3>Disclosure of your Information</h3>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <p>5.1</p>
                    </td>
                    <td css={tdNumberStyle}>
                        <span>Our goal is to provide a learning environment that is every bit as safe and secure as being
                            at school.&nbsp; A student&rsquo;s full name, learning module scores, and written material are
                            only visible to their teachers.&nbsp; Teachers can only access the information and results
                            of students attending their school.</span>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <p>5.2</p>
                    </td>
                    <td css={tdNumberStyle}>
                        <span>We will not disclose your personal information to third parties except in the following
                            circumstances:</span>
                        <ol>
                            <li>Where disclosure is necessary to undertake any of the purposes referred to in clause
                                3;
                            </li>
                            <li>If we are required to provide your personal information in relation to any legal
                                proceedings;
                            </li>
                            <li>Where disclosure is required by law.</li>
                        </ol>
                    </td>
                </tr>
                <tr>
                    <td css={tdNumberStyle}>
                        <p>5.3</p>
                    </td>
                    <td>
                        <span>We will not sell or rent your personal information to third parties.</span>
                    </td>
                </tr>
                <tr css={specialRowStyle}>
                    <td css={tdNumberStyle}>
                        <h3>6</h3>
                    </td>
                    <td>
                        <h3>Notification of privacy breach</h3>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <p>6.1</p>
                    </td>
                    <td css={tdNumberStyle}>
                        <span>In the event of a privacy breach, we have established procedures for notifying the New
                            Zealand Privacy Commissioner, relevant EU Supervisory Authority and the individual affected
                            (where necessary), including setting out the reasonable steps we have taken to mitigate the
                            effects of a privacy breach, should we be required to do so under law.</span>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <h3>7</h3>
                    </td>
                    <td>
                        <h3>Use of Cookies</h3>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <p>7.1</p>
                    </td>
                    <td css={tdNumberStyle}>
                        <span>Our site uses cookies to distinguish you from other users of our site.&nbsp; Cookies allow us
                            to identify visitors across different pages on our site.&nbsp; This helps us to provide you
                            with a personalised experience when you browse our site and also allows us to make
                            improvements.&nbsp; We do not use third-party cookies to track your Internet browsing beyond
                            our site.</span>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <p>7.2</p>
                    </td>
                    <td css={tdNumberStyle}>
                        <span>If you do not wish to accept cookies you can set your web browser to refuse cookies before
                            using our site.&nbsp; If you choose not to accept cookies you will still be able to access
                            our site but some of the features on our site may not be available to you or may not
                            function properly without the aid of cookies.</span>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <h3>8</h3>
                    </td>
                    <td css={tdNumberStyle}>
                        <h3>Accessing your Information</h3>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <p>8.1</p>
                    </td>
                    <td css={tdNumberStyle}>
                        <span>You have the right to access and correct personal information held by us.&nbsp; You can
                            manage your personal information on the site by using your MY ACCOUNT tab.</span>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <p>8.2</p>
                    </td>
                    <td css={tdNumberStyle}>
                        <p>If you have any queries about your personal information held by us, or if you would like to
                            make a privacy complaint, you can contact us at:</p>
                        <p>Writer&rsquo;s Toolbox<br/>Postal address: PO Box 24687, Royal Oak, Auckland 1345, New
                            Zealand<br/>Physical address: 395A Manukau Road, Epsom, Auckland 1023, New Zealand<br/>Phone:
                            (+64) 9 624 6886<br/>Email: <a css={linkStyle} href="mailto:support@wtbox.com">support@wtbox.com</a></p>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <h3>9</h3>
                    </td>
                    <td css={tdNumberStyle}>
                        <h3>Variations to this Policy</h3>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <p>9.1</p>
                    </td>
                    <td css={tdNumberStyle}>
                        <span>We may vary this privacy policy from time to time.&nbsp; We will provide notice of the change
                            by uploading the updated privacy policy on our site and we will also email you a copy of the
                            updated privacy policy (if you have provided us with your email address).&nbsp; After
                            providing that notice you will be deemed to have accepted the updated privacy policy if you
                            continue to use our site or the services provided by us.</span>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <h3>10</h3>
                    </td>
                    <td css={tdNumberStyle}>
                        <h3>Further Information</h3>
                    </td>
                </tr>
                <tr >
                    <td css={tdNumberStyle}>
                        <p>10.1</p>
                    </td>
                    <td css={tdNumberStyle}>
                        <p>For more information about your privacy rights see:</p>
                        <p>New Zealand:&nbsp;<a css={linkStyle} href="http://www.privacy.org.nz/">www.privacy.org.nz</a></p>
                        <p>Australia:&nbsp;<a css={linkStyle} href="http://www.oaic.gov.au/">www.oaic.gov.au</a></p>
                        <p>GDPR: <a css={linkStyle} href="https://gdpr.eu/">https://gdpr.eu/</a></p>
                    </td>
                </tr>
                </tbody>
            </table>
        </ContentContainer>
    );
};

const extraContainerStyles = css`
    height: 100%!important;
`;

const extraStyles = (theme: Theme) => css`
    padding: 0 60px;

    p,
    td {
        margin-bottom: 24px;
        color: ${theme.colours.grey[650]};
        font-size: 18px;
        line-height: 28px;

        ${theme.breakpoints.down('md')} {
            font-size: 16px;
            line-height: 26px;
        }
    }

    h3 {
        margin-bottom: 24px;
        font-size: 24px;
        color: ${theme.colours.blue[400]};

        ${theme.breakpoints.down('md')} {
            font-size: 21px;
        }
    }
`;

const headerStyle = css`
    margin: 58px 0 16px;
`;

const headerFontSizeStyle = (theme: Theme) => css`

    ${theme.breakpoints.down('md')} {
        font-size: 39px;
    }

    ${theme.breakpoints.down('sm')} {
        font-size: 34px;
    }

    ${theme.breakpoints.down('xs')} {
        font-size: 23px;
    }

    ${theme.breakpoints.down('xxs')} {
        font-size: 20px;
    }
`;

const tableStyle = css`
    width: 100%;
    table-layout: fixed;
    margin-bottom: 24px;

    td,
    tr {
        border: 0;
        vertical-align: top;
    }

    td {
        h3 {
            margin: 24px 0;
        }
    }
`;

const tdNumberStyle = (theme: Theme) => css`
    width: 8%;

    ${theme.breakpoints.down('xs')} {
        width: 15%;
    }
`;

const linkStyle = css`
    text-decoration: underline;
    color: #0000ee;
`;

const specialRowStyle = css`
    margin-bottom: 0;

    td {
        h3 {
            margin-top: 0;
        }
    }
`;

export default TermsAndConditionsContent;
