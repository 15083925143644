import React, {useContext} from 'react';

const AppContext = React.createContext<any>([]);

export const useAppState = () => {
    const [state, setState] = useContext(AppContext);
    return [state, setState];
};

export default AppContext;
