import React from 'react';
import { css } from '@emotion/core';

import { StripePlan } from "../../../core/hooks/useStripePlans";
import { Theme } from "../../../theme";
import { getPriceByInterval } from "../../../core/includes/stripePlansHelpers";

type Props = {
    isSelected: boolean,
    numberOfUsers: string,
    onPlanSelect: (plan: StripePlan) => void,
    plan: StripePlan,
};

const PlanSelectionItem: React.FC<Props> = ({
    isSelected,
    numberOfUsers,
    onPlanSelect,
    plan
}) => {
    const price = getPriceByInterval(plan, numberOfUsers);
    const numberOfMonths = 12 * parseInt(numberOfUsers);
    const totalPrice = (price * numberOfMonths);

    return (
        <div css={containerStyle(isSelected)}  onClick={() => onPlanSelect(plan)}>
            <div css={intervalStyle}>
                {plan.Interval === 'month' ? 'monthly' : 'annual'}
            </div>
            <div css={priceStyle(isSelected)}>
                ${price.toFixed(2)}
            </div>
            <div css={textStyle(isSelected)}>
                /mo/user
            </div>
            <div css={billingStyle}>
                {getBillingWording(plan.Interval, totalPrice)}
            </div>
        </div>
    )
};

const getBillingWording = (interval: string, totalPrice: number) => {
    if (interval === 'month') {
        return 'Billed monthly';
    }

    if (interval === 'year') {
        return `$${totalPrice.toFixed(2)} billed annually`;
    }
};

const containerStyle = (isSelected: boolean) => (theme: Theme) => css`
    margin-top: 40px;
    width: 240px;
    height: 185px;
    border: 1px solid ${theme.colours.grey['600']};
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: content-box;

    ${ isSelected && css`
        background-color: ${theme.colours.blue['50']};
        border: 3px solid ${theme.colours.blue['600']};
        width: 236px;
        height: 181px;
    ` }

    &:hover {
        background-color: ${theme.colours.blue['50']};
    }

    ${theme.breakpoints.down('sm')} {
        width: 45%;
        max-width: 240px;
    }
`;

const intervalStyle = css`
    font-size: 16px;
    height: 26px;
`;

const priceStyle = (isSelected: boolean) => (theme: Theme) => css`
    height: 40px;
    font-size: 32px;
    font-weight: 400;

    ${ isSelected && css`
        color: ${theme.colours.blue['600']};
        font-weight: 600;
    ` }

    ${theme.breakpoints.down('xs')} {
        font-size: 26px;
    }
`;

const textStyle = (isSelected: boolean) => (theme: Theme) => css`
    font-size: 16px;
    font-weight: 400;
    color: ${theme.colours.grey['650']};
    height: 34px;

    ${ isSelected && css`
        color: ${theme.colours.blue['600']};
        font-weight: 600;
    ` }
`;

const billingStyle = (theme: Theme) => css`
    font-size: 12px;
    font-style: italic;

    ${theme.breakpoints.down('xs')} {
        padding: 0 20px;
        text-align: center;
        height: 24px;
    }
`;

export default PlanSelectionItem;
