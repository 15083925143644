import React from 'react';
import { css } from '@emotion/core';
import { Link } from "react-router-dom";

import { Theme } from '../../../theme';
import { route } from "../../../core/includes/routes";
import Button from "../../../core/components/buttons/Button";
import { StripePlan } from "../../../core/hooks/useStripePlans";

type Props = {
    plan: StripePlan | null,
    isSubmitting: boolean,
    onClick: () => void,
};

const PlanSelectionFooter: React.FC<Props> = ({ plan, isSubmitting, onClick }) => (
    <div css={containerStyle}>
        <div css={planQuestionsStyle}>
            Questions? <Link to={route('contact-us')}>Contact Sales.</Link>
        </div>
        <div css={buttonStyle}>
            <Button
                color={"secondary"}
                disabled={!plan}
                disabledTooltipMessage={"Please select a plan"}
                forceLoading={isSubmitting}
                onClick={onClick}
                size="lg"
                type="submit"
            >
                Continue
            </Button>
        </div>
    </div>
);

const containerStyle = css`
    width: 100%;
    margin-top: 20px;
`;

const planQuestionsStyle = (theme: Theme) => css`
    font-size: 14px;
    height: 17px;
    line-height: 17px;
    white-space: pre;
    color: ${theme.colours.grey[650]};

    a {
        text-decoration: none;
        color: ${theme.colours.blue['400']};

        &:hover {
            text-decoration: underline;
        }
    }
`;

const buttonStyle = (theme: Theme) => css`
    text-align: right;
    margin-top: 22px;
    height: 32px;
    display: block;

    ${theme.breakpoints.down('md')} {
        display: none;
    }
`;

export default PlanSelectionFooter;
