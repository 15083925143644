import React from "react";
import { css } from "@emotion/core";

import theme from "../../../../theme";
import FooterTopMenu from "./FooterTopMenu";

type Props = {
    footerTopMenu: [any] | undefined,
};

const FooterTopMenuBox: React.FC<Props> = ({ footerTopMenu }) => {
    const style = css`
        display: flex;
        margin-right: 37px;
        color: ${theme.colours.doveGray};
        font-weight: ${theme.fonts.weights.normal};
        cursor: pointer;

        .footer--top_link_wrapper {
            &:not(:first-of-type) {
                margin-left: 50px;

                ${theme.breakpoints.only("sm")} {
                    margin-left: 32px;
                }

                ${theme.breakpoints.down("xs")} {
                    margin-left: 42px;
                }
            }
        }

        ${theme.breakpoints.down("md")} {
            margin-right: unset;
        }
    `;

    return (
        <div css={style}>
            {footerTopMenu?.map((menu: StandardPage) => (
                <FooterTopMenu key={"footerTopMenu" + menu.id} className={"footer--top_link_wrapper"} data={menu} />
            ))}
        </div>
    );
};

export default FooterTopMenuBox;
