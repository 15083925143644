import React, {useCallback, useEffect, useRef, useState} from 'react'
import { useScript } from "../../hooks/useScript";
import { NOOP } from "../../../config/constants";
import { css } from "@emotion/core";
import { Theme } from "@material-ui/core";
import { GOOGLE_APP_ID } from "../../../config/config";
import useElementDimensions from "../../hooks/useElementDimensions";
import useBreakpoint from "../../hooks/useBreakpoint";

type Props = {
    handleLoginFailure: (error: any) => void,
    triggerLogin: (socialUser: any) => void,
    buttonWidth: number,
    isSignUp: boolean,
}

const SocialButton: React.FC<Props> = ({ isSignUp = false, triggerLogin = NOOP,  handleLoginFailure = NOOP, buttonWidth }) => {
    const [hasChanged, setHasChanged] = useState(false);
    const buttonRef = useRef<HTMLDivElement>(null);
    const buttonDimensions = useElementDimensions(buttonRef.current);
    const { breakpoint } = useBreakpoint()

    useEffect(() => {
        if (buttonWidth !== buttonDimensions.width) {
            setHasChanged(true);
        } else if (hasChanged) {
            setHasChanged(false);
        }
    }, [buttonWidth, buttonDimensions.width, setHasChanged, hasChanged]);

    useScript("https://accounts.google.com/gsi/client", () => {
        window.google.accounts.id.initialize({
            client_id: GOOGLE_APP_ID,
            callback: handleGoogleLogin,
        });
        window.google.accounts.id.renderButton(buttonRef.current, {
            width: `${buttonWidth}px`,
            type: "text",
            shape: "circle",
            text: `${isSignUp ? 'signup_with' : 'continue_with'}`,
            size: "large",
            onsuccess: triggerLogin,
            onfailure: handleLoginFailure
        });
    }, hasChanged && breakpoint);

    const handleGoogleLogin = useCallback((socialUser: any) => {
        try {
            triggerLogin(socialUser);
        } catch (e) {
            console.log(`e-->`, e);
            handleLoginFailure(e);
        }

    }, [triggerLogin, handleLoginFailure]);

    return (
        <div css={socialLoginBlock}>
            <div
                id={GOOGLE_APP_ID!}
                ref={buttonRef}
            />
        </div>
    );
}

export default SocialButton;

const socialLoginBlock = (theme:Theme) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    > div {
        margin-bottom: 21px;
    }

    ${theme.breakpoints.only("md")} {
        margin-top: 17px;
    }

    ${theme.breakpoints.down("xs")} {
        > div {
            margin-bottom: 20px;
        }
    }
`;
