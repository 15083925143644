const routes = {
    home: {
        link: '/',
    },
    auth: {
        link: '/auth',
    },
    'for-home' : {
        link: '/for-home',
    },
    'for-school': {
        link: '/for-school',
    },
    about: {
        link: '/about',
    },
    faqs: {
        link: '/faqs',
    },
    subscriptions: {
        link: '/subscriptions',
    },
    'contact-us': {
        link: '/get-in-touch',
    },
    'features': {
        link: '/features',
    },
    'terms-and-conditions': {
        link: '/terms-and-conditions'
    }
};

export default routes;
