import React from "react";
import { css } from "@emotion/core";

import theme from "../../../../theme";

type Props = {
    children: React.ReactNode,
    headerMenu: StandardPage,
    selectedSegments?: string[],
    mouseClickHandler: Function,
};

const NavSubTextBox: React.FC<Props> = ({
    children,
    headerMenu,
    selectedSegments = undefined,
    mouseClickHandler,
}) => {
    const onClickHandler = () => {
        mouseClickHandler();
    };

    const style = css`
        display: flex;
        justify-content: start;
        align-items: center;
        height: 23px;
        white-space: nowrap;
        cursor: pointer;
        border-bottom: 2px solid transparent;

        ${theme.breakpoints.down("4xl")} {
            font-size: 12px;
        }

        // Selected menu
        ${selectedSegments?.includes(headerMenu.uRLSegment) && css`
            border-bottom: 2px solid ${theme.colours.curiousBlue};
        `};

        &:hover {
            color: ${theme.colours.curiousBlue};
            border-bottom: 2px solid ${selectedSegments?.includes(headerMenu.uRLSegment) ? theme.colours.curiousBlue : "transparent"};
        }

        &:active {
            border-bottom: 2px solid ${theme.colours.curiousBlue};
        }
    `;

    return (
        <div css={style} onClick={onClickHandler}>
            {children}
        </div>
    );
};

export default NavSubTextBox;
