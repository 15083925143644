import React from 'react';
import { css } from '@emotion/core';

import { Theme } from '../../../theme';
import { ReactComponent as VisaCardIcon } from "../../../images/cards/visa.svg";
import { ReactComponent as MasterCardIcon } from "../../../images/cards/master.svg";
import { ReactComponent as AmericanExpressIcon } from "../../../images/cards/american-express.svg";

type Props = {
    currency?: string,
};

const OrderSummaryFooter: React.FC<Props> = ({ currency = '' }) => (
    <div css={containerStyle}>
        <div css={titleStyle}>Charges auto-renew</div>
        <div css={subtitleStyle}>(Unless cancelled before next renewal date)</div>
        <div css={cardContainerStyle}>
            <VisaCardIcon css={cardStyle} />
            <MasterCardIcon css={cardStyle} />
            <AmericanExpressIcon css={cardStyle} />
        </div>
        <div css={currencyStyle}>All amounts shown in <span>{currency}</span></div>
    </div>
);

const containerStyle = (theme: Theme) => css`
    min-height: 140px;
    padding: 21px 22px;
    text-align: center;
    font-size: 12px;
    font-weight: ${theme.fonts.weights.light};
`;

const titleStyle = (theme: Theme) => css`
    font-size: 14px;
    font-weight: ${theme.fonts.weights.base};
    min-height: 20px;
    line-height: 17px;
`;

const subtitleStyle = (theme: Theme) => css`
    color: ${theme.colours.grey['650']};
    min-height: 20px;
    line-height: 17px;
`;

const cardContainerStyle = css`
    width: 120px;
    min-height: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 0;
    margin: 8px auto 11px;
`;

const cardStyle = css`
    margin-right: 2px;
`;

const currencyStyle = (theme: Theme) => css`
    height: 20px;
    line-height: 17px;
    font-size: 12px;
    margin: 8px 0 12px;
    color: ${theme.colours.grey['650']};

    span {
        font-weight: ${theme.fonts.weights.base};
        color: ${theme.colours.grey['900']};
    }
`;

export default OrderSummaryFooter;
