import React, { memo, useCallback, useEffect } from 'react';
import { css } from '@emotion/core';
import { useHistory } from 'react-router-dom';
import { DropdownOption } from '../../../core/components/form/DropDown';
import { AUTH_URL, PAYMENT_URL } from "../../../core/includes/pricingAuthRedirects";
import { Theme } from "../../../theme";
import useStripePlans, { StripePlan } from "../../../core/hooks/useStripePlans";
import useSetState from "../../../core/hooks/useSetState";
import * as storage from "../../../core/includes/localStorage";
import { useAppState } from "../../../core/contexts/AppContext";
import { isSocialLoginUser } from "../../../core/includes/auth";
import Loading from "../../../core/components/utils/Loading";
import OrderSummary from "./OrderSummary";
import Payment from "./Payment"
import PlanSelectionFooter from "./PlanSelectionFooter";
import PlanSelectionItem from "./PlanSelectionItem";
import PlanSelectionPlanType from "./PlanSelectionPlanType";
import PlanSelectionUsers from "./PlanSelectionUsers";
import BlacklistCountryRedirect from "../../../core/components/utils/BlacklistCountryRedirect";
import Button from "../../../core/components/buttons/Button";
import { PromotionCode } from "../../../config/graphQLTypes";

type Props = {
    isPayment: boolean
};

type DefaultState = {
    currentPlan: StripePlan | null,
    currentNumberOfUsers: DropdownOption,
    currentPlanType: DropdownOption,
    isSubmitting: boolean,
    promotionCode: PromotionCode | null,
};

const PlanSelection: React.FC<Props> = ({isPayment}) => {
    const history = useHistory();
    const urlPlanType = new URLSearchParams(history?.location?.search).get('plan-type');

    // Get persisted storage value
    const storageCurrentPlanTypeID = (urlPlanType === 'personal' || urlPlanType === 'family') ? urlPlanType : (storage.retrieveValue('currentPlanTypeID') ?? 'personal');
    // Find plan based on storage value, or set to default.
    const currentPlanType = PLAN_TYPES.find(
        (planType: DropdownOption) => planType.id === storageCurrentPlanTypeID
    ) || PLAN_TYPES[0];

    // Get persisted storage value
    const storageCurrentNumberOfUsersID = storage.retrieveValue('currentNumberOfUsersID');
    // Find number of users based on storage value, or set to default.
    let currentNumberOfUsers = NUMBER_OF_USERS.find((numberOfUsers: DropdownOption) => {
        return numberOfUsers.id === storageCurrentNumberOfUsersID;
    });

    if (!currentNumberOfUsers) {
        if (currentPlanType.id === 'family') {
            currentNumberOfUsers = NUMBER_OF_USERS[0];
        } else {
            currentNumberOfUsers = SINGLE_USER;
        }
    }

    const currentPlanID = storage.retrieveValue('currentPlanID');
    const [appState,] = useAppState();
    const stripePlans = useStripePlans();

    // Create state with base values and use storage values where appropriate
    const [state, setState] = useSetState<DefaultState>({
        currentPlan: null,
        currentPlanType,
        currentNumberOfUsers,
        isSubmitting: false,
        promotionCode: null,
    });

    // Set planType based on dropdown selection.  If changing plan, reset number of users.
    // Make sure storage is being persisted.
    const setPlanType = (planType: DropdownOption) => {
        if (state.currentPlanType.id !== planType.id) {
            const newNumberOfUsers = planType.id === 'personal' ? SINGLE_USER : NUMBER_OF_USERS[0];

            setState({
                currentPlanType: planType,
                currentNumberOfUsers: newNumberOfUsers,
            });
            storage.persistValue('currentPlanTypeID', planType.id);
            storage.persistValue('currentNumberOfUsersID', newNumberOfUsers.id);
        }
    };

    const setCurrentPlan = (plan: StripePlan) => {
        setState({ currentPlan: plan });
        storage.persistValue('currentPlanID', plan.id);
    };

    const setCurrentNumberOfUsers = (numberOfUsers: DropdownOption) => {
        setState({ currentNumberOfUsers: numberOfUsers });
        storage.persistValue('currentNumberOfUsersID', numberOfUsers.id);
    };

    const onSubmit = () => {
        setState({ isSubmitting: true });
        history.push({
            pathname: getConditionalRedirect(appState),
            state: { redirectTo: PAYMENT_URL },
        });
        setState({ isSubmitting: false });
    };

    const onApplyPromoCode = useCallback(
        (promotionCode: PromotionCode | null) => setState({ promotionCode }),
        [setState]
    );

    useEffect(() => {
        if (urlPlanType) {
            const newNumberOfUsers = storageCurrentPlanTypeID === 'personal' ? SINGLE_USER : NUMBER_OF_USERS[0];

            setState({
                currentPlanType: currentPlanType,
                currentNumberOfUsers: newNumberOfUsers,
            });
            storage.persistValue('currentPlanTypeID', storageCurrentPlanTypeID);
            storage.persistValue('currentNumberOfUsersID', newNumberOfUsers.id);
        }
    }, [urlPlanType, currentPlanType, setState, storageCurrentPlanTypeID])

    // Set plan based on local storage, otherwise grab first option.
    useEffect(
        () => {
            if (!stripePlans) {
                return;
            }

            const possiblePlan = currentPlanID && stripePlans.find((plan: StripePlan) => plan.id === currentPlanID);
            const currentPlan = possiblePlan || stripePlans[0];

            setState({ currentPlan });
            storage.persistValue('currentPlanID', currentPlan.id);
        },
        [stripePlans, setState, currentPlanID]
    );

    return (
        <div css={containerStyle(isPayment)}>
            <div css={leftSectionStyle}>
                {!isPayment && (
                    <div css={dropdownContainerStyle}>
                        <PlanSelectionPlanType
                            options={PLAN_TYPES}
                            selected={state.currentPlanType}
                            onChange={setPlanType}
                        />
                        <PlanSelectionUsers
                            currentPlanType={state.currentPlanType}
                            options={NUMBER_OF_USERS}
                            selected={state.currentNumberOfUsers}
                            onChange={setCurrentNumberOfUsers}
                        />
                    </div>
                )}
                <div css={paymentContainerStyle}>
                    { !isPayment && (
                        <div css={selectionContainerStyle}>
                            <div css={planSelectStyle}>
                                { !stripePlans && (
                                    <Loading />
                                )}
                                { stripePlans && stripePlans.map( (plan: StripePlan, index: number) => (
                                    <PlanSelectionItem
                                        key={index}
                                        isSelected={plan.Interval === state.currentPlan?.Interval}
                                        numberOfUsers={state.currentNumberOfUsers.value}
                                        onPlanSelect={setCurrentPlan}
                                        plan={plan}
                                    />
                                ))}
                            </div>
                            <PlanSelectionFooter
                                plan={state.currentPlan}
                                isSubmitting={state.isSubmitting}
                                onClick={onSubmit}
                            />
                        </div>
                    )}
                    {isPayment && (
                        <>
                            <BlacklistCountryRedirect />
                            <Payment
                                promotionCode={state.promotionCode}
                                plan={state.currentPlan}
                                numberOfUsers={state.currentNumberOfUsers.value}
                            />
                        </>
                    )}
                </div>
            </div>
            <div css={rightSectionStyle(isPayment)}>
                <OrderSummary
                    promotionCode={state.promotionCode}
                    onApplyPromoCode={onApplyPromoCode}
                    plan={state.currentPlan}
                    planType={state.currentPlanType.value}
                    numberOfUsers={state.currentNumberOfUsers.value}
                />
            </div>
            {!isPayment && (
                <div css={buttonStyle}>
                    <Button
                        color={"secondary"}
                        disabled={!state.currentPlan}
                        disabledTooltipMessage={"Please select a plan"}
                        forceLoading={state.isSubmitting}
                        onClick={onSubmit}
                        size="lg"
                        type="submit"
                    >
                        Continue
                    </Button>
                </div>
            )}
        </div>
    );
};

const getConditionalRedirect = (appState: any) => {
    if (appState.hasOwnProperty('authorized')) {
        if(!appState?.authorized) {
            return AUTH_URL;
        } else {
            if(appState?.user?.IsSocialConfirmed && isSocialLoginUser(appState?.user)) {
                return PAYMENT_URL;
            }
            else if (appState?.user?.LastLoginType === 'password' && appState?.user?.IsEmailVerified) {
                return PAYMENT_URL;
            } else {
                return AUTH_URL;
            }
        }
    }
    return AUTH_URL;
};

const PLAN_TYPES: DropdownOption[] = [
    {
        id: 'personal',
        value: 'Personal',
    },
    {
        id: 'family',
        value: 'Family',
    }
];

const SINGLE_USER = {
    id: '1',
    value: '1',
};

const NUMBER_OF_USERS: DropdownOption[] = [
    {
        id: '2',
        value: '2',
    },
    {
        id: '3',
        value: '3',
    },
    {
        id: '4',
        value: '4',
    },
    {
        id: '5',
        value: '5',
    },
    {
        id: '6',
        value: '6',
    },
];

const containerStyle = (isPayment: boolean) => (theme: Theme) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;

    ${theme.breakpoints.down('md')} {
        flex-wrap: wrap;


        ${isPayment && css`
            flex-direction: column-reverse;
            margin-top: 0;
        `};
    }
`;

const leftSectionStyle = (theme: Theme) => css`
    display: flex;
    flex-direction: column;

    ${theme.breakpoints.down('md')} {
        width: 100%;
        max-width: 510px;
        margin: 0 auto;
    }
`;

const dropdownContainerStyle = (theme: Theme) => css`
    width: 510px;
    height: 50px;
    background-color: ${theme.colours.lightGrey};
    border-radius: ${theme.borderAndShadow.smallRadius};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    ${theme.breakpoints.down('md')} {
        width: 100%;
    }
`;

const paymentContainerStyle = css`
    width: 100%;
    display: flex;
    flex-direction: row;
`;

const selectionContainerStyle = (theme: Theme) => css`
    width: 510px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${theme.breakpoints.down('md')} {
        width: 100%;
    }
`;

const planSelectStyle = (theme: Theme) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ${theme.breakpoints.down('md')} {
        justify-content: space-evenly;
    }
`;

const rightSectionStyle = (isPayment: boolean) => (theme: Theme) => css`
    margin-left: 50px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    ${theme.breakpoints.down('md')} {
        width: 100%;
        max-width: 510px;
        margin: 0 auto;

        ${isPayment && css`
            margin-bottom: 28px;
        `};
    }
`;

const buttonStyle = (theme: Theme) => css`
    width: 100%;
    display: none;
    margin-top: 22px;

    ${theme.breakpoints.down('md')} {
        display: flex;
        justify-content: center;
    }
`;

export default memo(PlanSelection);
