import React from "react";
import { css } from "@emotion/core";

import theme from "../../../../theme";
import Cart from '../../icons/Cart';
import { sendCorporate } from "../../../includes/navigate";

type Props = {
    to: string,
    closeBurgerMenu: ({ forcedClose }: any) => void,
};

const CartIcon: React.FC<Props> = ({ to, closeBurgerMenu }) => {
    const sendToLink = () => {
        closeBurgerMenu({ forcedClose: true });
        sendCorporate(to);
    };

    const style = css`
        display: flex;
        align-items: center;
        cursor: pointer;

        ${theme.breakpoints.up("xxl")} {
            margin-right: 20px;
        }

        ${theme.breakpoints.between("lg", "xl")} {
            margin-right: 10px;
        }

        ${theme.breakpoints.down("md")} {
            margin-right: ${theme.sizes.menu.sideMargin}px;
        }
    `;

    const cartStyle = css`
        width: 25px;
        height: 25px;

        &:hover {
            > svg > path {
                fill: ${theme.colours.scienceBlue};
            }
        }
    }
    `;

    return (
        <div css={style} onClick={sendToLink}>
            <Cart css={cartStyle} />
        </div>
    );
};
export default CartIcon;
