import React, { useCallback, useMemo, useEffect, useRef } from 'react';
import gql from 'graphql-tag';
import { css, SerializedStyles } from "@emotion/core";
import { Link } from "react-router-dom";

import Button from "../buttons/Button";
import FormActions from "../form/FormActions";
import { useMutation } from "@apollo/react-hooks";
import { useAppState } from "../../contexts/AppContext";
import VerificationInput from "react-verification-input";
import OutsideClickHandler from "react-outside-click-handler";
import { Redirect, useHistory } from "react-router";
import { SUCCESS_URL, AUTH_SIGNUP_URL } from "../../includes/pricingAuthRedirects";
import Loading from "../utils/Loading";
import { Theme } from "../../../theme";
import { needEmailVerification } from "../../includes/auth";
import errorParser from "../../includes/errorParser";
import useSetState from "../../hooks/useSetState";
import useRedirectHomeIfOnRestrictedPage from "../../hooks/useRedirectHomeIfOnRestrictedPage";

type DefaultState = {
    isSubmitting: boolean,
    isLoadingResend: boolean,
    disableButton: boolean,
    verificationCode: number,
    verificationError: string,
};

const INITIAL_STATE = {
    isSubmitting: false,
    isLoadingResend: false,
    disableButton: false,
    verificationCode: 0,
    verificationError: '',
};

type Props = {
    successUrl?: string,
    changeEmailLink?: string,
    extraHeaderStyles?: SerializedStyles,
    onSuccess?: () => void
    changeEmailContent?: React.ReactNode,
};

const verificationCodeLength = 5;
const VERIFICATION_CODE_PLACEHOLDER = "-";

const EmailVerification: React.FC<Props> = (
    {
        successUrl = SUCCESS_URL,
        changeEmailLink = AUTH_SIGNUP_URL,
        extraHeaderStyles,
        onSuccess,
    }
) => {
    const history: any = useHistory();
    const redirectTo = history.location.state?.redirectTo;
    const [appState] = useAppState();
    const inputRef = useRef<HTMLInputElement>();
    const [state, setState] = useSetState<DefaultState>(INITIAL_STATE);
    const [verifyEmailHomeUser] = useMutation(EMAIL_VERIFICATION_QUERY);
    const [resendEmailVerification] = useMutation(RESEND_EMAIL_VERIFICATION_QUERY);
    useRedirectHomeIfOnRestrictedPage();

    const updateVerificationInputStyle = useCallback(() => {
        const characters = document.querySelectorAll(".verification-character");
        characters.forEach(character => {
            if (character.textContent === VERIFICATION_CODE_PLACEHOLDER && !character.classList.contains("verification-character--selected")) {
                character.classList.add("verification-character--inactive");
            }
        });
    }, []);

    useEffect(() => {
        setState({ disableButton: state.verificationCode.toString().length !== verificationCodeLength });
    }, [state.verificationCode, setState]);

    useEffect(() => {
        updateVerificationInputStyle();
    }, [appState.hasUserBeenLoaded, updateVerificationInputStyle]);

    const focusInput = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const setInputRef = useCallback((ref: HTMLInputElement) => {
        if (ref) {
            inputRef.current = ref;
        }
        focusInput();
    }, [focusInput]);

    const onSuccessVerification = useCallback(() => {
        if (onSuccess) {
            onSuccess();
        } else {
            appState.refetchUser().then(() => {
                history.push(redirectTo ? redirectTo : successUrl);
            });
        }
    }, [history, onSuccess, appState, redirectTo, successUrl]);

    const submitEmailVerificationForm = useCallback(() => {
        setState({ isSubmitting: true, verificationError: '' });
        return verifyEmailHomeUser({
            variables: {
                verificationCode: state.verificationCode
            }
        }).then((data) => {
            onSuccessVerification();
        }).catch((error) => {
            setState({ isSubmitting: false, verificationError: errorParser(error) });
        }).finally(focusInput);
    }, [state.verificationCode, verifyEmailHomeUser, onSuccessVerification, setState, focusInput]);

    const onResendEmailVerification = useCallback((e) => {
        e.preventDefault();
        setState({ isLoadingResend: true, verificationError: '' });

        return resendEmailVerification().then((data) => {
            setState({ isLoadingResend: false });
        }).catch((error) => {
            setState({ isLoadingResend: false, verificationError: errorParser(error) });
        }).finally(focusInput);
    }, [resendEmailVerification, setState, focusInput]);

    const inputProps = useMemo(() => ({
        onChange: (e: number) => {
            setState({ verificationCode: e, verificationError: '' });
        }
    }), [setState]);

    useEffect(() => {
        const fn = (event: KeyboardEvent) => {
            if (event.key === 'Enter' && !state.disableButton) {
                submitEmailVerificationForm();
            }
        };

        window.addEventListener("keypress", fn);

        return () => {
            window.removeEventListener("keypress", fn);
        }
    }, [state.disableButton, submitEmailVerificationForm]);

    const userEmail = appState?.user?.Email;

    if (!appState.hasUserBeenLoaded) {
        return <Loading />;
    }

    if (appState?.user && !needEmailVerification(appState?.user)) {
        return <Redirect to={successUrl} />;
    }

    return (
        <div css={emailVerificationContent}>
            {(state.isSubmitting || state.isLoadingResend) && (
                <Loading onTop overlay overlayColour={"transparent"} />
            )}
            <div css={emailVerificationHeader(extraHeaderStyles)}>
                <span>Email Verification</span>
            </div>
            <div css={emailVerificationText}>
                <span>We have sent a verification code to <b>{userEmail}</b>, please check your inbox and enter the code here.</span>
            </div>
            <div css={emailVerificationForm(!!state.verificationError)}>
                <OutsideClickHandler onOutsideClick={updateVerificationInputStyle}>
                    <VerificationInput
                        input={inputProps}
                        length={5}
                        validChars="0-9"
                        placeholder={VERIFICATION_CODE_PLACEHOLDER}
                        removeDefaultStyles
                        container={{ className: "verification-container" }}
                        characters={{ className: "verification-characters" }}
                        character={{
                            className: "verification-character",
                            classNameInactive: "verification-character--inactive",
                            classNameSelected: "verification-character--selected"
                        }}
                        getInputRef={setInputRef}
                    />
                    <div css={emailVerificationError(!!state.verificationError)}>
                        <span>{state.verificationError}.</span>
                    </div>
                </OutsideClickHandler>
            </div>
            <div css={resendCodeText}>
                <span>
                    <Link to="/resend-code" onClick={onResendEmailVerification}>Click here</Link>
                    {` for a new code if it didn't work or you didn't receive an email.`}
                </span>
            </div>
            <div css={resendCodeText}>
                <span>
                    <Link to={changeEmailLink}>Click here</Link>
                    {` to change your email if your email is incorrect.`}
                </span>
            </div>
            <div css={formActionsStyle}>
                <FormActions>
                    <Button
                        type="submit"
                        color={"secondary"}
                        disabled={state.disableButton}
                        forceLoading={state.isSubmitting}
                        onClick={submitEmailVerificationForm}
                    >
                        Continue
                    </Button>
                </FormActions>
            </div>
        </div>
    );
};

const EMAIL_VERIFICATION_QUERY: any = gql`
    mutation verifyEmailHomeUser($verificationCode: Int!) {
        verifyEmailHomeUser(VerificationCode: $verificationCode) {
            id
        }
    }
`;

const RESEND_EMAIL_VERIFICATION_QUERY: any = gql`
    mutation resendSignUpEmailVerificationCode {
        resendSignUpEmailVerificationCode
    }
`;

const emailVerificationContent = (theme: Theme) => css`
    margin-top: 40px;
    width: 510px;
    max-width: 510px;
    position: relative;

    ${theme.breakpoints.down("sm")} {
        width: 100%;

        .verification-characters  .verification-input__character.verification-character {
            margin-right: 15px;
        }
    }
`;

const emailVerificationHeader = (extraHeaderStyles?: SerializedStyles) => (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${theme.colours.grey['90']};
    width: 100%;
    height: 50px;

    span {
        font-size: 26px;
        margin-left: 20px;
        font-weight: 400;
    }

    ${extraHeaderStyles};
`;

const emailVerificationText = css`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 2px;

    span {
        font-size: 16px;
    }
`;

const emailVerificationError = (isVisible: boolean) => (theme: Theme) => css`
    height: 14px;
    color: ${isVisible ? theme.colours.red[300] : 'transparent'};
    width: 100%;
    margin-top: 8px;
`;

const emailVerificationForm = (errorMessageVisible: boolean) => (theme: Theme) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .verification-character {
        border: 1px solid ${theme.colours.grey[450]};
        border-radius: 3px;
        margin-top: 10px;
        padding: 0 14px;
        background: transparent;
        outline: none;
        line-height: 56px;
        width: 56px;
        height: 60px;
        margin-right: 20px;
        text-align: center;
        font-family: ${theme.fonts.frutiger};
        font-size: 46px;
        font-weight: 400;
        display: block;

        &--inactive {
            color: ${theme.colours.grey[300]};
        }

        &--selected {
            border: 1px solid ${theme.colours.blue[400]};
            color: ${theme.colours.blue[400]};
        }

        ${errorMessageVisible && css`
            border: 1px solid ${theme.colours.crimson};
            background-color: ${theme.colours.soapStone};
        `}
    }

    ${theme.breakpoints.down('xxs')} {
        justify-content: center;

        .verification-character {
            width: 47px;
            height: 50px;
            padding: 0 10px;
            line-height: 50px;

            :first-of-type {
                margin-left: 14px;
            }
        }
    }
`;

const resendCodeText = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    span {
        font-size: 14px;
        font-weight: 300;

        a {
            text-decoration: none;
            color: ${theme.colours.blue['400']};

            &:hover {
                text-decoration: underline;
            }
        }
    }
`;

const formActionsStyle = (theme: Theme) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    ${theme.breakpoints.down('sm')} {
        margin-top: 20px;
        justify-content: center;
    }
`;

export default EmailVerification;
