import React from "react";
import { css } from "@emotion/core";
import theme from "../../../../theme";

type Props = {
    children: React.ReactNode,
};

const NavMenuText: React.FC<Props> = ({
    children,
}) => {
    const style = css`
        display: flex;
        justify-content: center;
        align-items: center;
        padding: ${theme.sizes.menu.heightMainUnderLine}px 20px 0;
        height: ${theme.sizes.menu.heightMain - theme.sizes.menu.heightMainUnderLine}px;
        font-size: 14px;
        font-weight: ${theme.fonts.weights.normal};

        ${theme.breakpoints.up("5xl")} {
            padding: ${theme.sizes.menu.heightMainUnderLine}px 30px 0;
            font-size: 16px;
        }

        ${theme.breakpoints.down("xl")} {
            padding: ${theme.sizes.menu.heightMainUnderLine}px 13px 0;
        }
    `;

    return (
        <div css={style}>{children}</div>
    );
};

export default NavMenuText;
