import React, { forwardRef } from "react";
import { css } from "@emotion/core";

import theme from "../../../../theme";

type Props = {
    countryName?: string,
    showOptions?: boolean
};

const DropdownPlaceHolder = forwardRef<HTMLDivElement, Props>(({
    countryName = "",
    showOptions = false,
}, ref) => {
    const style = css`
        padding-right: 17px;
        white-space: nowrap;
        color: ${showOptions ? theme.colours.curiousBlue : "black"};
        cursor: pointer;
    `;

    return (
        <div css={style} ref={ref}>
            {countryName}
        </div>
    );
});

export default DropdownPlaceHolder;
