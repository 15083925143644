import React, {memo, useCallback, useEffect} from 'react';
import {css, SerializedStyles} from '@emotion/core';
import {DOMUtils} from '../../includes/DOMUtils';
import Portal from '../Portal';
import FocusLock from 'react-focus-lock';

type SizeType = string | 'small' | 'medium' | 'max';

type Props = {
    children: React.ReactNode,
    innerRef?: () => void,
    onClose?: (e: React.MouseEvent) => void,
    onOverlayClick?: () => void,
    overlay?: boolean,
    scroll?: boolean,
    size?: SizeType,
    showCloseButton?: boolean,
    extraStyle?: SerializedStyles,
};

const Dialog: React.FC<Props> = (
    {
        children,
        innerRef,
        onClose,
        onOverlayClick,
        overlay,
        scroll,
        showCloseButton,
        size,
        extraStyle,
    }
) => {

    /**
     * Disable body scrolling on mobile.
     */
    useEffect(() => {
        if (DOMUtils.isMobile()) {
            DOMUtils.preventBodyScrolling();
        }

        return () => {
            if (DOMUtils.isMobile()) {
                DOMUtils.enableBodyScrolling();
            }
        };
    }, []);

    /**
     * Handle close button click.
     */
    const handleClose = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (typeof onClose === 'function') {
            onClose(e);
        } else if (typeof onOverlayClick === 'function') {
            onOverlayClick();
        }
    }, [onClose, onOverlayClick]);

    const closeText = size === 'max' ? 'CLOSE' : 'x';

    return (
        <Portal id="dialog-portal">
            <FocusLock>
                {overlay && (
                    <div
                        css={overlayStyles}
                        onClick={onOverlayClick ? onOverlayClick : () => {
                        }}
                    />
                )}
                <div css={containerStyles(size, extraStyle)} ref={innerRef}>
                    {showCloseButton && <span css={closeStyles} onClick={handleClose}>{closeText}</span>}
                    {children}
                </div>
            </FocusLock>
        </Portal>
    );
};

Dialog.defaultProps = {
    overlay: true,
    showCloseButton: true,
};

const overlayStyles = css`
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
`;

const getScreenWidth = (size?: SizeType) => {
    switch(size) {
        case "medium":
        case "max":
            return "500px";

        case "small":
        default:
            return "280px";
    }
}

const containerStyles = (size?: SizeType, extraStyle?: SerializedStyles) => (theme: any) => css`
    ${theme.popover.container};

    width: ${getScreenWidth(size)};
    max-width: 80%;
    max-height: 80%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
    padding: 24px;

    @media (max-width: 616px) {
        padding: 15px;
    }

    ${extraStyle};
`;

const closeStyles = (theme: any) => css`
    position: absolute;
    right: 0;
    top: 0;
    border-width: 0 0 1px 1px;
    border-color: ${theme.colours.grey[700]};
    border-style: solid;
    font-size: 16px;
    cursor: pointer;
    color: ${theme.colours.white};
    line-height: 20px;
    vertical-align: middle;
    text-align: center;
    display: block;
    width: 24px;
    height: 24px;
    background: ${theme.colours.grey[700]};

    &:hover {
        border-color: ${theme.colours.grey[500]};
        background: ${theme.colours.grey[500]};
    }
`;

export default memo(Dialog);
