import {
    isPossiblePhoneNumber,
    isValidPhoneNumber as isValidPhone,
    parsePhoneNumber
} from "react-phone-number-input/min";
import { parsePhoneNumberFromString } from "libphonenumber-js/min";

export const isValidPhoneNumber = (phone: string) => {
    if (!phone) return false;

    if (!isPossiblePhoneNumber(phone) || !isValidPhone(phone)) {
        return false;
    }

    const phoneObject = parsePhoneNumber(phone);
    if (!phoneObject) {
        return false;
    }

    const parsedPhoneNumber = parsePhoneNumberFromString(phone, phoneObject.country);
    if (parsedPhoneNumber === undefined || !parsedPhoneNumber.isValid()) {
        return false;
    }

    return true;
};