export const OTHER = { value: "Other (please specify)" };

export const COUNTRIES_WITH_REGION_OPTIONS: any = {
    "GB": [
        { value: "South East England" },
        { value: "London (Greater London)" },
        { value: "North West England" },
        { value: "East of England" },
        { value: "West Midlands" },
        { value: "South West England" },
        { value: "Yorkshire and the Humber" },
        { value: "North East England" },
        OTHER,
    ],
    "SG": [
        { value: "Central Region (SG)" },
        { value: "East Region (SG)" },
        { value: "North Region (SG)" },
        { value: "North-East Region (SG)" },
        { value: "West Region" },
        OTHER,
    ],
    "HK": [
        { value: "New Territories" },
        { value: "Kowloon" },
        { value: "Hong Kong Island" },
        OTHER,
    ],
    "MY": [
        { value: "Kuala Lumpur" },
        { value: "Labuan" },
        { value: "Putrajaya" },
        OTHER,
    ],
    "VN": [
        { value: "Northeast (Northern Vietnam)" },
        { value: "Northwest (Northern Vietnam)" },
        { value: "Red River Delta (Northern Vietnam)" },
        { value: "North Central Coast (Central Vietnam)" },
        { value: "South Central Coast (Central Vietnam)" },
        { value: "Central Highlands (Central Vietnam)" },
        { value: "Southeast (Southern Vietnam)" },
        { value: "Mekong River Delta (Southern Vietnam)" },
        OTHER,
    ],
    "TH": [
        { value: "North Thailand" },
        { value: "North East Thailand" },
        { value: "Central Thailand" },
        { value: "East Thailand" },
        { value: "South Thailand" },
        OTHER,
    ],
    "NZ": [
        { value: "Auckland" },
        { value: "Bay of Plenty" },
        { value: "Canterbury" },
        { value: "Gisborne" },
        { value: "Hawke's Bay" },
        { value: "Manawatu-Whanganui" },
        { value: "Marlborough" },
        { value: "Nelson" },
        { value: "Northland" },
        { value: "Otago" },
        { value: "Taranaki" },
        OTHER,
    ],
    "AU": [
        { value: "Australian Capital Territory (ACT)" },
        { value: "New South Wales (NSW)" },
        { value: "Northern Territory (NT)" },
        { value: "Queensland (QLD)" },
        { value: "South Australia (SA)" },
        { value: "Tasmania" },
        { value: "Victoria (VIC)" },
        { value: "Western Australia (WA)" },
        OTHER,
    ],
};
