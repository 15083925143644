import React from "react";

import theme from  "../../../../theme";
import NavMenuUnderLine from "../../header/corporate/NavMenuUnderLine";
import BurgerNavSubTextBoxContainer from "./BurgerNavSubTextBoxContainer";

type Props = {
    children: React.ReactNode,
    headerMenu: StandardPage,
    currentURLSegment?: string,
    closeMenuHandler: () => void,
};

const BurgerNavHeaderTextBox: React.FC<Props> = ({
    children,
    headerMenu,
    currentURLSegment = "",
    closeMenuHandler,
}) => (
    <BurgerNavSubTextBoxContainer
        isTextBold
        colour={theme.colours.black}
        showUnderline={headerMenu.uRLSegment === currentURLSegment}
        {...headerMenu.isClickable && { clickHandler: closeMenuHandler }}
    >
        {children}
        <NavMenuUnderLine width={100} height={4} />
    </BurgerNavSubTextBoxContainer>
);

export default BurgerNavHeaderTextBox;
