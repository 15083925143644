import React from "react";
import { css } from "@emotion/core";
import theme from "../../../theme";

type Props = {
    showBurgerMenu?: boolean,
    iconSize: number,
};

const BurgerNavMenuIconLines: React.FC<Props> = ({ showBurgerMenu = false, iconSize }) => {
    const style = css`
        position: relative;
        width: ${iconSize}px;
        height: ${iconSize}px;

        > span {
            height: ${iconSize >= 28 ? 4 : 3}px;
            width: 100%;
            background: ${theme.colours.curiousBlue};
            transition: ${theme.transitions.transitionAll};
            border-radius: ${theme.borderAndShadow.largeRadius};
        }

        > span:nth-of-type(1) {
            position: absolute;
            top: 20%;
            transform: translateY(-50%);

            ${showBurgerMenu && css`
                transform: translateY(-50%) rotate(135deg);
                top: 50%;
            `};
        }

        > span:nth-of-type(2) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            ${showBurgerMenu && css`
                width: 0%;
            `};
        }

        > span:nth-of-type(3) {
            position: absolute;
            top: 80%;
            transform: translateY(-50%);

            ${showBurgerMenu && css`
                transform: translateY(-50%) rotate(225deg);
                top: 50%;
            `};
        }
    `;

    return (
        <div css={style}>
            <span />
            <span />
            <span />
        </div>
    );
};

export default BurgerNavMenuIconLines;
