import React from "react";
import { css } from "@emotion/core";

import theme from "../../../theme";
import { sendCorporate } from "../../includes/navigate";

type Props = {
    children: React.ReactNode,
    currentURLSegment?: string,
    link: string,
    isLast: boolean,
};

const BreadcrumbItem: React.FC<Props> = ({
    children,
    currentURLSegment = "",
    link,
    isLast
}) => {
    const onClickMenuHandler = () => {
        sendCorporate(link);
    };

    const style = css`
        cursor: pointer;

        ${(link.indexOf(currentURLSegment) > -1 || currentURLSegment === 'home') ? css`
            color: ${theme.colours.curiousBlue};
        ` : css`
            color: ${theme.colours.gray}
        `}
    `;

    const menuDivideStyle = css`
        padding: 0 10px;
        color: ${theme.colours.gray}
    `;

    return (
        <>
            <span css={style} onClick={onClickMenuHandler}>
                {children}
            </span>

            {!isLast && (
                <span css={menuDivideStyle}>/</span>
            )}
        </>
    );
};

export default BreadcrumbItem;
