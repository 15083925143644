import React from "react";
import { css } from "@emotion/core";

import theme from "../../../../theme";
import NavMenuText from "./NavMenuText";
import NavMenuUnderLine from "./NavMenuUnderLine";
import NavMenuTextCover from "./NavMenuTextCover";

type Props = {
    children: React.ReactNode,
    data: StandardPage,
    selectedSegments?: string[],
    mouseOverHandler: () => void,
    mouseClickHandler: Function,
};

const NavTextBox: React.FC<Props> = ({
    children,
    data,
    selectedSegments = undefined,
    mouseOverHandler,
    mouseClickHandler,
}) => {
    const onClickHandler = () => {
        if (data.isClickable) {
            mouseClickHandler();
        }
    };

    const style = css`
        height: ${theme.sizes.menu.heightMain}px;

        &:hover {
            color: ${theme.colours.curiousBlue};

            &:active {
                .nav-menu-underline {
                    background-color: ${theme.colours.curiousBlue}
                }
            }
        }

        // Selected menu
        ${selectedSegments?.includes(data.uRLSegment) && css`
            color: ${theme.colours.curiousBlue};
            font-weight: ${theme.fonts.weights.bold};

            .nav-menu-underline {
                background-color: ${theme.colours.curiousBlue};
            }
        `}
    `;

    return (
        <div css={style} onMouseOver={mouseOverHandler} onClick={onClickHandler}>
            <NavMenuText>{children}</NavMenuText>
            <NavMenuUnderLine />
            <NavMenuTextCover />
        </div>
    );
};

export default NavTextBox;
