import React from "react";
import {Link} from "react-router-dom";
import {css} from "@emotion/core";

type Props = {
    link?: string,
};

const WizardLink: React.FC<Props> = ({link, children}) => {
    if (link) {
        return (
            <Link to={link} css={linkStyle}>{children}</Link>
        );
    }

    return (
        <>
            {children}
        </>
    )
}

const linkStyle = css`
    width: 100%;
    height: 100%;
    display: inherit;
    text-decoration: none;
`

export default WizardLink;
