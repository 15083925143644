import useBreakpointLib from 'use-breakpoint';
import breakpoints from "../../theme/breakpoints";

const useBreakpoint = () => {
    const {
        breakpoint,
        maxWidth,
        minWidth
    } = useBreakpointLib(breakpoints.sizes, "xl");

    const isMobileBreakpoint = breakpoint === "xxs" || breakpoint === "xs" || breakpoint === "sm";

    return { breakpoint, maxWidth, minWidth, isMobileBreakpoint };
};

export default useBreakpoint;
