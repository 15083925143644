/**
 * Send Corporate site
 */
import { CORPORATE_URL } from "../../config/config";

export const sendCorporate = (url: string | undefined) => {
    // url should be allowed "" because of home link
    if (url !== undefined) {
        window.location.href = `${CORPORATE_URL}/${url}`;
    }
};
