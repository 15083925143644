import React from "react";
import { css } from "@emotion/core";

import theme from "../../../../theme";

import useBreakpoint from "../../../hooks/useBreakpoint";
import { isDown } from "../../../../theme/breakpoints";

const FooterCopyright = () => {
    const currentYear = new Date().getFullYear();
    const { breakpoint } = useBreakpoint();

    const style = css`
        margin: 35px auto 0;
        color: ${theme.colours.doveGray};
        font-size: 12px;

        ${theme.breakpoints.down("md")} {
            margin: 35px 0 0;
        }
    `;

    return (
        <div css={style}>
            Copyright © {currentYear} Advanced Learning. All Rights Reserved.
            {isDown(breakpoint, "md") && <br />}
            395A Manukau Road, Epsom, Auckland 1023, New Zealand
        </div>
    );
};

export default FooterCopyright;
