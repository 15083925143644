import React from "react";
import { css } from "@emotion/core";

import NavSubTextBox from "./NavSubTextBox";
import NavLinkTag from "./NavLinkTag";

type Props = {
    subMenu: StandardPage,
    selectedSegments?: string[],
    mouseClickHandler: Function,
};

const NavSubLink: React.FC<Props> = ({
    subMenu,
    selectedSegments = undefined,
    mouseClickHandler,
}) => {
    const style = css`
        margin: 5px 0;
        width: fit-content;
    `;

    return (
        <div css={style}>
            <NavLinkTag data={subMenu}>
                <NavSubTextBox
                    headerMenu={subMenu}
                    selectedSegments={selectedSegments}
                    mouseClickHandler={mouseClickHandler}
                >
                    {subMenu.title}
                </NavSubTextBox>
            </NavLinkTag>
        </div>
    );
};

export default NavSubLink;
