import { keyframes } from "@emotion/core";
import { Theme } from "./index";

const bloop: Function = (theme: Theme) => keyframes`
    0% {
        transform: none;
    }

    5% {
        transform: ${theme.transform.bloopScale};
    }

    10% {
        transform: none;
    }
`;

const slowBloop: Function = (theme: Theme) => keyframes`
    0% {
        transform: none;
    }

    2.5% {
        transform: ${theme.transform.bloopScale};
    }

    5% {
        transform: none;
    }
`;

const videoSnap = keyframes`
    5% {
        transform: rotate(-3deg);
    }

    10% {
        transform: rotate(0deg);
    }
`;

const wcfDraw = keyframes`
    to {
        stroke-dashoffset: 0;
    }
`;

const wcfTextShow = keyframes`
    to {
        opacity: 1;
    }
`;

export default {
    bloop,
    slowBloop,
    videoSnap,
    wcfDraw,
    wcfTextShow,
}