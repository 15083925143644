import React  from 'react';
import { css } from '@emotion/core';

import { DropdownOption } from '../../../core/components/form/DropDown';
import { Theme } from "../../../theme";
import DropDown from "../../../core/components/form/DropDown";

type Props = {
    onChange: Function,
    options: Array<DropdownOption>,
    selected: DropdownOption
};

const PlanSelectionPlanType: React.FC<Props> = ({
    onChange,
    options,
    selected,
}) => (
    <div css={containerStyle}>
        <DropDown
            extraOptionContainerStyles={extraOptionContainerStyles}
            extraOptionStyles={extraOptionStyles}
            hasBorder={false}
            options={options}
            searchable={false}
            selected={selected}
            setSelected={onChange}
        />
    </div>
);

const containerStyle = (theme: Theme) => css`
    padding-left: 10px;
    width: 170px;
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: ${theme.borderAndShadow.smallRadius};
    cursor: pointer;
    font-size: 26px;
    line-height: 17px;
    font-weight: ${theme.fonts.weights.base};

    &:hover {
        background: ${theme.colours.grey[300]};
    }
`;

const extraOptionContainerStyles = css`
    width: 168px;
    left: -9px;
`;

const extraOptionStyles = css`
    height: inherit;
    padding: 5px 5px 5px 21px;

    p {
        font-size: 16px;
    }
`;

export default PlanSelectionPlanType;
