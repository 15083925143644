const BASE_PATH = '/pricing';

const routes = {
    'pricing': {
        link: BASE_PATH
    },
    'pricing/select-plan': {
        link: `${BASE_PATH}/select-plan`
    },
    'pricing/plans': {
        link: `${BASE_PATH}/plans`
    },
    'pricing/auth': {
        link: `${BASE_PATH}/auth`
    },
    'pricing/email-verification': {
        link: `${BASE_PATH}/email-verification`
    },
    'pricing/social-confirmation': {
        link: `${BASE_PATH}/social-confirmation`
    },
    'pricing/payment': {
        link: `${BASE_PATH}/payment`
    },
    'pricing/payment-successful': {
        link: `${BASE_PATH}/payment-successful`
    },
};

export default routes;
