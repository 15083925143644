import React, { memo } from "react";
import { css } from "@emotion/core";
import { Theme } from "../../../theme";

type TwoColumnProps = {
    children: React.ReactNode,
    className?: string,
}

const TwoColumn: React.FC<TwoColumnProps> = ({ children, className }) => (
    <div css={twoColumnStyle} className={className}>{children}</div>
);

const twoColumnStyle = (theme: Theme) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 22px;

    ${theme.breakpoints.down('xs')} {
        display: flex;
        flex-direction: column;
        column-gap: unset;
    }

    > div:last-child {
        margin-bottom: 19px;
    }
`;

export default memo(TwoColumn);
