import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { css } from "@emotion/core";

import theme from "../../../../theme";

import DropdownPlaceHolderContainer from "../../dropdown/headerCountryDropdown/DropdownPlaceHolderContainer";
import DropdownPlaceHolder from "../../dropdown/headerCountryDropdown/DropdownPlaceHolder";
import DropdownChevron from "../../dropdown/headerCountryDropdown/DropdownChevron";
import DropdownOptions from "../../dropdown/headerCountryDropdown/DropdownOptions";
import DropdownOptionItem from "../../dropdown/headerCountryDropdown/DropdownOptionItem";

type Props = {
    countries?: [CountryItem],
    selectedCountry?: string,
    setCountryHandler: (countryCode: string) => void,
};

const DEFAULT_CHEVRON_SIZE = 15;

const DEFAULT_COUNTRY_GLOBAL: CountryItem = {
    id: '0',
    code: 'other',
    name: 'Global',
    useFocusPoint: false,
    image: {
        id: 0,
        URL: '',
        FocusPoint: [0, 0],
    }
};
const NavCountryDropdown: React.FC<Props> = ({
    countries = undefined,
    selectedCountry = '',
    setCountryHandler,
}) => {
    const [showOption, setShowOption] = useState(false);
    const placeHolderContainerRef = useRef<HTMLDivElement>(null);
    const placeHolderRef = useRef<HTMLDivElement>(null);

    const countryOptions = countries ? [...countries, DEFAULT_COUNTRY_GLOBAL] : [DEFAULT_COUNTRY_GLOBAL];
    const selectedCountryName = countryOptions?.find((item: CountryItem) => item.code === selectedCountry)?.name;
    const showOptionHandler = useCallback(() => {
        setShowOption(prevShowOption => !prevShowOption);
    }, [setShowOption]);

    useEffect(() => {
        const clickHandler = (e: any) => {
            if (placeHolderContainerRef.current && !placeHolderContainerRef.current.contains(e.target)) {
                setShowOption(false);
            }
        };
        window.addEventListener("click", clickHandler);
        return () => {
            window.removeEventListener("click", clickHandler);
        };
    }, []);

    const style = css`
        height: 100%;

        ${theme.breakpoints.between("sm", "lg")} {
            padding-right: ${theme.sizes.menu.sideMargin}px;
        }
    `;

    return (
        <div css={style}>
            <DropdownPlaceHolderContainer
                showOptionsHandler={showOptionHandler}
                ref={placeHolderContainerRef}
            >
                <DropdownPlaceHolder
                    countryName={selectedCountryName}
                    showOptions={showOption}
                    ref={placeHolderRef}
                />
                <DropdownChevron size={DEFAULT_CHEVRON_SIZE} showOptions={showOption} />
            </DropdownPlaceHolderContainer>
            <DropdownOptions
                showOptions={showOption}
                placeHolderWidth={placeHolderRef?.current?.offsetWidth}
                chevronSize={DEFAULT_CHEVRON_SIZE}
            >
                {countryOptions?.map((item: CountryItem, index) => (
                    item.code === selectedCountry
                        ? null
                        : <DropdownOptionItem
                            key={`country-code-list-${index}`}
                            countryItem={item}
                            setCountryHandler={setCountryHandler}
                        />
                ))}
            </DropdownOptions>
        </div>
    );
};

export default memo(NavCountryDropdown);
