import React, { memo } from 'react';
import { css } from '@emotion/core';
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { Theme } from "../../theme";
import PageContainer from "../../core/components/PageContainer";
import RedirectPage from "../RedirectPage";
import { LOGIN_URL, SIGNUP_URL } from "../../config/config";

const AuthPage: React.FC = () => {
    const match: any = useRouteMatch();

    return (
        <PageContainer>
            <div css={authPage}>
                <Switch>
                    <Route exact path={`${match.url}/signup`}>
                        <RedirectPage url={SIGNUP_URL} />
                    </Route>
                    <Route exact path={`${match.url}/login`}>
                        <RedirectPage url={LOGIN_URL} />
                    </Route>
                    <Route path="">
                        <RedirectPage url={LOGIN_URL} />
                    </Route>
                </Switch>
            </div>
        </PageContainer>
    );
};

const authPage = (theme: Theme) => css`
    font-family: ${theme.fonts.frutiger};
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: ${theme.sizes.pageBodyMinHeight};
    margin: 72px auto ${theme.sizes.pageBodyMarginBottom};

    ${theme.breakpoints.down('xs')} {
        margin-top: 0;
    }
`;

export default memo(AuthPage);
