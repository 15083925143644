import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { css } from "@emotion/core";

import theme from "../../../theme";

type Props = {
    children: React.ReactNode,
    closeBurgerMenu: Function,
};

const BurgerNavContainer: React.FC<Props> = ({ children, closeBurgerMenu }) => {
    const closeMenuHandler = () => {
        closeBurgerMenu({ forcedClose: true });
    }

    const style = css`
        position: fixed;
        width: 100%;
        min-width: ${theme.breakpoints.sizes.xs}px;
        max-height: 100%;
        overflow-y: auto;
        z-index: ${theme.zIndex.zIndexHigh};
        box-shadow: ${theme.borderAndShadow.dropShadow};

        ${theme.breakpoints.up("xl")} {
            display: none;
        }

        a {
            color: inherit;
            text-decoration: none;
        }
    `;

    return (
        <div css={style}>
            <OutsideClickHandler onOutsideClick={closeMenuHandler}>
                {children}
            </OutsideClickHandler>
        </div>
    );
};

export default BurgerNavContainer;
