import React, {memo, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';

type Props = {
    children: React.ReactNode,
    destroy?: boolean,
    id: string,
};

const Portal: React.FC<Props> = ({ children, destroy, id }) => {
    const [divEl, setDivEl] = useState<HTMLElement|null>(null);

    useEffect(() => {
        let el = document.getElementById(id);

        if (!el) {
            el = document.createElement('div');
            el.id = id;
            document.body.appendChild(el);

            el.style.position = 'fixed';
            el.style.zIndex = '10000';
            el.style.left = '0';
            el.style.top = '0';
            el.style.width = '100%';
            el.style.height = '100%';
            el.style.display = 'flex';
            el.style.alignItems = 'center';
            el.style.justifyContent = 'center';
        }

        setDivEl(el);

        return () => {
            if (el && destroy) {
                try {
                    document.body.removeChild(el);
                } catch (e) {
                }
            }
        }
    }, [destroy, id]);

    return divEl ? ReactDOM.createPortal(children, divEl) : null;
};

Portal.defaultProps = {
    destroy: true,
};

export default memo(Portal);
