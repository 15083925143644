import { useCallback, useEffect } from 'react';

import useSetState from "../hooks/useSetState";
import { countryToPhoneCode, phoneToCountryCode } from "../../theme/utils";
import Countries from "../components/country-dropdown/Countries";

export type OnFieldChange = (name: string, value: any, companionField?: any) => void;

const usePhoneNumberInput = (phone: string, phoneCode: string, onFieldChange: OnFieldChange, countryCode?: string) => {
    type PhoneNumberHistory = {
        previous: string,
        current: string,
    }

    type DefaultState = {
        isPhoneCodeSet: boolean,
        possiblePhoneCode: string,
        possiblePhoneNumber: string,
        phoneNumberHistory: PhoneNumberHistory,
    }

    const INITIAL_STATE = {
        isPhoneCodeSet: false,
        possiblePhoneCode: "",
        possiblePhoneNumber: "none",
        phoneNumberHistory: {
            previous: "",
            current: "",
        },
    }

    const [state, setState] = useSetState<DefaultState>(INITIAL_STATE);

    const setPhoneCodeIsSet = useCallback(() => setState({
        isPhoneCodeSet: !!phoneCode,
    }), [phoneCode, setState]);

    const potentiallySetPhoneCode = useCallback(() => {
        const wasPreviouslyNull = !state?.phoneNumberHistory?.previous;
        const wasPasteOrAutoFillUsed = (state?.phoneNumberHistory?.current?.length - state?.phoneNumberHistory?.previous?.length) > 1;
        const isNumberWithCode = state.phoneNumberHistory.current.charAt(0) === "+";
        const hasNumberBeenPastedOrAutoFilled = (wasPreviouslyNull || wasPasteOrAutoFillUsed) && isNumberWithCode;

        if (countryToPhoneCode(countryCode) === state.possiblePhoneCode) {
            onFieldChange("phoneCode", countryCode);
            onFieldChange("phone", phone.substr(state.possiblePhoneCode.length));
            setState({
                possiblePhoneCode: "",
                possiblePhoneNumber: "none",
            })
        } else if (Countries.find(country => country.phone === state.possiblePhoneCode)) {
            onFieldChange("phoneCode", phoneToCountryCode(state.possiblePhoneCode));
            onFieldChange("phone", phone.substr(state.possiblePhoneCode.length));
            setState({
                possiblePhoneCode: "",
                possiblePhoneNumber: "none",
            })
        } else if (hasNumberBeenPastedOrAutoFilled) {
            const potentialPhoneCodes: string[] = [];

            for (let i = 1; i < 6; i++) {
                potentialPhoneCodes.push(phone.substr(0, i));
            }

            potentialPhoneCodes.sort((a,b) => b.length - a.length);
            potentialPhoneCodes.forEach(code => {
                if (countryToPhoneCode(countryCode) === code) {
                    onFieldChange("phoneCode", countryCode);
                    onFieldChange("phone", phone.substr(code.length));
                    setState({
                        possiblePhoneCode: "",
                        possiblePhoneNumber: "none",
                    })
                } else if (Countries.find(country => country.phone === code)) {
                    onFieldChange("phoneCode", phoneToCountryCode(code));
                    onFieldChange("phone", phone.substr(code.length));
                    setState({
                        possiblePhoneCode: "",
                        possiblePhoneNumber: "none",
                    })
                }
            })
        }
    }, [countryCode, phone, onFieldChange, state, setState]);

    const setPhoneNumberHistoryAndPossiblePhoneNumbers = useCallback(() => {
        const previous = state?.phoneNumberHistory?.current;
        const current = phone;
        const isFirstCharacterOfPreviousPlus = previous?.charAt(0) === "+";
        const isFirstCharacterOfCurrentPlus =  current?.charAt(0) === "+";
        if (state.possiblePhoneNumber === "none" && !isFirstCharacterOfPreviousPlus && isFirstCharacterOfCurrentPlus) {
            setState({
                possiblePhoneNumber: current?.length > 1 ? current.substr(1) : "",
                possiblePhoneCode: current?.substr(0, 1),
            })
        } else if (state.possiblePhoneNumber !== "none" && isFirstCharacterOfPreviousPlus && !isFirstCharacterOfCurrentPlus) {
            setState({
                possiblePhoneNumber: "none",
                possiblePhoneCode: "",
            })
        } else if (state.possiblePhoneNumber !== "none" && isFirstCharacterOfCurrentPlus) {
            setState({
                possiblePhoneCode: current?.substr(0, current?.length - state.possiblePhoneNumber?.length),
            });
        }

        setState({
            phoneNumberHistory: {
                previous: previous,
                current: current,
            },
        });
    }, [phone, state, setState]);

    const possiblePhoneCode = state.possiblePhoneCode;
    const phoneNumberHistory = state.phoneNumberHistory;
    const isPhoneCodeSet = state.isPhoneCodeSet;

    useEffect(() => {
        if (possiblePhoneCode) {
            potentiallySetPhoneCode();
        }
    }, [possiblePhoneCode, potentiallySetPhoneCode]);

    useEffect(() => {
        if (phoneCode) {
            setPhoneCodeIsSet();
        }
    }, [phoneCode, setPhoneCodeIsSet]);

    useEffect(() => {
        if (phoneNumberHistory.current !== phone) {
            setPhoneNumberHistoryAndPossiblePhoneNumbers();
        }
    }, [phone, phoneNumberHistory, setPhoneNumberHistoryAndPossiblePhoneNumbers]);

    return isPhoneCodeSet;
};

export default usePhoneNumberInput;
