import React, { forwardRef, ReactNode } from "react";
import { css } from "@emotion/core";

type Props = {
    children: ReactNode,
    showOptionsHandler: () => void,
};

const DropdownPlaceHolderContainer = forwardRef<HTMLDivElement, Props>(({
    children,
    showOptionsHandler,
}, ref) => {
    const style = css`
        display: flex;
        justify-content: end;
        align-items: center;
        height: 100%;
    `;

    return (
        <div css={style} onClick={showOptionsHandler} ref={ref}>
            {children}
        </div>
    );
});

export default DropdownPlaceHolderContainer;
