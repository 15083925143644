import React from "react";
import { css } from "@emotion/core";

import theme from "../../../../theme";

type Props = {
    children: React.ReactNode,
};

const NavShadowBox: React.FC<Props> = ({
    children,
}) => {
    const style = css`
        background-color: ${theme.colours.white};
        box-shadow: ${theme.borderAndShadow.dropShadow};
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
}

export default NavShadowBox;
