import React from "react";
import { css } from "@emotion/core";
import { sendCorporate } from "../../../includes/navigate";
import theme from "../../../../theme";

type Props = {
    children: React.ReactNode,
    data: StandardPage | null,
};

const NavLinkTag: React.FC<Props> = ({
    children,
    data,
}) => {
    const sendToLink = () => {
        sendCorporate(data?.uRLSegment);
    };

    const style = css`
        cursor: pointer;
        width: fit-content;
        font-weight: ${theme.fonts.weights.base};
    `;

    return (
        data?.isClickable ? (
            <div onClick={sendToLink} css={style} className={"nav-link"}>
                {children}
            </div>
        ) : (
            <div className={"nav-link"}>{children}</div>
        )
    );
}

export default NavLinkTag;
