/**
 * Helper function to be used in conjunction with React.forwardRef,
 * to support the ref arg being a function or an object.
 * @param theRef
 * @param refReceiver
 */
export default function passForwardRef(theRef: any, refReceiver: any) {
    if (typeof refReceiver === "function") {
        refReceiver(theRef);
    } else if (refReceiver) {
        refReceiver.current = theRef;
    }
}
