import { useAppState } from "../contexts/AppContext";
import { useLazyQuery } from "@apollo/react-hooks";
import { useEffect } from "react";
import { gql } from "apollo-boost";

export const TRIAL_DAYS = 30;

export type GetStripePlan = {
    getStripePlans: StripePlan[],
};

export type StripePlan = {
    id: string,
    Currency: string,
    Interval: string,
    NiceCurrency: string,
    Tiers: StripeTier[],
};

export type StripeTier = {
    id: string,
    UnitAmount: number,
    UpTo: number|null,
};

const useStripePlans = () => {
    const [ appState, setAppState ] = useAppState();
    const [ runQuery, { data } ] = useLazyQuery<GetStripePlan>(GET_PLANS);

    const countryCode = appState.country.code;

    // Wait for appState to have a country code before executing query based on country code.
    useEffect(
        () => {
            if (countryCode !== "") {
                runQuery();
            }
        },
        [countryCode, runQuery]
    );

    useEffect(
        () => {
            if (data) {
                const sortedData = data.getStripePlans.sort(plan => plan.Interval === 'month' ? -1 : 1);
                setAppState({ plans: sortedData });
            }
        },
        [data, setAppState]
    );

    return appState.plans;
};

const GET_PLANS = gql`
    query getStripePlans {
        getStripePlans {
            id
            Currency
            NiceCurrency
            Interval
            Tiers {
                id
                UnitAmount
                UpTo
            }
        }
    }
`;

export default useStripePlans;
