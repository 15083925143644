import React from 'react';
import { Helmet } from "react-helmet";
import PageContainer from "../../core/components/PageContainer";
import TermsAndConditionsContent from "./components/TermsAndConditionsContent";
import { getSiteTitle } from "../../config/config";

const TermsAndConditions: React.FC = () => (
    <PageContainer>
        <Helmet>
            <title>{getSiteTitle('Terms and Conditions')}</title>
        </Helmet>
        <TermsAndConditionsContent />
    </PageContainer>
);

export default TermsAndConditions;
