import colours from './colours';
import borderAndShadow from './borderAndShadow';
import { fonts } from './fonts';
import { css } from '@emotion/core';
import "../../node_modules/tippy.js/dist/tippy.css";
import "../../node_modules/tippy.js/animations/scale-subtle.css";

const tippy = {
    whiteTheme: css`
        .tippy-white-theme {
            background: ${colours.white};
            box-shadow: ${borderAndShadow.boxShadow};
            color: ${colours.poloBlue2};
            font-weight: ${fonts.weights.light};
        
            &[data-placement^=bottom] .tippy-arrow {
                border-bottom-color: ${colours.white};
            }
        
            &[data-placement^=top] .tippy-arrow {
                border-top-color: ${colours.white};
            }
        }
    `
};

export default tippy;
