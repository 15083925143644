import React from "react";
import { css } from "@emotion/core";

import theme from "../../../theme";

type Props = {
    children: React.ReactNode,
};

const BurgerNavWrapper: React.FC<Props> = ({
    children,
}) => {
    const style = css`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: ${theme.sizes.menu.burgerHeightMain}px;

        ${theme.breakpoints.only("lg")} {
            // 6px: Button div padding
            padding-right: calc(${theme.sizes.menu.sideMargin}px - 6px);
        }

        ${theme.breakpoints.down("lg")} {
            justify-content: space-between;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default BurgerNavWrapper;
