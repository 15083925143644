import React, { useCallback, useState } from "react";
import { css } from "@emotion/core";
import OutsideClickHandler from "react-outside-click-handler";

import ProfileIcon from "../icons/ProfileIcon";
import theme, { Theme } from "../../../theme";
import CorporateProfileContextMenu from "./CorporateProfileContextMenu";

type UserData = {
    socialAvatarUrl: string,
    firstName: string,
    surname: string,
    email: string,
};

type Props = {
    userData: UserData,
    isInHeaderMenu?: boolean
};

const CorporateProfile: React.FC<Props> = ({ userData, isInHeaderMenu = false }) => {
    const [isOpenContext, setIsOpenContext] = useState(false);

    const toggleSortingMenu = useCallback((e, forceClose = false) => {
        if (!forceClose) return setIsOpenContext(!isOpenContext);
        setTimeout(() => isOpenContext && setIsOpenContext(false), 100);
    }, [isOpenContext]);

    const onOutsideClick = useCallback((e: any) => toggleSortingMenu(e, true), [toggleSortingMenu]);

    return (
        <div css={profileContainerStyle(isInHeaderMenu)}>
            <div css={userProfileStyle(isInHeaderMenu)}>
                {userData.email && (
                    <OutsideClickHandler display={"flex"} onOutsideClick={onOutsideClick}>
                        <div css={userProfileContentStyle(isOpenContext, isInHeaderMenu)} onClick={toggleSortingMenu}>
                            <React.Fragment>
                                {userData.socialAvatarUrl
                                    ? (<img css={profileIconStyle} src={userData.socialAvatarUrl} alt="" />)
                                    : (<ProfileIcon css={profileIconStyle} />)
                                }
                                <div css={textWithChevronWrapperStyle}>
                                    {isOpenContext && (
                                        <CorporateProfileContextMenu />
                                    )}
                                </div>
                            </React.Fragment>
                        </div>
                    </OutsideClickHandler>
                )}
            </div>
        </div>
    );
}

const profileContainerStyle = (isInHeaderMenu: boolean) => (theme: Theme) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: ${theme.zIndex.zIndexOne};

    ${!isInHeaderMenu && css`
        position: absolute;
        height: 80px;
        width: auto;
    `}
`;

const userProfileStyle = (isInHeaderMenu: boolean) => (theme: Theme) => css`
    display: flex;
    z-index: ${theme.zIndex.zIndexLow};
    justify-content: center;
    align-items: center;
    font-family: ${theme.fonts.frutiger};

    ${!isInHeaderMenu && css`
        padding: 0 20px;
        width: 165px;
    `};
`;

const profileIconStyle = css`
    width: 43px;
    height: 43px;

    ${theme.breakpoints.only("xxl")} {
        width: 40px;
        height: 40px;
    }

    ${theme.breakpoints.only("xl")} {
        width: 37px;
        height: 37px;
    }
`;

const userProfileContentStyle = (isOpenContext: boolean, isInHeaderMenu: boolean) => (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    position: relative;
    height: 35px;
    transition: color ${theme.transitions.transition};

    ${isInHeaderMenu && css`
        flex-direction: row-reverse;
    `};
`;

const textWithChevronWrapperStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        margin-top: 3px;
    }
`;

export default CorporateProfile;
