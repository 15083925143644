// Colours - as per http://chir.ag/projects/name-that-color
// Useful alternative Colours https://www.color-blindness.com/color-name-hue

const red = {
    50: '#fff2f2',
    100: '#ffcdd2',
    200: '#ef9a9a',
    300: '#e57373',
    350: '#ff174f',
    400: '#ef5350',
    500: '#f44336',
    600: '#e53935',
    700: '#d32f2f',
    800: '#c62828',
    900: '#b71c1c',
    999: '#ff0000',
};

const green = {
    100: '#C8E6C9',
    200: '#A5D6A7',
    300: '#81C784',
    400: '#66BB6A',
    500: '#4CAF50',
    600: '#43A047',
    700: '#388E3C',
    800: '#2E7D32',
    900: '#1B5E20',
    950: '#145819',
};

const grey = {
    50: '#fafafa',
    80: '#F3F3F3',
    90: '#F4F4F4',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    450: '#AAAAAA',
    500: '#9e9e9e',
    600: '#757575',
    650: '#707070',
    700: '#757575',
    720: '#555555',
    800: '#424242',
    900: '#212121',
    920: '#1B1D28',
};

const blue = {
    50: '#E3F2FD',
    100: '#AAD4EF',
    400: '#1D8BDB',
    450: '#3B528F',
    500: '#05AEF0',
    550: '#5670AB',
    560: '#0076FF',
    600: '#156CBC',
    610: '#0869B2',
    620: '#0964A7',
    700: '#0C4A93',
    900: '#364D9B',
    930: '#222247',
    950: '#3C3E44',
};

const colours = {
    borderColor: grey[300],
    primary: green[900],
    secondary: red[500],
    blue,
    green,
    grey,
    red,
    alabaster: '#F8F8F8',
    alto: '#D0D0D0',
    atlantis: '#70BA27',
    aquaIsland: '#AED6DF',
    aquamarineBlue: '#66cae2',
    azureRadiance: '#0188E6',
    azureRadiance2: '#1778F2',
    bahamaBlue: '#0964A7',
    black: '#040404',
    blueChill: '#0FACA6',
    blueMarguerite: '#6559CA',
    brinkPink: '#FC747A',
    buccaneer: '#6f3842',
    buttercup: '#F7B41B',
    bonJour: '#E0DCDC',
    candyCorn2: '#FCE359',
    catskillWhite: '#F5F7FA',
    cerise: '#D9317A',
    chablis: '#fff0f0',
    chateauGreen: '#37B44E',
    carnation: '#F15154',
    ceriseRed: '#E33F5F',
    cinnabar2: '#E23D44',
    comet: '#5F647E',
    concrete: '#F2F2F2',
    concrete2: '#F3F2F2',
    cornflower: '#98b6e4',
    cornflowerBlue: '#6195ED',
    cream: '#FFFFCE',
    crimson: '#ED1C24',
    crimson2: '#E7161B',
    crusta: '#F77638',
    curiousBlue: '#1D8BDB',
    curiousBlue2: '#27A9E0',
    deepCerulean2: '#066AB2',
    denim: '#156CBC',
    denim2: '#117AC6',
    dodgerBlue: '#2086FC',
    doveGray: '#707070',
    doveGray2: '#666666',
    drover: '#FCEEA3',
    dullLavender: '#938de8',
    edward: '#A2ABAC',
    edward2: '#ACB4B5',
    emerald: '#63C973',
    emerald3: '#3ECB54',
    eucalyptus: '#2eb24a',
    frenchGray2: '#BDBCC6',
    frenchRose: '#F2406A',
    fountainBlue: '#65bec7',
    fuchsiaBlue: '#893DC2',
    gallery: '#EEEDED',
    gold: '#ffd40c',
    gray: '#909090',
    harlequin: '#37CE00',
    havelockBlue: '#649EDD',
    hawkesBlue: '#d2e2fe',
    heliotrope: '#8D5AFF',
    iron2: '#CCCDCE',
    inchWorm: '#A3ED3C',
    indigo: '#5258CF',
    jaffa: '#f2a039',
    killarney: '#3C763D',
    koromiko: '#FEC564',
    koromiko2: '#FEC66D',
    lightGrey: '#EFEFEF',
    limeade2: '#82A800',
    lochmara: '#0984DA',
    lochmara3: '#017DD8',
    malibu: '#86DEF5',
    malibu2: '#68BAF9',
    mantis: '#68C879',
    marigoldYellow: '#f9d676',
    mediumRedViolet: '#BC318F',
    mineShaft3: '#3B3B3B',
    mischka2: '#D5D4DB',
    mySin: '#FDB92A',
    naturalGrey: '#888786',
    nightRider: '#080404',
    pattensBlue2: '#D9E6FF',
    persimmon: '#FF5A5A',
    pictonBlue: '#39CCEF',
    pinkSalmon: '#FF9BB3',
    poloBlue2: '#8DA8D6',
    quartz: '#C6D3ED',
    raspberry: '#E30B5D',
    rockBlue2: '#97B1C9',
    rhino: '#343465',
    sanMarino: '#5c75b2',
    santasGray: "#9D9CA7",
    sail: '#A0D4F5',
    scienceBlue: '#005BD6',
    scooter: '#2AB4D3',
    selectiveYellow2: '#FAB700',
    shipCove: '#7692C7',
    silverChalice: '#9E9E9E',
    silverChalice2: '#A3A3A3',
    sinbad: '#9DCBD1',
    soapStone: '#FFF9F7',
    solitude: '#e3effe',
    spindle5: '#A9C0EB',
    steelBlue: '#4A81BE',
    sun: '#FCAF17',
    sundance: '#cbc761',
    sunglow: '#FFD033',
    sushi: '#8DC63F',
    toreaBay: '#0C4A93',
    tundora: '#4A4A4A',
    toryBlue: '#0E519D',
    transparent: 'rgba(0,0,0,0)', // Useful for transitions between no fill and fill.,
    valencia: '#D23E44',
    viking: '#61B1E3',
    viola2: '#D090D0',
    wellRead: '#B53533',
    white: '#FFFFFF',
    wildWaterMelon: '#ff487b',
    yellowGreen: '#CCE16F',
    yellowOrange: '#FBB040',
};

const writingStrength = {
    bad: colours.crimson,
    low: colours.brinkPink,
    average: colours.sun,
    strong: colours.inchWorm,
    excellent: colours.eucalyptus,
};

const status = {
    success: colours.emerald3,
    error: colours.cinnabar2,
};

/**
 * Convert a hexadecimal integer to a decimal integer.
 *
 * @param {string} hex Hexadecimal integer.
 * @returns {number} Decimal integer.
 */
const hexToInt = (hex: string) => parseInt(parseInt(hex, 16).toString(10));

/**
 * Get an rgba colour from a given 6-digit hex colour and an alpha value (defaults to 1).
 *
 * @param {string} hex 6-digit hex rgb colour.
 * @param {number} alpha Alpha channel value.
 * @returns {string} rgba colour.
 */
const rgba = (hex: string, alpha = 1) => {
    if (!hex || hex.length !== 7) {
        throw Error(`Hex colour must have length of 7, got ${hex}`);
    }

    const rgb = [hex.substring(1, 3), hex.substring(3, 5), hex.substring(5, 7)];

    return `rgba(${hexToInt(rgb[0])}, ${hexToInt(rgb[1])}, ${hexToInt(rgb[2])}, ${alpha})`;
};

const utils = {
    hexToInt,
    rgba,
};


export default {
    ...colours,
    status,
    utils,
    writingStrength,
};
