import React from 'react';
import { css, SerializedStyles } from '@emotion/core';

import { Theme } from '../../../theme';
import TextWithTmConverted from "../../text-with-tm-converted/TextWithTmConverted";

type Props = {
    title: string,
    colour?: string,
    className?: string,
    hasBar?: boolean,
    extraStyles?: SerializedStyles,
};

const HeaderContentTitle: React.FC<Props> = ({
     title,
     colour,
     hasBar = true,
     className,
     extraStyles,
 }) => (
    <div css={containerStyle} className={className}>
        {hasBar && <div css={lineStyle(colour)}/>}
        <h1 css={titleStyle(colour, hasBar, extraStyles)}>
            <TextWithTmConverted text={title} />
        </h1>
    </div>
);

const containerStyle = css`
    position: relative;
    display: flex;
    flex-direction: row;
`;

const titleStyle = (colour?: string, hasBar?: boolean, extraStyles?: SerializedStyles) => (theme: Theme) => css`
    line-height: 48px;
    color: ${colour};
    font-family: ${theme.fonts.frutiger};
    font-weight: ${theme.fonts.weights.bold};
    letter-spacing: -0.92px;
    white-space: pre-line;
    font-size: 46px;

    ${hasBar && css`
        padding-left: 40px;

        ${theme.breakpoints.down('sm')} {
            padding-left: 36px;
        }
    `};

    ${theme.breakpoints.down('lg') } {
        font-size: 40px;
        line-height: 42px;
    }

    ${theme.breakpoints.down('xs') } {
        font-size: 31px;
        line-height: 33px;
    }

    ${theme.breakpoints.down('xxs')} {
        width: 290px;
    }

    ${extraStyles};
`;

const lineStyle = (colour?: string) => (theme: Theme) => css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: calc(${14000/144}% - 11px);
    background-color: ${colour};

    ${theme.breakpoints.up('xl')} {
        width: 20px;
    }

    ${theme.breakpoints.down('lg')} {
        width: 16px;
    }
`;

export default HeaderContentTitle;
