import React, { useCallback, useEffect, useRef, useState } from 'react';
import { css, SerializedStyles } from '@emotion/core';

import DropDownList from "./DropDownList";
import { Theme } from "../../../theme";
import DropdownChevron from "./DropdownChevron";

export type DropdownOption = {
    id: string,
    value: string,
}

type Props = {
    className?: string,
    extraChevronStyles?: SerializedStyles,
    extraOptionContainerStyles?: SerializedStyles,
    extraOptionStyles?: SerializedStyles,
    extraSelectedStyles?: SerializedStyles,
    hasBorder?: boolean,
    hasError?: boolean,
    options?: Array<DropdownOption>,
    placeholder?: string,
    extraPlaceholderStyles?: SerializedStyles,
    readOnly?: boolean,
    searchable: boolean,
    selected?: DropdownOption,
    setSelected?: Function
};

const DropDown: React.FC<Props> = ({
    className,
    extraChevronStyles,
    extraOptionContainerStyles,
    extraOptionStyles,
    extraSelectedStyles,
    hasBorder,
    hasError,
    options,
    placeholder,
    extraPlaceholderStyles,
    readOnly,
    searchable,
    selected,
    setSelected,
}) => {
    const [dropped, setDropped] = useState(false);
    const dropdownRef = useRef<HTMLInputElement>(null);

    const toggle = useCallback(()=> {
        if (readOnly) return;
        setDropped(dropped => !dropped);
    }, [setDropped, readOnly]);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropped(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div css={containerStyle} ref={dropdownRef} className={className}>
            <div css={selectedContainerStyle(hasBorder, hasError, readOnly)} onClick={toggle}>
                {selected
                    ? (<div css={selectedItemStyle(extraSelectedStyles)}>{selected.value}</div>)
                    : (<div css={placeholderStyle(extraPlaceholderStyles)}>{placeholder && placeholder}</div>)
                }
                {!readOnly && (
                    <>
                        <DropdownChevron dropped={dropped} hasError={hasError} css={dropdownChevronStyle(extraChevronStyles)} />
                    </>
                )}
            </div>
            {dropped && (
                <DropDownList
                    extraOptionContainerStyles={extraOptionContainerStyles}
                    extraOptionStyles={extraOptionStyles}
                    selected={selected}
                    setSelected={setSelected}
                    setDropped={setDropped}
                    options={options}
                    searchable={searchable}
                />
            )}
        </div>
    );
};

DropDown.defaultProps = {
    hasError: false,
    hasBorder: true,
    readOnly: false,
    options: [],
    setSelected: () => {},
}

const containerStyle = css`
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: 100%;
`;

const selectedContainerStyle = (hasBorder?: boolean, hasError?: boolean, readOnly?: boolean) => (theme: Theme) => css`
    flex-grow: 1;
    display: flex;
    position: relative;
    border: 1px solid ${hasBorder ? theme.forms.defaults.borderColor : theme.colours.transparent};
    border-radius: 3px;
    justify-content: space-between;

    ${hasError && theme.forms.error};

    ${!readOnly && css`
        :hover {
            cursor: pointer;
        }
    `};
`;

const selectedItemStyle = (extraSelectedStyles?: SerializedStyles) => css`
    margin: auto 0 auto 10px;
    font-size: 100%;

    ${extraSelectedStyles};
`;

const placeholderStyle = (extraPlaceholderStyles?: SerializedStyles) => (theme: Theme) => css`
    margin: auto 0 auto 10px;
    font-size: 100%;

    ${theme.mixins.placeholderAttributes()};

    ${extraPlaceholderStyles};
`;

const dropdownChevronStyle = (extraChevronStyles?: SerializedStyles) => (theme: Theme) => css`
    right: 15px;

    ${extraChevronStyles};
`;

export default DropDown;
