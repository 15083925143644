import React from "react";
import { css } from "@emotion/core";

import theme from "../../../../theme";
import Image from "../../Image";
import wtbLogo from "../../../../images/wtb-2-lines-blue-v1.png";
import FooterTopMenuBox from "./FooterTopMenuBox";

type Props = {
    footerTopMenu: [any] | undefined,
    onMoveToHome: (e: React.MouseEvent) => void,
};

const FooterTopLeftSection:React.FC<Props> = ({ footerTopMenu, onMoveToHome }) => {
    const style = css`
        display: flex;
        align-items: end;
        margin-top: 50px;
        margin-bottom: 5px;
        width: 100%;

        ${theme.breakpoints.down('md')} {
            justify-content: space-between;
            margin-bottom: 22px;
        }

        ${theme.breakpoints.down('xs')} {
            flex-direction: column;
            gap: 12px;
            align-items: start;
        }
    `;

    const imageStyle = css`
        width: 126px;
        height: unset;

        ${theme.breakpoints.down("md")} {
            margin-bottom: 10px;
        }
    `;

    const menuStyle = css`
        ${theme.breakpoints.up("sm")} {
            margin-left: 45px;
        }
    `;

    return (
        <div css={style}>
            <Image image={wtbLogo} onClickHandler={onMoveToHome} css={imageStyle} />
            <div css={menuStyle}>
                <FooterTopMenuBox footerTopMenu={footerTopMenu} />
            </div>
        </div>
    );
};

export default FooterTopLeftSection;
