/**
 * Converts seconds to a time string e.g. 300s => 5:00
 *
 * @param {number} time
 * @returns {string}
 */
export const secondsToTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
};

/**
 * Shortens large numbers by adding m or k suffixes.
 *
 * @param {number} value
 * @param {number} decimals
 *
 * @returns {string}
 */
export const shortenLargeNumber = (value) => {
    let d = 0;
    let suffix = '';
    let decimals = 0;

    if (value > 1000000) {
        d = 1000000;
        suffix = 'm';
        decimals = 1;
    } else if (value > 10000) {
       d = 1000;
       suffix = 'k';
       decimals = 0;
    } else if (value > 1000) {
        d = 1000;
        suffix = 'k';
        decimals = 1;
    }

    return d ? `${(value/d).toFixed(decimals)}${suffix}` : value;
}

/**
 * Return the current URLSegment
 */
export const getURLSegment = (path) => {
    const segments = getURLSegments(path);

    if (segments[segments.length - 1] === '') {
        return 'home';
    }

    return segments[segments.length - 1];
};

/**
 * Return the URLSegments including the current URLSegment
 * @param path
 */
export const getURLSegments = (path) => {
    let strSegments = path.startsWith('/') ? path.substring(1) : path;
    strSegments = strSegments.endsWith('/') ? strSegments.substring(0, strSegments.length - 1) : strSegments;
    return strSegments.split('/');
}

/**
 * Return the URLSegments including the current URLSegment
 * @param path
 */
export const getURLSegmentsString = (path) => {
    let strSegments = path.startsWith('/') ? path.substring(1) : path;
    return strSegments.endsWith('/') ? strSegments.substring(0, strSegments.length - 1) : strSegments;
}

/**
 * The formatter for the all menu of Footer
 * @param allFooterMenu
 */
export const getFooterMenu = (allFooterMenu) => {
    const resultMenu = {
        menu: [],
        side: [],
        bottom: [],
        all: allFooterMenu,
    };

    allFooterMenu.forEach((menu) => {
        // Set footer main menu
        if (menu.showInFooter === 'menu') {
            // Root level (level-1) menu
            if (menu.parentID === 0) {
                let id = menu.id.toString();
                resultMenu.menu.push({ ...{ [id]: [menu] } });
            } else {
                resultMenu.menu.forEach((rootMenu) => {
                    // Set into parent menu array
                    if (rootMenu[menu.parentID]) {
                        rootMenu[menu.parentID].push({ ...menu });

                        // Set into the root menu array of its menu category
                    } else {
                        const parentMenu = allFooterMenu.find((m) => parseInt(m.id) === menu.parentID);
                        if (parentMenu && rootMenu[parentMenu.parentID]) {
                            // Get the last index number and count of the same category
                            const index = rootMenu[parentMenu.parentID].findLastIndex((m) => parseInt(m.id) === menu.parentID);
                            const subCount = rootMenu[parentMenu.parentID].filter((m) => m.parentID === menu.parentID).length;

                            // Set position and insert menu
                            rootMenu[parentMenu.parentID].splice(index + 1 + subCount, 0, { ...menu });
                        }
                    }
                });
            }

            // Set footer side menu
        } else if (menu.showInFooter === 'side') {
            resultMenu.side.push(menu);

            // Set footer bottom text link
        } else {
            resultMenu.bottom.push(menu);
        }
    });

    return resultMenu;
};

export default {
    secondsToTime,
    shortenLargeNumber,
    getURLSegment,
    getURLSegments,
    getURLSegmentsString,
    getFooterMenu,
};
