import React from "react";
import { css } from "@emotion/core";
import { v4 as uuidv4 } from "uuid";
import theme from "../../../theme";

type Props = {
    colour?: string,
    backgroundColour?: string,
    className?: string,
};

const ProfileIcon: React.FC<Props> = ({
    colour,
    backgroundColour,
    className = "",
}) => {
    const uniqueID = uuidv4();
    const personColour = colour ? colour : theme.colours.white;
    const circleColour = backgroundColour ? backgroundColour : `url(#profile${uniqueID})`;

    const style = css`
        display: flex;
        justify-content: center;

        > svg {
            width: 43px;
            height: 43px;
        }

        ${!backgroundColour && css`
            &:hover {
                circle:first-of-type {
                    fill: url(${`#profile-hover${uniqueID}`});
                }
            }
        `}
    `;

    return (
        <div css={style} className={className}>
            <svg id={`uuid-6ebaa667-89ee-4d41-8017-e6b4a6be1719${uniqueID}`} viewBox="0 0 1080 1080">
                <defs>
                    <linearGradient id={`profile${uniqueID}`} x1="2077.7" y1="-3475.26" x2="2089.85" y2="-3485.31" gradientTransform="translate(-88914 -148984) scale(43 -43)" gradientUnits="userSpaceOnUse">
                        <stop offset="0" stopColor="#1d8bdb"/>
                        <stop offset="1" stopColor="#69b7f4"/>
                    </linearGradient>
                    <linearGradient id={`profile-hover${uniqueID}`} gradientTransform="matrix(43 0 0 -43 -42714 -148984)" gradientUnits="userSpaceOnUse" x1="1002.77" x2="1015.83" y1="-3474.88" y2="-3484.83">
                        <stop offset="0" stopColor="#95d2ff"/>
                        <stop offset="1" stopColor="#4aaaf5"/>
                    </linearGradient>
                    <clipPath id={`clip${uniqueID}`}>
                        <circle cx="538.49" cy="544.4" r="427.81" fill={`${circleColour}`}/>
                    </clipPath>
                </defs>
                <g id={`uuid-f003a890-6c0d-4b89-bd23-98d0940dfb28${uniqueID}`}>
                    <circle id={`background${uniqueID}`} cx="538.49" cy="544.4" r="525.6" fill={`${circleColour}`}/>
                    <circle id={`head${uniqueID}`} cx="538.49" cy="422.17" r="134.45" fill={`${personColour}`}/>
                    <g clipPath={`url(#clip${uniqueID})`}>
                        <g id={`uuid-a23887dd-5662-4201-89f8-813379a8b877${uniqueID}`}>
                            <ellipse id={`body${uniqueID}`} cx="538.49" cy="911.1" rx="256.69" ry="305.58" fill={`${personColour}`}/>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default ProfileIcon;
