import React, { useCallback, useMemo } from "react";
import { css } from "@emotion/core";
import { useHistory } from "react-router-dom";

import theme, { Theme } from "../../../theme";
import { useAppState } from "../../contexts/AppContext";
import { HomeUser } from "../utils/UserSession";
import { sendCorporate } from "../../includes/navigate";
import RoundTextButton from "../buttons/RoundTextButton";
import useBreakpoint from "../../hooks/useBreakpoint";
import { isOnly } from "../../../theme/breakpoints";
import { logout } from "../../includes/auth";

const CorporateProfileContextMenu: React.FC = () => {
    const history = useHistory();
    const [appState] = useAppState();
    const { breakpoint, minWidth } = useBreakpoint();
    const buttonHeight = 42;

    const [position, top, right] = useMemo(() => {
        switch (true) {
            case isOnly(breakpoint, 'lg'):
                return ['fixed', theme.sizes.menu.heightMain - 2, theme.sizes.menu.sideMargin - 5];
            default:
                return ['absolute', theme.sizes.menu.heightMain - 12, -5];
        }
    }, [breakpoint]);

    const buttonWidth = useMemo(() => {
        switch (true) {
            case minWidth >= theme.breakpoints.sizes["3xl"]:
                return 253;
            default:
                return 211;
        }
    }, [minWidth]);

    const user: HomeUser = appState?.user;

    const onLogoutClick = useCallback(() => {
        logout().then(() => {
            appState.clearUser();
            sendCorporate("");
        })
    }, [appState]);

    const onAccountClick = useCallback(() => {
        history.push("/manage");
    }, [history]);

    const stopEvent = useCallback((e: any) => e.stopPropagation(), []);

    const profileContextMenuStyle = (theme: Theme) => css`
        position: ${position};
        top: ${top}px;
        right: ${right}px;
        box-shadow: inset ${theme.borderAndShadow.dropShadow};
        cursor: default;
        border-radius: 0 0 23px 23px;
        z-index: ${theme.zIndex.zIndexHigher};
        background-color: ${theme.colours.concrete};
        padding: 13px;

        ${theme.breakpoints.up("3xl")} {
            width: 282px;
        }

        ${theme.breakpoints.between("xl", "xxl")} {
            width: 241px;
        }
    `;

    return (
        <div css={profileContextMenuStyle}>
            <div css={profileContextMenuContentStyle}>
                <div css={contextMenuHeaderStyle} onClick={stopEvent}>
                    <div css={nameStyle}>{user.FirstName} {user.Surname}</div>
                    <div css={emailStyle}>{user.Email}</div>
                </div>
                {user?.Subscription && (
                    <RoundTextButton
                        onClick={onAccountClick}
                        width={buttonWidth}
                        height={buttonHeight}
                        backgroundColour={theme.colours.frenchRose}
                        backgroundHoverColour={theme.colours.pinkSalmon}
                        useBoxShadow={false}
                        css={buttonStyle}
                    >
                        Account
                    </RoundTextButton>
                )}
                <RoundTextButton
                    onClick={onLogoutClick}
                    width={buttonWidth}
                    height={buttonHeight}
                    backgroundColour={theme.colours.curiousBlue}
                    useBoxShadow={false}
                    css={buttonStyle}
                >
                    Log out
                </RoundTextButton>
            </div>
        </div>
    )
}

const profileContextMenuContentStyle = css`
    overflow: hidden;
    border-radius: 3px;
`;

const contextMenuHeaderStyle = css`
    padding-left: 10px;
`;

const buttonStyle = css`
    padding: 0;

    &:first-of-type {
        margin-bottom: 10px;
    }
`;

const emailStyle = (theme: Theme) => css`
    font-size: 14px;
    line-height: 17px;
    margin: 5px 0 28px;
    ${theme.utils.oneLineEllipsis};
`;

const nameStyle = (theme: Theme) => css`
    font-size: 30px;
    line-height: 37px;
    color: ${theme.colours.curiousBlue};
    font-weight: ${theme.fonts.weights.bold};
    ${theme.utils.oneLineEllipsis};

    ${theme.breakpoints.only("xxl")} {
        font-size: 28px;
        line-height: 34px;
    }
`;

export default CorporateProfileContextMenu;
