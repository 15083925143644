import React, { memo, useCallback } from 'react';
import { css } from "@emotion/core";

import { Theme } from "../../../theme";

type Props = {
    className?: string,
    hasError?: boolean,
    label: string,
    id?: string,
    name: string,
    onChange: (name: string, value: boolean) => void,
    value: boolean,
};

const SimpleCheckbox: React.FC<Props> = ({className, hasError = false, label, id, name, onChange, value}) => {
    const onClick = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

        onChange(name, !value);
    }, [name, onChange, value]);

    const onKeyDown = useCallback((e) => {
        if (e.keyCode === 32) {
            onClick(e);
        }
    }, [onClick]);

    return (
        <label id={id || name} onClick={onClick} className={className} css={checkboxContainerStyle(value)} onKeyDown={onKeyDown} tabIndex={-1}>
            <div css={checkboxStyle(hasError)}>
                {value && <span css={markStyle} />}
            </div>
            <span css={labelStyle}>{label}</span>
        </label>
    );
};

const checkboxContainerStyle = (selected: boolean) => (theme: Theme) => css`
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    outline: none;

    &:hover > div {
        ${!selected && `background-color: ${theme.colours.grey[100]}`};
        border-color: ${theme.colours.blue[400]};
    }
`;

const checkboxStyle = (hasError: boolean) => (theme: Theme) => css`
    width :20px;
    height: 20px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    background-color: ${theme.colours.white};
    border: 1px solid ${theme.colours.utils.rgba(theme.colours.grey[650], 0.5)};
    transition: border-color ${theme.transitions.transitionFaster}, background-color ${theme.transitions.transitionFaster};

    ${hasError && css`
        border-color: ${theme.colours.crimson};
    `};
`;

const labelStyle = css`
    margin-left: 10px;
    width: calc(100% - 20px);
    line-height: 20px;
`;

const markStyle = (theme: Theme) => css`
    height: 20px;
    width: 20px;
    background-color: ${theme.colours.white};

    &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -60%) rotate(45deg);
        width: 5px;
        height: 12px;
        border: solid ${theme.colours.atlantis};
        border-width: 0 2px 2px 0;
    }
`;

export default memo(SimpleCheckbox);
