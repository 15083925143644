import React from "react";
import { sendCorporate } from "../../../includes/navigate";

type Props = {
    data: StandardPage,
    className?: string,
};

const FooterTopMenu: React.FC<Props> = ({ data, className = "" }) => {

    const sendToLink = (link: string) => {
        sendCorporate(link);
    };

    return (
        <div className={className} onClick={()=>{sendToLink(data.uRLSegment)}}>
            {data.titleForFooter ?? data.title}
        </div>
    );
}

export default FooterTopMenu;
