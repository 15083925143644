import React from 'react';
import { css, SerializedStyles } from '@emotion/core';
import {Theme} from "../../../theme";

type Props = {
    children: React.ReactNode,
    backgroundColour?: string,
    extraStyles?: SerializedStyles,
    extraContainerStyles?: SerializedStyles,
    backgroundImageCss?: SerializedStyles,
    extraFullWidthStyles?: SerializedStyles,
    isHeader?: boolean,
};

const ContentContainer: React.FC<Props> = ({ children, backgroundColour, extraContainerStyles, extraStyles, backgroundImageCss, extraFullWidthStyles, isHeader= false }) => (
    <div css={backgroundColourStyle(extraContainerStyles, backgroundColour, isHeader)}>
        { extraFullWidthStyles &&
            <div css={fullWidthStyle(extraFullWidthStyles)} />
        }
        <div css={style(backgroundColour, extraStyles, backgroundImageCss)}>
            { children }
        </div>
    </div>
);

const backgroundColourStyle = (extraStyles?: SerializedStyles, backgroundColour?: string, isHeader?: boolean) => (theme: Theme) => css`
    background-color: ${backgroundColour};
    position: relative;

    ${theme.breakpoints.up('xxl')} {
        height: ${isHeader ? '610' : '720'}px;
    }

    ${theme.breakpoints.only('xl')} {
        height: ${isHeader ? '550' : '640'}px;
    }

    ${theme.breakpoints.only('lg')} {
        height: ${isHeader ? '452' : '512'}px;
    }

    ${theme.breakpoints.only('md')} {
        height: ${isHeader ? '964' : '1024'}px;
    }

    ${theme.breakpoints.only('sm')} {
        height: ${isHeader ? '900' : '960'}px;
    }

    ${theme.breakpoints.down('xs')} {
        height: ${isHeader ? '607' : '667'}px;
    }

    ${extraStyles}
`;

const fullWidthStyle = (extraStyles?: SerializedStyles) => css`
    height: 100%;
    width: 100%;
    position: absolute;

    ${extraStyles}
`;

const style = (backgroundColour?: string, extraStyles?: SerializedStyles, backgroundImageCss?: SerializedStyles) => (theme: Theme) => css`
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    height: 100%;

    ${theme.breakpoints.up('xxl')} {
        max-width: 1440px;
    }

    ${theme.breakpoints.only('xl')} {
        max-width: 1280px;
    }

    ${theme.breakpoints.only('lg')} {
        max-width: 1024px;
    }

    ${theme.breakpoints.only('md')} {
        max-width: 768px;
    }

    ${theme.breakpoints.only('sm')} {
        max-width: 568px;
    }

    ${theme.breakpoints.down('xs')} {
        max-width: 368px;
    }

    ${extraStyles};

    ${backgroundImageCss};
`;

export default ContentContainer;
