import React from 'react';
import { css } from '@emotion/core';

import { Theme } from "../../../theme";
import { PromotionCode } from "../../../config/graphQLTypes";
import pluralize from "pluralize";

type Props = {
    billingStartDate: string,
    discountPriceByInterval: number | null,
    discountTotalPrice: string | null,
    numberOfUsers: string,
    period: string,
    planType: string,
    priceByInterval: number,
    promotionCode: PromotionCode | null,
    totalPrice: string,
};

const OrderSummarySubscription: React.FC<Props> = ({
    billingStartDate,
    discountPriceByInterval,
    discountTotalPrice,
    numberOfUsers,
    period,
    planType,
    priceByInterval,
    promotionCode,
    totalPrice,
}) => {
    const isDiscounted = promotionCode !== null;

    return (
        <div css={containerStyle}>
            <div css={subscriptionStyle(isDiscounted)}>
                <div>{planType} Subscription</div>
                <div>${totalPrice}</div>
            </div>
            {isDiscounted && (
                <div css={subscriptionStyle(false)}>
                    <div>{promotionCode?.Coupon?.PercentOff}% off {promotionCode?.Coupon?.Term}</div>
                    <div>${discountTotalPrice}</div>
                </div>
            )}
            <div css={usersStyle}>
                <div css={numberOfUsersStyle}>{pluralize('User', parseInt(numberOfUsers), true)}</div>
                <div>{period}</div>
            </div>
            <div css={perUserStyle(isDiscounted)}>${priceByInterval.toFixed(2)} per user</div>
            {isDiscounted && (
                <div css={perUserStyle(false)}>${discountPriceByInterval?.toFixed(2)} per user</div>
            )}
            <div css={intervalStyle}>Starts next month: {billingStartDate}</div>
        </div>
    );
};

const containerStyle = (theme: Theme) => css`
    padding: 21px 19px 21px 22px;
    font-weight: ${theme.fonts.weights.light};
`;

const subscriptionStyle = (isDiscounted: boolean) => (theme: Theme) => css`
    min-height: 19px;
    line-height: 17px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: ${theme.fonts.weights.base};

    ${isDiscounted && css`
        div:last-child {
            text-decoration: line-through;
        }
    `};
`;

const usersStyle = css`
    height: 19px;
    line-height: 17px;
    display: flex;
    justify-content: space-between;
`;

const numberOfUsersStyle = (theme: Theme) => css`
    color: ${theme.colours.grey['650']};
`;

const perUserStyle = (isDiscounted: boolean) => (theme: Theme) => css`
    height: 19px;
    line-height: 17px;
    color: ${theme.colours.grey['650']};

    ${isDiscounted && css`
        text-decoration: line-through;
    `};
`;

const intervalStyle = (theme: Theme) => css`
    height: 19px;
    line-height: 17px;
    color: ${theme.colours.grey['650']};
    letter-spacing: -0.07px;
`;

export default OrderSummarySubscription;
