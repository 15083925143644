import React from 'react';
import { css } from '@emotion/core';
import { Theme } from '../../../theme';
import TwoColumn from "./TwoColumn";
import SimpleCheckbox from "./SimpleCheckbox";

export type SimpleCheckboxField = {
    label: string,
    name: string,
    value: boolean,
};

type Props = {
    fields: Array<SimpleCheckboxField>,
    hasError: boolean,
    groupName: string,
    id?: string,
    isTwoColumn?: boolean,
    onFieldChange: (name: string, value: any) => void,
};

const SimpleCheckboxGroup: React.FC<Props> = ({
    fields,
    groupName,
    id,
    hasError,
    isTwoColumn = false,
    onFieldChange
}) => {
    const onChange = (name: string, value: boolean) => {
        const newFields = [...fields];

        const changedIndex = newFields.findIndex(field => field.name === name);

        if (changedIndex >= 0) {
            newFields[changedIndex].value = value;
        }

        onFieldChange(groupName, fields);
    };

    const checkboxes = fields.map((field, index) => (
        <SimpleCheckbox
            css={checkboxInput}
            hasError={hasError}
            key={index}
            label={field.label}
            id={id}
            name={field.name}
            onChange={onChange}
            value={field.value}
        />
    ));

    if (isTwoColumn) {
        return (
            <TwoColumn>
                { checkboxes }
            </TwoColumn>
        );
    }

    return (
        <>
            { checkboxes }
        </>
    );
};

const checkboxInput = (theme: Theme) => css`
    margin-top: 0;
    box-sizing: border-box;
    width: 100%;
    font-size: ${theme.forms.label.fontSize};
    height: 47px;
`;

export default SimpleCheckboxGroup;
