const menu = {
    heightMain: 58,
    heightMainUnderLine: 8,
    heightSub: 315,
    paddingLeftOftSub: 430,
    paddingRightOfSub: 300,
    burgerHeightMain: 55,
    breadcrumbHeight: 27,
    breadcrumbBurgerHeight: 35,
    breadcrumbBurgerSmallHeight: 26,
    sideMargin: 44,
}

const sizes = {
    padding: '20px',
    smallRadius: '3px',
    smallRadiusBottom: '0 0 3px 3px',
    smallRadiusTop: '3px 3px 0 0',
    topHeaderHeight: '30px',
    pageBodyMinHeight: '770px',
    pageBodyMarginBottom: '30px',
    menu,
};

export default sizes;
