import React, { useMemo } from "react";
import { css } from "@emotion/core";

import theme from "../../../theme";
import Collapse from "../Collapse";
import useBreakpoint from "../../hooks/useBreakpoint";
import { isBetween } from "../../../theme/breakpoints";

type Props = {
    children: React.ReactNode,
    showBurgerMenu: boolean,
};

const BurgerNavMainContainer: React.FC<Props> = ({ children, showBurgerMenu }) => {
    const { breakpoint } = useBreakpoint();
    const headerHeight = useMemo(() => {
        switch (true) {
            case isBetween(breakpoint, 'sm', 'lg'):
                return theme.sizes.menu.burgerHeightMain + theme.sizes.menu.breadcrumbBurgerHeight;
            default:
                return theme.sizes.menu.breadcrumbBurgerSmallHeight + theme.sizes.menu.burgerHeightMain + theme.sizes.menu.breadcrumbBurgerHeight;
        }
    }, [breakpoint]);

    const style = css`
        display: flex;
        width: 100%;
        height: calc(100vh - ${headerHeight}px);
    `;

    return (
        <Collapse isOpen={showBurgerMenu}>
            <div css={style}>
                {children}
            </div>
        </Collapse>
    );
};

export default BurgerNavMainContainer;
