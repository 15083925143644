import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

type ListType = "whitelist" | "blacklist" | undefined

const useCountries = (type?: ListType) => {
    const { data } = useQuery(GET_COUNTRIES, {
        variables: {
            ...type && { type }
        }
    })
    return data
}

const GET_COUNTRIES = gql`
    query getCountries($type: String) {
        countries: getCountries(type: $type) {
            id
            CountryCode
            Title
        }
    }
`

export default useCountries
