import React from "react";

import { sendCorporate } from "../../../includes/navigate";
import FooterBottomSection from "./FooterBottomSection";
import FooterContainer from "./FooterContainer";
import FooterCopyright from "./FooterCopyright";
import FooterDivideLine from "./FooterDivideLine";
import FooterMainSection from "./FooterMainSection";
import FooterRow from "./FooterRow";
import FooterTopLeftSection from "./FooterTopLeftSection";
import FooterTopRightSection from "./FooterTopRightSection";

import useBreakpoint from "../../../hooks/useBreakpoint";
import SectionContainer from "../../layouts/SectionContainer";

import { isUp, isDown } from "../../../../theme/breakpoints";

type Props = {
    footerMenu?: { menu: [any], side: [any], bottom: [any] },
};

const Footer: React.FC<Props> = ({ footerMenu }) => {
    const { breakpoint } = useBreakpoint();

    // Logo link to home
    const onMoveToHome = () => {
        sendCorporate("");
    };

    return (
        <SectionContainer overflowHidden>
            <FooterContainer>
                <FooterDivideLine position={"top"} />

                {isDown(breakpoint, "md") && (
                    <FooterRow>
                        <FooterTopLeftSection footerTopMenu={footerMenu?.side} onMoveToHome={onMoveToHome} />
                    </FooterRow>
                )}

                {/* Header menu section */}
                <FooterRow removePaddingForVerticalMenu>
                    <FooterMainSection footerMenu={footerMenu?.menu} />
                </FooterRow>

                {isDown(breakpoint, "md") && (
                    <FooterRow>
                        <FooterBottomSection footerBottomMenu={footerMenu?.bottom} />
                    </FooterRow>
                )}

                {/* Logo, links and social icons */}
                <FooterRow>
                    {isUp(breakpoint, "lg") && (
                        <FooterTopLeftSection footerTopMenu={footerMenu?.side} onMoveToHome={onMoveToHome} />
                    )}
                    <FooterTopRightSection />
                </FooterRow>

                <FooterDivideLine position={"bottom"} />

                {/* Bottom links */}
                {isUp(breakpoint, "lg") && (
                    <FooterRow>
                        <FooterBottomSection footerBottomMenu={footerMenu?.bottom} />
                    </FooterRow>
                )}

                {/* Copyright */}
                <FooterRow>
                    <FooterCopyright />
                </FooterRow>
            </FooterContainer>
        </SectionContainer>
    );
};

export default Footer;
