import React from "react";
import { css } from "@emotion/core";
import theme from "../../../theme";

type Props = {
    children: React.ReactNode,
    className?: string,
    overflowHidden?: boolean;
};

const SectionContainer: React.FC<Props> = ({
    children,
    className = "",
    overflowHidden,
}) => {

    const style = css`
        width: 100%;
        min-width: ${theme.breakpoints.sizes.xs}px;
        display: flex;
        justify-content: center;
        position: relative;

        ${overflowHidden && css`
            overflow: hidden;
        `};
    `;

    return (
        <div css={style} className={className}>
            {children}
        </div>
    );
};

export default SectionContainer;
