import React from "react";
import { css } from "@emotion/core";

import theme from "../../../../theme";
import BurgerNavSmallHeaderMenuChevron from "./BurgerNavSmallHeaderMenuChevron";
import BurgerNavSmallHeaderMenuTitle from "./BurgerNavSmallHeaderMenuTitle";
import { sendCorporate } from "../../../includes/navigate";

type Props = {
    headerMenu: StandardPage,
    setSubMenu: Function,
    hasSubMenu: boolean,
    tabbedMenu: boolean,
    currentURLSegment?: string,
    closeMenuHandler: Function,
};

const BurgerNavSmallHeaderMenuBox: React.FC<Props> = ({
    headerMenu,
    setSubMenu,
    hasSubMenu,
    tabbedMenu,
    currentURLSegment = '',
    closeMenuHandler,
}) => {
    const borderBottomColour = !hasSubMenu || !tabbedMenu ? theme.colours.viking : "transparent";

    const onClickMenuHandler = () => {
        if (hasSubMenu) {
            setSubMenu({ headerMenu, keepHeader: false });
        } else if (headerMenu?.isClickable) {
            sendCorporate(headerMenu?.uRLSegment);
            closeMenuHandler();
        }
    };

    const style = css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 65px;
        cursor: pointer;
        border-bottom: 2px solid ${borderBottomColour};

        ${currentURLSegment === headerMenu.uRLSegment && css`
            opacity: 0.7;
        `}

        ${theme.breakpoints.only("md")} {
            height: 84px;
        }

        ${theme.breakpoints.only("sm")} {
            height: 75px;
        }

        ${theme.breakpoints.down("xs")} {
            height: 80px;
        }

        ${headerMenu.isClickable && css`
            &:hover {
                > div {
                    color: ${theme.colours.gold};
                }
            }
        `}
    `;

    return (
        <div css={style} onClick={onClickMenuHandler}>
            <BurgerNavSmallHeaderMenuTitle headerMenu={headerMenu} closeMenuHandler={closeMenuHandler}>
                {headerMenu.title}
            </BurgerNavSmallHeaderMenuTitle>

            {hasSubMenu && (
                <BurgerNavSmallHeaderMenuChevron tabbedMenu={tabbedMenu} />
            )}
        </div>
    );
};

export default BurgerNavSmallHeaderMenuBox;
