import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { css } from '@emotion/core';
import { Redirect, useHistory } from "react-router";
import { useMutation } from "@apollo/react-hooks";
import gql from 'graphql-tag';
import { useTheme } from "emotion-theming";
import EmailValidator from 'email-validator';

import { Theme } from '../../../theme';
import { PromotionCode } from "../../../config/graphQLTypes";
import useForm, { FieldList } from "../../../core/hooks/useForm";
import Form from "../../../core/components/form/Form";
import LabelledField from "../../../core/components/form/LabelledField";
import Input from "../../../core/components/form/Input";
import FormActions from "../../../core/components/form/FormActions";
import Button from "../../../core/components/buttons/Button";
import * as storage from "../../../core/includes/localStorage";
import { useAppState } from "../../../core/contexts/AppContext";
import { AUTH_URL, MANAGE_URL } from "../../../core/includes/pricingAuthRedirects";
import errorParser from "../../../core/includes/errorParser";
import Loading from "../../../core/components/utils/Loading";
import { StripePlan } from "../../../core/hooks/useStripePlans";
import useSetState from "../../../core/hooks/useSetState";
import { getNiceTotalPriceFromTier } from "../../../core/includes/stripePlansHelpers";
import TwoColumn from "../../../core/components/form/TwoColumn";
import useCountries from "../../../core/hooks/useCountries";
import useOnce from "../../../core/hooks/useOnce";
import { isValidPhoneNumber } from "../../../core/includes/phoneNumber";
import { isValidPostalCode } from "../../../core/includes/postalCode";
import SearchableList from "../../../core/components/form/SearchableList";
import Countries from "../../../core/components/country-dropdown/Countries";
import { PAYMENT_INTENT_STATUS_SUCCEEDED, REQUIRES_ACTIONS } from "../../../core/includes/constants";
import PhoneNumberInput from "../../../core/components/phone-number-input/PhoneNumberInput";
import { countryToPhoneCode } from "../../../theme/utils";
import usePhoneNumberInput from "../../../core/hooks/usePhoneNumberInput";
import SimpleCheckboxGroup, { SimpleCheckboxField } from "../../../core/components/form/SimpleCheckboxGroup";
import { OTHER, COUNTRIES_WITH_REGION_OPTIONS } from "../../../core/includes/dropdowns";
import useRedirectHomeIfOnRestrictedPage from "../../../core/hooks/useRedirectHomeIfOnRestrictedPage";

type Props = {
    numberOfUsers: string,
    plan: StripePlan | null,
    promotionCode: PromotionCode | null,
}

type DefaultState = {
    isSubmitting: boolean,
    errorMessage: string | null,
    isVisibleErrorMessage: boolean,
}

const INITIAL_STATE = {
    isSubmitting: false,
    errorMessage: null,
    isVisibleErrorMessage: false,
}

const Payment: React.FC<Props> = ({ numberOfUsers, plan, promotionCode }) => {
    const theme = useTheme<Theme>();
    const history = useHistory();
    const [appState,] = useAppState();
    const [state, setState] = useSetState<DefaultState>(INITIAL_STATE);
    const phoneInputRef = useRef<HTMLInputElement>(null);
    const otherReasonInputRef = useRef<HTMLInputElement>(null);
    const otherStateInputRef = useRef<HTMLInputElement>(null);
    useRedirectHomeIfOnRestrictedPage();

    const stripe: any = useStripe();
    const elements: any = useElements();

    const validator: any = (fields: FieldList, isSubmit: boolean = false) => {
        const errors: ErrorsType = {};

        if (!fields.firstName) {
            errors.firstName = 'Please provide your first name';
        }

        if (!fields.surname) {
            errors.surname = 'Please provide your last name';
        }

        if (!fields.email) {
            errors.email = 'Please provide your email address';
        }

        if (isSubmit && fields.email && !EmailValidator.validate(fields.email)) {
            errors.email = 'Please provide a valid email address';
        }

        if (!fields.phone && !isPhoneCodeSet) {
            errors.phone = 'Please provide a phone number'
        } else if (isSubmit && !isValidPhoneNumber(countryToPhoneCode(fields.phoneCode) + fields.phone)) {
            errors.phone = 'Please provide a valid phone number'
        }

        if (!fields.address) {
            errors.address = 'Please provide an address';
        }

        if (!fields.city) {
            errors.city = 'Please provide a city';
        }

        if (!fields.state) {
            errors.state = 'Please provide a state/region';
        }

        if (isSubmit && isStateSelectionOther && !fields.otherState) {
            errors.state = " ";
            errors.otherState = 'Please provide a state/region';
        }

        if (!fields.country) {
            errors.country = 'Please provide a country';
        }

        if (!fields.postCode) {
            errors.postCode = 'Please provide a post code';
        } else if (isSubmit && fields.country && !isValidPostalCode(fields.country, fields.postCode)) {
            errors.postCode = 'Please provide a valid post code';
        }

        if (!fields.howDidYouHearAboutUs) {
            errors.howDidYouHearAboutUs = 'Please let us know how you heard about us';
        }

        if (fields.writingChallengeGroup) {
            const checkboxesChecked = fields.writingChallengeGroup.reduce(
                (a: number, b: SimpleCheckboxField) => a + (b.value ? 1 : 0),
                0
            );

            if (checkboxesChecked === 0) {
                errors.writingChallengeGroup = "Please select at least one option below."
            }

            if (checkboxesChecked > 3) {
                errors.writingChallengeGroup = "Please only select a maximum of 3 options.";
            }
        }

        if (isSubmit && shouldShowOtherReasonInput && !fields.otherReason) {
            errors.howDidYouHearAboutUs = " ";
            errors.otherReason = 'Please let us know how you heard about us';
        }

        if (isSubmit && fields.country) {
            const country = Countries.find(country => country['code'] === fields.country);
            const isNotValidCountry = country === undefined;
            if (isNotValidCountry)
                errors.country = 'Please provide a valid country';
        }

        return errors;
    };

    const countriesData = useCountries();
    const [createSubscriptionData] = useMutation(CREATE_USER_SUBSCRIPTION);
    const [completeSubscriptionData] = useMutation(COMPLETE_SUBSCRIPTION);

    const [fields, errors, onFieldChange, onReset, validate] = useForm({
        fields: {
            firstName: appState?.user?.FirstName,
            surname: appState?.user?.Surname,
            email: appState?.user?.Email,
            phoneCode: appState?.country?.codeFromIP,
            state: "",
            otherState: "",
            howDidYouHearAboutUs: "",
            otherReason: "",
            writingChallengeGroup: [
                ...WRITING_CHALLENGE_GROUP
            ],
        },
        validator,
        extraErrorFields: { errorMessage: state.errorMessage && state.isVisibleErrorMessage },
    });

    const setFormData = useOnce(onReset);

    const isPhoneCodeSet = usePhoneNumberInput(fields.phone, fields.phoneCode, onFieldChange, appState?.country?.codeFromIP);

    useEffect(() => {
        if (appState?.user) {
            setFormData();
        }
    }, [appState, setFormData, fields.phoneCode]);

    const isHowDidYouHearAboutUsSelectionOther = fields.howDidYouHearAboutUs === OTHER.value;
    const isSelectionWordOfMouth = fields.howDidYouHearAboutUs === "Word of Mouth (please specify)";
    const isStateSelectionOther = fields.state === OTHER.value;
    const shouldShowOtherReasonInput = isHowDidYouHearAboutUsSelectionOther || isSelectionWordOfMouth;

    useEffect(() => {
        if (shouldShowOtherReasonInput) {
            otherReasonInputRef?.current?.focus();
        }
    }, [shouldShowOtherReasonInput]);

    useEffect(() => {
        if (isStateSelectionOther) {
            otherStateInputRef?.current?.focus();
        }
    }, [isStateSelectionOther]);

    const totalPrice = useMemo(() => {
        if (!plan) return "";
        return getNiceTotalPriceFromTier(plan, numberOfUsers);
    }, [plan, numberOfUsers]);

    const onCountryFieldChange = useCallback((name: string, value: string) => {
        onFieldChange(name, value, "", "state");
    }, [onFieldChange]);

    const getStateError = useCallback(() => {
        if (isStateSelectionOther) {
            return !!errors.state && !!errors.otherState;
        }

        return !!errors.state;
    }, [isStateSelectionOther, errors.state, errors.otherState]);

    const getHowDidYouHearAboutUsError = useCallback(() => {
        if (shouldShowOtherReasonInput) {
            return !!errors.howDidYouHearAboutUs && !!errors.otherReason;
        }

        return !!errors.howDidYouHearAboutUs;
    }, [shouldShowOtherReasonInput, errors.howDidYouHearAboutUs, errors.otherReason]);

    const cardElementOptions = useMemo(() => {
        return {
            style: {
                base: {
                    padding: 40,
                    lineHeight: '20px',
                    fontSize: '16px',
                    fontSmoothing: 'antialiased',
                    fontFamily: theme.fonts.frutiger,
                    '::placeholder': {
                        color: theme.colours.grey[500],
                    },
                }
            }
        };
    }, [theme]);

    const goToPaymentSuccessPage = useCallback(() => {
        appState.refetchUser().then(() => {
            history.push('/pricing/payment-successful');
        });
    }, [appState, history]);

    const cardElementErrorEffect = useCallback(() => {
        setState({ errorMessage: '', isVisibleErrorMessage: false });
    }, [setState]);

    const getState = useCallback(() => {
        return isStateSelectionOther ? fields.otherState : fields.state;
    }, [isStateSelectionOther, fields.state, fields.otherState]);

    const getHowDidYouHearAboutUs = useCallback(() => {
        return shouldShowOtherReasonInput ? fields.howDidYouHearAboutUs.replace("please specify", fields.otherReason) : fields.howDidYouHearAboutUs;
    }, [shouldShowOtherReasonInput, fields.howDidYouHearAboutUs, fields.otherReason]);

    if (appState.hasUserBeenLoaded && appState?.user?.Subscription) {
        return <Redirect from="*" to={`${MANAGE_URL}/subscription-details`} />;
    }

    const createSubscription = (paymentMethodId: string) => {
        const fieldValues: any = {};
        fields.writingChallengeGroup.forEach((field: SimpleCheckboxField) => fieldValues[field.name] = field.value);

        createSubscriptionData({
            variables: {
                input: {
                    paymentMethod: paymentMethodId,
                    price: plan?.id || "",
                    quantity: Number(numberOfUsers),
                    phone: countryToPhoneCode(fields.phoneCode) + fields.phone,
                    city: fields.city,
                    country: fields.country,
                    howDidYouHearAboutUs: getHowDidYouHearAboutUs(),
                    ...fieldValues,
                    addressLine: fields.address,
                    state: getState(),
                    postCode: fields.postCode,
                    promotionCodeID: promotionCode?.id,
                }
            }
        }).then((response) => {
            const paymentIntent = JSON.parse(response?.data?.createHomeUserSubscription?.PaymentIntent);

            if (REQUIRES_ACTIONS.includes(paymentIntent?.status)) {
                confirmCardPaymentIfRequired(paymentIntent.client_secret);
            } else {
                goToPaymentSuccessPage();
            }
        }).catch((error) => {
            setState({ errorMessage: errorParser(error), isVisibleErrorMessage: true, isSubmitting: false });
        });
    };

    const confirmCardPaymentIfRequired = (clientSecret: string) => {
        stripe
            .confirmCardPayment(clientSecret)
            .then(function (result: any) {
                // Handle result.error or result.paymentIntent
                if (result.paymentIntent && result.paymentIntent.status === PAYMENT_INTENT_STATUS_SUCCEEDED) {
                    setState({ isSubmitting: true });
                    completeSubscriptionData()
                        .then(goToPaymentSuccessPage)
                        .catch((error) => {
                            setState({
                                errorMessage: errorParser(error),
                                isVisibleErrorMessage: true,
                                isSubmitting: false
                            });
                        });
                } else {
                    // https://stripe.com/docs/api/errors
                    setState({
                        errorMessage: result?.error?.message ?? "An error occurred while processing your card. Please try again.",
                        isVisibleErrorMessage: true,
                        isSubmitting: false
                    });
                }
            });
    };

    const paymentSubmit = async () => {
        if (!stripe || !elements) {
            return;
        }

        if (appState.hasUserBeenLoaded && !appState?.authorized) {
            return <Redirect from="*" to={AUTH_URL} />;
        }

        setState({
            errorMessage: '',
            isVisibleErrorMessage: false,
            isSubmitting: true,
        });

        const cardElement: any = elements.getElement(CardElement);

        const { error: cardTokenError } = await stripe.createToken(cardElement);

        if (cardTokenError) {
            setState({
                errorMessage: cardTokenError.message,
                isVisibleErrorMessage: true,
                isSubmitting: false
            });
            return;
        }

        // Temporarily commented out until we fix this flow.
        // if (appState?.country?.code !== cardToken?.card?.country) {
        //     setState({
        //         errorMessage: "Country of your credit card must match the country of this transaction.",
        //         isVisibleErrorMessage: true,
        //         isSubmitting: false
        //     });
        //     return;
        // }

        const latestInvoicePaymentIntentStatus = storage.retrieve('latestInvoicePaymentIntentStatus');
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: fields.firstName + ' ' + fields.surname,
                email: fields.email,
                phone: countryToPhoneCode(fields.phoneCode) + fields.phone,
                address: {
                    city: fields.city,
                    country: fields.country,
                    line1: fields.address,
                    state: getState(),
                },
            },
        });

        if (error) {
            validate();
            setState({
                errorMessage: error.errorMessage,
                isVisibleErrorMessage: error.errorMessage !== "Invalid email address.",
                isSubmitting: false
            });
            return;
        }

        if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
            setState({ isSubmitting: false });
        } else if (!validate()) {
            setState({ isSubmitting: false });
        } else {
            createSubscription(paymentMethod.id);
        }
    };

    return (
        <div css={paymentContent}>
            {!appState.hasUserBeenLoaded && <Loading onTop overlay overlayColour={"transparent"} />}
            <Form onSubmit={paymentSubmit}>
                <div css={paymentHeader}>
                    <span>Payment Method</span>
                </div>
                <div css={checkoutFormBlock}>
                    <div css={state.isVisibleErrorMessage ? checkoutFormError : checkoutForm}>
                        <CardElement options={cardElementOptions} onChange={cardElementErrorEffect} />
                    </div>
                </div>
                <div id={"errorMessage"} css={paymentValidationError}>
                    {state.isVisibleErrorMessage && <span>{state.errorMessage}</span>}
                </div>
                <div css={paymentHeader}>
                    <span>Billing Contact</span>
                </div>
                <div css={billingContactContent}>
                    <TwoColumn>
                        <LabelledField name="firstName" error={errors.firstName} label="First Name">
                            <Input
                                hasError={!!errors.firstName}
                                css={nameInput}
                                label="First name"
                                name="firstName"
                                onChange={onFieldChange}
                                value={fields.firstName}
                            />
                        </LabelledField>
                        <LabelledField name="surname" error={errors.surname} label="Last Name">
                            <Input
                                hasError={!!errors.surname}
                                css={surnameInput}
                                label="Last name"
                                name="surname"
                                onChange={onFieldChange}
                                value={fields.surname}
                            />
                        </LabelledField>
                    </TwoColumn>
                    <LabelledField name="email" error={errors.email} label="Email">
                        <Input
                            hasError={!!errors.email}
                            css={textInput}
                            label="Email address"
                            name="email"
                            onChange={onFieldChange}
                            value={fields.email}
                        />
                    </LabelledField>
                    <LabelledField name="phone" error={errors.phone} label="Phone">
                        {countriesData &&
                            <PhoneNumberInput
                                countries={countriesData.countries}
                                hasError={!!errors.phone}
                                onChange={onFieldChange}
                                phoneNumber={fields.phone}
                                phoneCode={fields.phoneCode}
                                phoneInputRef={phoneInputRef}
                            />
                        }
                    </LabelledField>
                    <LabelledField name="address" error={errors.address} label="Address">
                        <Input
                            hasError={!!errors.address}
                            css={addressInput}
                            label="Address"
                            name="address"
                            onChange={onFieldChange}
                            value={fields.address}
                        />
                    </LabelledField>
                    <TwoColumn>
                        <LabelledField name="city" error={errors.city} label="City">
                            <Input
                                hasError={!!errors.city}
                                css={nameInput}
                                label="City"
                                name="city"
                                onChange={onFieldChange}
                                value={fields.city}
                            />
                        </LabelledField>
                        <LabelledField name="country" error={errors.country} label="Country">
                            {countriesData && (
                                <SearchableList
                                    name="country"
                                    options={countriesData.countries}
                                    value={fields.country}
                                    onChange={onCountryFieldChange}
                                    labelField="Title"
                                    valueField="CountryCode"
                                    placeholder="Select country"
                                    hasError={!!errors.country}
                                    variant={'country'}
                                    clearable
                                />
                            )}
                            {!countriesData && (
                                <Input
                                    hasError={!!errors.country}
                                    css={textInput}
                                    label="Country"
                                    name="country"
                                    onChange={onFieldChange}
                                    value={fields.country}
                                />
                            )}
                        </LabelledField>
                    </TwoColumn>
                    <TwoColumn>
                        <LabelledField name="state" error={errors.state} label="State/Region">
                            {COUNTRIES_WITH_REGION_OPTIONS[fields?.country] ? (
                                    <SearchableList
                                        css={dropdownStyles}
                                        name="state"
                                        options={COUNTRIES_WITH_REGION_OPTIONS[fields?.country]}
                                        value={fields.state}
                                        onChange={onFieldChange}
                                        labelField="value"
                                        valueField="value"
                                        placeholder="Select state/region"
                                        hasError={getStateError()}
                                        disableSearch
                                    />
                                )
                                : (
                                    <Input
                                        hasError={!!errors.state}
                                        css={nameInput}
                                        label="State/Province"
                                        name="state"
                                        onChange={onFieldChange}
                                        value={fields.state}
                                    />
                                )
                            }
                        </LabelledField>
                        <LabelledField name="postCode" error={errors.postCode} label="Post Code">
                            <Input
                                hasError={!!errors.postCode}
                                css={textInput}
                                label="Post Code"
                                name="postCode"
                                onChange={onFieldChange}
                                value={fields.postCode}
                            />
                        </LabelledField>
                    </TwoColumn>
                    {isStateSelectionOther && (
                        <LabelledField name="state" error={errors.otherState} label="">
                            <Input
                                hasError={!!errors.otherState && !!errors.state}
                                css={nameInput}
                                label="State/Province"
                                name="otherState"
                                placeholder="Let us know your state/region"
                                onChange={onFieldChange}
                                value={fields.otherState}
                                innerRef={otherStateInputRef}
                            />
                        </LabelledField>
                    )}
                    <LabelledField name="howDidYouHearAboutUs" error={errors.howDidYouHearAboutUs} label="How did you hear about Writer's Toolbox?">
                        <SearchableList
                            name="howDidYouHearAboutUs"
                            hasError={getHowDidYouHearAboutUsError()}
                            options={HOW_DID_YOU_HEAR_ABOUT_US_OPTIONS}
                            css={howDidYouHearAboutUsDropdownStyles}
                            placeholder="Select an option"
                            value={fields.howDidYouHearAboutUs}
                            onChange={onFieldChange}
                            labelField="value"
                            valueField="value"
                            disableSearch
                        />
                    </LabelledField>
                    {shouldShowOtherReasonInput && (
                        <LabelledField name="otherReason" error={!!errors.howDidYouHearAboutUs && errors.otherReason} label="Please fill in the box below:">
                            <Input
                                name="otherReason"
                                hasError={!!errors.otherReason && !!errors.howDidYouHearAboutUs}
                                css={textInput}
                                value={fields.otherReason}
                                onChange={onFieldChange}
                                placeholder={isSelectionWordOfMouth ? "E.g. Family/Friend/Principal/Teacher recommended" : "Let us know how you heard about Writer's Toolbox"}
                                innerRef={otherReasonInputRef}
                                maxLength={254}
                            />
                        </LabelledField>
                    )}
                    <LabelledField name="writingChallengeGroup"  error={errors.writingChallengeGroup} css={writingChallengeField}>
                        <div css={textInput}>What is your child's main writing challenge?* (Select up to 3)</div>
                    </LabelledField>
                    <LabelledField name="writingChallengesCheckboxes">
                        <SimpleCheckboxGroup
                            id={"writingChallengeGroup"}
                            groupName={"writingChallengeGroup"}
                            fields={fields.writingChallengeGroup}
                            hasError={!!errors.writingChallengeGroup}
                            onFieldChange={onFieldChange}
                            isTwoColumn={true}
                        />
                    </LabelledField>
                </div>
                <div css={formActions}>
                    <FormActions>
                        {totalPrice && (
                            <Button
                                css={formSubmitButton}
                                type="submit"
                                color="secondary"
                                forceLoading={state.isSubmitting}
                            >
                                Sign up
                            </Button>
                        )}
                    </FormActions>
                </div>
            </Form>
        </div>
    );
};

const HOW_DID_YOU_HEAR_ABOUT_US_OPTIONS = [
    { value: "The Courier-Mail" },
    { value: "Facebook/Instagram" },
    { value: "LinkedIn" },
    { value: "Twitter" },
    { value: "Google" },
    { value: "Word of Mouth (please specify)" },
    OTHER,
];

const WRITING_CHALLENGE_GROUP = [
    {
        label: 'Struggles to get started',
        name: 'getStarted',
        value: false,
    },
    {
        label: 'Lacks confidence',
        name: 'confidence',
        value: false,
    },
    {
        label: 'Has a learning difficulty',
        name: 'learning',
        value: false,
    },
    {
        label: 'Struggles with English language',
        name: 'english',
        value: false,
    },
    {
        label: 'Overwrites / underwrites',
        name: 'writing',
        value: false,
    },
    {
        label: 'Needs extending (already a strong writer)',
        name: 'extending',
        value: false,
    },
    {
        label: 'Other',
        name: 'challengeOther',
        value: false,
    },
];

const CREATE_USER_SUBSCRIPTION = gql`
    mutation createHomeUserSubscription($input: HomeUserInput!) {
        createHomeUserSubscription(input: $input) {
            id
            CurrentPeriodEnd
            PaymentIntent
        }
    }
`;

const COMPLETE_SUBSCRIPTION = gql`
    mutation completeSubscription {
        completeSubscription {
            id
        }
    }
`;

type ErrorsType = {
    firstName?: string,
    surname?: string,
    email?: string,
    phone?: string,
    address?: string,
    city?: string,
    state?: string,
    otherState?: string,
    country?: string,
    postCode?: string,
    howDidYouHearAboutUs?: string,
    wordOfMouth?: string,
    otherReason?: string,
    writingChallengeGroup?: string,
}

const paymentValidationError = (theme: Theme) => css`
    display: flex;
    min-height: 20px;
    line-height: 14px;
    top: 100%;
    font-size: 12px;
    color: ${theme.colours.crimson};
`;

const checkoutFormBlock = css`
    width: 100%;
    margin-bottom: 8px;
`;

const checkoutForm = (theme: Theme) => css`
    border: 1px solid ${theme.colours.grey['450']};
    border-radius: 3px;
    margin-top: 10px;
    padding: 14px;
    background: transparent;
    outline: none;
`;

const checkoutFormError = (theme: Theme) => css`
    ${checkoutForm(theme)};
    border: 1px solid ${theme.colours.crimson};
    background-color: ${theme.colours.soapStone};
`;

const paymentContent = (theme: Theme) => css`
    width: 510px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    ${theme.breakpoints.down('md')} {
        width: 100%;
        max-width: 510px;
        margin: 0 auto;
    }
`;

const billingContactContent = css`
    margin-top: 12px;
    margin-bottom: 44px;
    width: 100%;
`;

const nameInput = (theme: Theme) => css`
    margin-top: 0;
    box-sizing: border-box;
    width: 244px;
    font-size: ${theme.forms.label.fontSize};

    ${theme.breakpoints.down('sm')} {
        width: 100%;
    }
`;

const surnameInput = (theme: Theme) => css`
    margin-top: 0;
    box-sizing: border-box;
    width: 245px;
    font-size: ${theme.forms.label.fontSize};

    ${theme.breakpoints.down('sm')} {
        width: 100%;
    }
`;

const textInput = (theme: Theme) => css`
    margin-top: 0;
    box-sizing: border-box;
    width: 100%;
    font-size: ${theme.forms.label.fontSize};
`;

const addressInput = (theme: Theme) => css`
    margin-top: 0;
    box-sizing: border-box;
    width: 100%;
    font-size: ${theme.forms.label.fontSize};
`;

const paymentHeader = (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${theme.colours.grey['90']};
    width: 510px;
    height: 50px;

    span {
        font-size: 26px;
        margin-left: 20px;
        font-weight: 400;
    }

    ${theme.breakpoints.down('md')} {
        width: 100%;
        max-width: 510px;
    }
`;

const formActions = (theme: Theme) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    ${theme.breakpoints.down('md')} {
        justify-content: center;
    }
`;

const formSubmitButton = (theme: Theme) => css`
    background-color: ${theme.colours.blue['400']};
    width: 124px;

    ${theme.breakpoints.up('lg')} {
        margin-bottom: 55px;
    }

    ${theme.breakpoints.down('md')} {
        margin-top: 20px;
    }
`;

const dropdownStyles = css`
    cursor: pointer;

    > div > ul {
        max-height: unset;

        > li {
            padding-left: 14px;
        }
    }
`;

const howDidYouHearAboutUsDropdownStyles = css`
    ${dropdownStyles}

    > div > ul {
        max-height: 261px;
        overflow: hidden;
    }
`;

const writingChallengeField = css`
    margin-bottom: 9px;
`;

export default Payment;
