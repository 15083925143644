import React from 'react';
import { css } from '@emotion/core';
import { TRIAL_DAYS } from "../../../core/hooks/useStripePlans";

import { Theme } from "../../../theme";

const OrderSummaryCharge: React.FC = () => (
    <div css={containerStyle}>
        <div css={chargeStyle}>
            <div>Today's Charge</div>
            <div css={priceStyle}>$0.00</div>
        </div>
        <div css={guaranteeStyle}>
            <div>{TRIAL_DAYS}-day free trial</div>
        </div>
    </div>
);

const containerStyle = (theme: Theme) => css`
    height: 78px;
    line-height: 18px;
    padding: 21px 19px 21px 22px;
    font-weight: ${theme.fonts.weights.base};
`;

const chargeStyle = css`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const guaranteeStyle = (theme: Theme) => css`
    font-size: 12px;
    font-weight: ${theme.fonts.weights.light};
    color: ${theme.colours.grey['650']};
`;

const priceStyle = css`
    font-size: 18px;
`;

export default OrderSummaryCharge;
