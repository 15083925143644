import React from "react";
import { css } from "@emotion/core";

import theme from "../../../../theme";
import { NOOP } from "../../../../config/constants";

type Props = {
    children: React.ReactNode,
    isHeaderMenu?: boolean,
    isTextBold?: boolean,
    colour: string,
    showUnderline: boolean,
    clickHandler?: () => void,
};
const BurgerNavSubTextBoxContainer: React.FC<Props> = ({
    children,
    isHeaderMenu = false,
    isTextBold = false,
    colour,
    showUnderline,
    clickHandler = NOOP,
}) => {
    const style = css`
        margin-top: 20px;
        width: fit-content;
        font-size: 20px;
        line-height: 1.5;
        color: ${colour};

        ${!isHeaderMenu && css`
            margin-bottom: 8px;
        `}

        ${isTextBold && css`
            font-weight: ${theme.fonts.weights.bold};
        `}

        ${showUnderline && css`
            .nav-menu-underline {
                background-color: ${theme.colours.cornflowerBlue};
            }
        `}

        &:hover {
            color: ${theme.colours.curiousBlue};
        }
    `;
    return (
        <div css={style} onClick={clickHandler}>
            {children}
        </div>
    );
};
export default BurgerNavSubTextBoxContainer;
