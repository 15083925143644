import React from "react";
import theme from "../../../theme";

type Props = {
    className?: string,
    colour?: string,
};

const Cart: React.FC<Props> = ({
    className = "",
    colour = theme.colours.curiousBlue,
}) => (

    <div className={className}>
        <svg viewBox="0 0 1080 1080">
            <path
                id="uuid-5e4a60e9-3fbb-4d7d-a870-119a4e5847b7"
                d="m708.05,701.52H167.52c-18.82,2.37-35.99-10.96-38.36-29.77-.02-.17-.04-.35-.06-.52-26-109.58-51.78-219.24-77.34-328.99-4.49-16.08-8-32.41-10.53-48.91-2.46-17,9.33-32.77,26.32-35.23,1.74-.25,3.5-.36,5.26-.31,4.44-.31,8.62,0,12.93,0h732.88c8.04-32.12,16.17-63.92,23.9-95.86,1.12-19.79,18.08-34.92,37.87-33.8,1.12.06,2.24.18,3.35.35h139.53c15.99-1.71,31.04,7.85,36.29,23.06,5.3,16.59-3.86,34.34-20.46,39.64-3.61,1.15-7.39,1.64-11.17,1.45-39.62.36-79.25.4-118.87,0-6.39-1.22-12.55,2.97-13.77,9.35-.08.43-.14.87-.17,1.31-30.27,123.11-60.71,246.25-91.33,369.42-21.74,87.75-43.52,175.5-65.35,263.25-5.6,22.21-14.57,28.83-37.4,28.87h-21.37c19.76,12.15,31.39,34.04,30.38,57.22-1.03,16.13-8.69,31.11-21.15,41.4-24.3,20.91-60.81,18.77-82.49-4.84-25.41-26.96-21.59-59.08,11.64-93.02h-324.28c32.74,32.12,36.52,67.79,10.66,94.18-23.23,23.58-61.17,23.86-84.75.64-.26-.25-.51-.51-.77-.77-26.08-26.65-22.21-61.48,12.04-94.93h-53.75c-15.79,1.39-29.71-10.29-31.1-26.08-.15-1.67-.15-3.35,0-5.02-.75-16.45,11.98-30.38,28.43-31.13,1.1-.05,2.2-.04,3.29.03,163.65.62,327.3.27,490.96.58,10.57,0,15.99-2.18,18.39-13.33,6.13-28.87,13.68-57.44,21.06-87.82m-34.34-382.03l-15.64,126.03h113.54l31.1-126.12-129,.09Zm-558.12,0c9.73,41.18,19.01,80.76,28.7,120.21.62,2.49,4.04,6.04,6.22,6.09,33.89.4,67.83.27,102.97.27l-14.44-126.52-123.45-.04Zm187.68,0l14.04,125.76h106.61v-125.72l-120.65-.04Zm186.04,0v125.63h104.84l15.55-125.49-120.38-.13Zm160.32,191.42l-15.5,126.34c29.1,0,56.11.27,83.16-.4,2.89,0,7.29-5.02,8.26-8.44,6.31-22.7,11.99-45.58,17.77-68.45,4-16.08,7.64-32.25,11.59-48.86l-105.28-.18Zm-310.96,125.45h86.09v-125.23h-100.22l14.08,125.27m-63.88.89l-14.26-126.34h-99.91c9.42,40.42,18.52,79.96,28.21,119.5,1.67,3.54,5.01,5.99,8.88,6.53,24.88.53,49.8.31,77.25.31m295.45-.49l15.64-125.72h-96.53v125.72h80.89Z"
                fill={`${colour}`}
            />
        </svg>
    </div>
);

export default Cart;
