import React, { memo, useCallback, useEffect, useState } from 'react'
import { useAppState } from "../../contexts/AppContext"
import { isEmpty } from "lodash"
import useCountries from "../../hooks/useCountries"
import { useHistory } from "react-router"
import Dialog from "../dialog/Dialog"
import DialogHeader from "../dialog/DialogHeader"
import DialogContent from "../dialog/DialogContent"
import DialogActions from "../dialog/DialogActions"
import Button from "../buttons/Button"

const BlacklistCountryRedirect: React.FC = () => {
    const [appState] = useAppState()
    const history = useHistory()
    const blacklistCountries = useCountries("blacklist")
    const [showModal, setShowModal] = useState(false)

    const goToHome = useCallback(() => {
        history.push("/")
    }, [history])

    useEffect(
        () => {
            if (!appState?.country || isEmpty(blacklistCountries?.countries)) {
                return
            }

            if (blacklistCountries.countries.some((country: any) => country.CountryCode === appState?.country?.code)) {
                setShowModal(true)
            }
        },
        [appState, blacklistCountries, setShowModal]
    )

    if (!showModal) {
        return null
    }

    return (
        <Dialog size={"small"} showCloseButton={false}>
            <DialogHeader>Unavailable country</DialogHeader>
            <DialogContent>Sorry, Writer’s Toolbox is currently unavailable in your country.</DialogContent>
            <DialogActions align={"center"}>
                <Button type="button" size="sm" onClick={goToHome}>OK</Button>
            </DialogActions>
        </Dialog>
    )
}

export default memo(BlacklistCountryRedirect)
