const border = {
    smallRadius: "3px",
    largeRadius: "9px",
}

const shadow = {
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.3)',
    boxShadow2: '5px 3px 6px 0 rgba(0, 0, 0, 0.3)',
    boxShadow3: '0 3px 6px 0 rgba(0, 0, 0, 0.3)',
    boxShadow4: '9px 0px 6px -5px rgba(0, 0, 0, 0.3)',
    dropShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    boxInsetTopShadow: 'inset 0 5px 6px -5px rgba(0, 0, 0, 0.16)',
}

export default {
    ...border,
    ...shadow,
}
