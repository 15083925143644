import React from "react";
import { css } from "@emotion/core";

import theme from "../../../../theme";

type Props = {
    countryItem: CountryItem,
    setCountryHandler: (countryCode: string) => void,
};

const DropdownOptionItem: React.FC<Props> = ({ countryItem, setCountryHandler }) => {

    const onClickHandler = () => {
        setCountryHandler(countryItem.code);
    };

    const style = css`
        white-space: nowrap;
        cursor: pointer;

        &:hover {
            color: ${theme.colours.curiousBlue};
        }
    `;

    return (
        <li css={style} onClick={onClickHandler}>{countryItem.name}</li>
    );
};

export default DropdownOptionItem;
