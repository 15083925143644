import moment from 'moment';
import { AUTH_REFRESH_LEEWAY, } from "./constants";
import LoginError from './LoginError';
import { API_BASE_URL } from "../../config/config";

export const AUTH_EXPIRY_COOKIE = "X-Auth-Expiry";

/**
 * Should the token be refreshed. Checks if current time is between the after the refresh time. Expiry should
 * also be checked with hasExpired.
 *
 * @returns {boolean|boolean}
 */
export const shouldTokenRefresh: any = () => {
    const expiryCookie = getCookie(AUTH_EXPIRY_COOKIE);
    // can't refresh if we don't have a token
    if (!expiryCookie) return false;

    const expires = moment(new Date(parseInt(expiryCookie)));
    const refreshTime = expires.clone().subtract(AUTH_REFRESH_LEEWAY, 's');
    const now = moment();

    return now.isSameOrAfter(refreshTime);
};

/**
 * Has the token has expired.
 *
 * @returns {boolean}
 */
export const hasTokenExpired: any = () => {
    const expiryCookie = getCookie(AUTH_EXPIRY_COOKIE);
    if (!expiryCookie) return true;

    const expires = moment(new Date(parseInt(expiryCookie)));
    const now = moment();

    return now.isAfter(expires);
};

/**
 * Refresh the token. It sends original token and receives an updated token in the headers.
 *
 * @returns {Promise<null>}
 */
export const refreshToken: any = async () => {
    const response = await fetch(`${API_BASE_URL}/auth/refresh`);

    const data = await response.json();

    if (!data.status) {
        throw new Error('Unable to refresh token');
    }
};

/**
 * Login with username/password.
 *
 * @param {string} email
 * @param {string} password
 *
 * @returns {Promise<void>}
 */
export const login: any = async (email: any, password: any) => {
    const response = await fetch(`${API_BASE_URL}/auth/login`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({ email, password, withoutSession: true, domain: 'wtbox.com' }),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    const data = await response.json();

    // handle a redirect for logging into wrong site
    if (data.redirect) {
        window.location = data.redirect;
    }

    if (!data.status) {
        // error the message
        if (data.message) {
            throw new LoginError(data.message);

            // specific html error message
        } else if (data.htmlMessage) {
            throw new LoginError(data.htmlMessage, 'html', data.link);
        }
    }

    return data;
};

/**
 * Log out. Clears the cookies on the backend.
 *
 * @returns {Promise<void>}
 */
export const logout: any = async () => {
    const response = await fetch(`${API_BASE_URL}/auth/logout`, {
        credentials: 'include',
    });
    return response.json();
};

/**
 * Login with social profile.
 *
 * @param {string} email
 * @param {string} token // Social credential that comes from Google?
 * @param {string} type
 *
 * @returns {Promise<void>}
 */
export const socialLogin: any = async (email: string, token: string, type: string) => {
    const body = { email, token, type, withoutSession: true, domain: 'wtbox.com' };

    const response = await fetch(`${API_BASE_URL}/auth/login`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json'
        }
    });

    const data = await response.json();

    // handle a redirect for logging into wrong site
    if (data.redirect) {
        window.location = data.redirect;
    }

    if (!data.status) {
        // error the message
        if (data.message) {
            throw new LoginError(data.message);

            // specific html error message
        } else if (data.htmlMessage) {
            throw new LoginError(data.htmlMessage, 'html', data.link);
        }
    }
};

export type SocialLoginType = 'google' | 'facebook';

export const isSocialLoginUser = (user: any): boolean => {
    return user?.LastLoginType === 'google' || user?.LastLoginType === 'facebook';
};

/**
 * Need to confirm the social
 *
 * @param {any} user
 *
 * @returns {boolean}
 */
export const needSocialConfirmation: any = (user: any) => {
    return !!(isSocialLoginUser(user) && !user?.IsSocialConfirmed);
};

/**
 * Need to verify the email
 *
 * @param {any} user
 *
 * @returns {boolean}
 */
export const needEmailVerification: any = (user: any) => {
    return !!(user?.LastLoginType === 'password' && !user?.IsEmailVerified);
};

/**
 * Returns all the cookies that are available. The following cookies are not going to be part of this list:
 * - from other sites
 * - HttpOnly cookies are not accessible
 *
 * @returns {{[p: string]: *}}
 */
export const getCookies = (): { [p: string]: any; } => {
    return document.cookie.split(";").reduce((ac, cv) => Object.assign(ac, { [cv.split('=')[0].trim()]: cv.split('=')[1] }), {});
}

export const getCookie = (cookie = "") => {
    return getCookies()[cookie];
}
