// import accountRoutes from '../../screens/account/routes';
import homeRoutes from '../../pages/routes';
import pricingRoutes from '../../pages/pricing-page/routes';
import manageRoutes from '../../pages/management-page/routes';
import offerPageRoutes from '../../pages/offer-page/routes';
import wtbEventsRoutes from '../../pages/wtb-events/routes';
import landingPageRoutes from '../../pages/landing-page/routes';
import blogRoutes from '../../pages/blog/routes';

const routes: any = {
    ...homeRoutes,
    ...pricingRoutes,
    ...manageRoutes,
    ...offerPageRoutes,
    ...wtbEventsRoutes,
    ...landingPageRoutes,
    ...blogRoutes,
};

/**
 * Get a route by its name.
 *
 * @param name
 */
export const route = (name: string) => {
    const route = routes[name] || null;

    if (route && route.route) {
        return route.route;
    } else if (route && route.link) {
        return route.link;
    }

    return null;
};

export type Params = {
    [key:string]: string,
};

export const link = (name: string, params: Params = {}, queryParams: Params = {}): string => {
    let link = routes.hasOwnProperty(name) ? routes[name].link : '/';

    // replace params
    Object.keys(params).forEach((key: string) => link = link.replace('$' + key, params[key]));

    // append query params to link
    if (queryParams) {
        link = appendQueryString(link, queryParams);
    }

    return link;
};

/**
 * Appends the params as a querystring on the url.
 *
 * @param url
 * @param params
 * @return {string}
 */
export function appendQueryString(url: string, params: Params = {}): string {
    let query = Object.keys(params)
        .reduce((str: string, param: string) => {

            let val = encodeURIComponent(params[param] ? params[param] : '');

            return str ?
                `${str}&${param}=${val}` :
                `${param}=${val}`;
        }, '');

    return query ? `${url}?${query}` : url;
}

export const join = (base: string, path: string) => base.endsWith('/') ? base.slice(0, -1) + path : base + path;

export const up = (levels: number) => {
    const levelsArray = window.location.pathname.split("/").filter(str => str !== "");

    if (levelsArray[0] === "wte") levelsArray.splice(0, 1);

    return `/${levelsArray.slice(0, 0 - levels).join("/")}`;
}

export default routes;
