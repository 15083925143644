import React, { memo, useMemo } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { css } from '@emotion/core';
import { Helmet } from "react-helmet";
import PlanSelection from "./components/PlanSelection";
import PaymentSuccess from "./components/PaymentSuccess"
import Auth from "../../core/components/auth/Auth";
import EmailVerification from "../../core/components/auth/EmailVerification";
import WizardPanel from "./components/WizardPanel";
import SocialSignUp from "../../core/components/social/SocialSignUp";
import StripeLoader from "../../core/components/utils/StripeLoader";
import { useAppState } from "../../core/contexts/AppContext";
import { needEmailVerification, needSocialConfirmation } from "../../core/includes/auth";
import { EMAIL_VERIFICATION_URL, SOCIAL_CONFIRMATION_URL, SUCCESS_URL } from "../../core/includes/pricingAuthRedirects";
import { Theme } from "../../theme";
import { route } from "../../core/includes/routes";
import { getSiteTitle } from "../../config/config";
import PageContainer from "../../core/components/PageContainer";

type Props = {
    showBreadcrumb: boolean,
};

const PricingPage: React.FC<Props> = ({ showBreadcrumb }) => {
    const match: any = useRouteMatch();
    const [appState] = useAppState();

    const wizardPanelStages = useMemo(() => {
        if (
            appState?.user &&
            appState?.authorized &&
            (!needEmailVerification(appState?.user) && !needSocialConfirmation(appState?.user))
        ) {
            return [
                {
                    stage: 'select-plan',
                    title: 'SELECT A PLAN',
                    link: `${match.url}/select-plan`,
                },
                {
                    stage: 'payment',
                    title: 'PAYMENT',
                    link: `${match.url}/payment`,
                },
            ];
        }

        return [
            {
                stage: 'select-plan',
                title: 'SELECT A PLAN',
                link: `${match.url}/select-plan`,
            },
            {
                stage: 'auth',
                title: 'REGISTRATION',
            },
            {
                stage: 'payment',
                title: 'PAYMENT',
            },
        ];
    }, [appState, match]);

    return (
        <PageContainer css={pageContainerStyle} showBreadcrumb={showBreadcrumb}>
            <StripeLoader>
                <div css={pricingPage}>
                    <Switch>
                        <Route path={route('pricing/select-plan')} default>
                            <>
                                <Helmet>
                                    <title>{getSiteTitle('Select Plan')}</title>
                                </Helmet>
                                <WizardPanel stages={wizardPanelStages} activeStage={'select-plan'} />
                                <PlanSelection isPayment={false} />
                            </>
                        </Route>
                        <Route path={route('pricing/auth')}>
                            <>
                                <Helmet>
                                    <title>{getSiteTitle('Auth')}</title>
                                </Helmet>
                                <WizardPanel stages={wizardPanelStages} activeStage={'auth'} />
                                <Auth
                                    urls={{
                                        socialConfirmationURL: SOCIAL_CONFIRMATION_URL,
                                        emailVerificationURL: EMAIL_VERIFICATION_URL,
                                        successURL: SUCCESS_URL,
                                    }}
                                />
                            </>
                        </Route>
                        <Route path={route('pricing/email-verification')}>
                            <>
                                <Helmet>
                                    <title>{getSiteTitle('Email Verification')}</title>
                                </Helmet>
                                <WizardPanel stages={wizardPanelStages} activeStage={'auth'} />
                                <EmailVerification />
                            </>
                        </Route>
                        <Route path={route('pricing/social-confirmation')}>
                            <>
                                <Helmet>
                                    <title>{getSiteTitle('Social Confirmation')}</title>
                                </Helmet>
                                <WizardPanel stages={wizardPanelStages} activeStage={'auth'} />
                                <SocialSignUp />
                            </>
                        </Route>
                        <Route path={route('pricing/payment')}>
                            <>
                                <Helmet>
                                    <title>{getSiteTitle('Payment')}</title>
                                </Helmet>
                                <WizardPanel stages={wizardPanelStages} activeStage={'payment'} />
                                <PlanSelection isPayment={true} />
                            </>
                        </Route>
                        <Route path={route('pricing/payment-successful')}>
                            <>
                                <Helmet>
                                    <title>{getSiteTitle('Payment Success')}</title>
                                </Helmet>
                                <PaymentSuccess />
                            </>
                        </Route>
                    </Switch>
                </div>
            </StripeLoader>
        </PageContainer>
    );
};

const pageContainerStyle = css`
    overflow: visible;
`;

const pricingPage = (theme: Theme) => css`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: ${theme.sizes.pageBodyMarginBottom};
    color: ${theme.colours.grey[900]};
    padding-bottom: 30px;

    ${theme.breakpoints.up('lg')} {
        max-width: 840px;
        margin: 0 auto;
    }

    ${theme.breakpoints.only('md')} {
        padding: 0 80px;
    }

    ${theme.breakpoints.only('sm')} {
        padding: 0 34px;
    }

    ${theme.breakpoints.down('xs')} {
        padding: 0 20px;
    }
`;

export default memo(PricingPage);
