import React from 'react';
import { Theme } from "../../../theme";
import { css, SerializedStyles } from "@emotion/core";

type Props = {
    isVisible: boolean,
    message?: string | null,
    customStyle?: SerializedStyles,
    children?: React.ReactNode,
    className?: string,
};

const ErrorMessage: React.FC<Props> = ({ isVisible, message, customStyle, children, className }) => (
    <>
        {isVisible && (
            <div id={"errorMessage"} css={style(customStyle)} className={className}>
                <span>{message || children}</span>
            </div>
        )}
    </>
);

const style = (customStyle?: SerializedStyles) => (theme: Theme) => css`
    display: flex;
    width: 100%;
    margin-top: 10px;
    background-color: ${theme.colours.red['50']};
    border: 1px solid ${theme.colours.crimson};
    border-radius: ${theme.borderAndShadow.smallRadius};
    color: ${theme.colours.crimson};

    ${customStyle};

    span {
        margin: 10px;
    }
`;

export default ErrorMessage;
