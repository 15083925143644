import React from "react";

import BurgerNavHeaderMenuContainer from "./BurgerNavHeaderMenuContainer";
import BurgerNavHeaderMenuChevron from "./BurgerNavHeaderMenuChevron";
import BurgerNavHeaderMenuTitle from "./BurgerNavHeaderMenuTitle";

type Props = {
    headerMenu: StandardPage,
    currentURLSegment?: string,
    selectedHeaderMenu?: StandardPage,
    selectedSegments?: string[],
    getSubMenu: Function,
    setSubMenu: Function,
    closeMenuHandler: Function,
    wasBurgerMenuClicked: boolean,
};

const BurgerNavHeaderMenuBox: React.FC<Props> = ({
    headerMenu,
    currentURLSegment = "",
    selectedHeaderMenu = undefined,
    selectedSegments = undefined,
    getSubMenu,
    setSubMenu,
    closeMenuHandler,
    wasBurgerMenuClicked,
}) => {
    const submenu = getSubMenu(headerMenu);
    const hasSubMenu = submenu?.standardPage && (
        submenu.standardPage.length > 1 || (submenu.standardPage.length === 1 && submenu.standardPage[0].showInMenus)
    );

    return (
        <BurgerNavHeaderMenuContainer
            headerMenu={headerMenu}
            currentURLSegment={currentURLSegment}
            selectedHeaderMenu={selectedHeaderMenu}
            selectedSegments={selectedSegments}
            hasSubMenu={hasSubMenu}
            setSubMenu={setSubMenu}
            closeMenuHandler={closeMenuHandler}
            wasBurgerMenuClicked={wasBurgerMenuClicked}
        >
            <BurgerNavHeaderMenuTitle>{headerMenu.title}</BurgerNavHeaderMenuTitle>

            {hasSubMenu && (
                <BurgerNavHeaderMenuChevron />
            )}
        </BurgerNavHeaderMenuContainer>
    );
};

export default BurgerNavHeaderMenuBox;
