import React from 'react';
import { css } from '@emotion/core';

import { Theme } from "../../../theme";
import WizardOption from './WizardOption'

type Stage = {
    stage: string,
    title: string,
    link?: string,
};

type Props = {
    stages: Array<Stage>,
    activeStage: string,
};

const WizardPanel: React.FC<Props> = ({stages, activeStage}) => {
    const currentIndex = stages.findIndex((value) => value.stage === activeStage);
    const currentIsLast = currentIndex === stages.length - 1;

    return (
        <div css={wizardPanel(currentIsLast)}>
            {stages.map((stage, index) => (
                <React.Fragment key={stage.title} >
                    <WizardOption
                        progress={getOptionProgress(index, currentIndex)}
                        stageNumber={index + 1}
                        isLastStage={index === stages.length - 1}
                        stageText={stage.title}
                        link={stage.link}
                        totalLength={stages.length}
                    />
                </React.Fragment>
            ))}
        </div>
    );
};

const getOptionProgress = (index: number, currentIndex: number): string => {
    if (index < currentIndex) return 'done';
    if (index === currentIndex) return 'doing';
    return 'todo';
};

const wizardPanel = (currentIsLast: boolean)=> (theme: Theme) => css`
    display: flex;
    margin-top: 17px;
    width: 100%;
    height: 36px;
    border-radius: 3px;
    background-color: ${currentIsLast ? theme.colours.denim : theme.colours.curiousBlue};

    ${theme.breakpoints.down('md')} {
        max-width: 510px;
    }
`;

export default WizardPanel;
