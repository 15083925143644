import React, {useEffect} from 'react';
import { css } from '@emotion/core';
import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import { Theme } from '../../../theme';
import useSetState from "../../../core/hooks/useSetState";
import errorParser from "../../../core/includes/errorParser";
import Button from '../../../core/components/buttons/Button';
import Input from '../../../core/components/form/Input';
import { PromotionCode } from "../../../config/graphQLTypes";

type Props = {
    onApplyPromoCode: (promotionCode: PromotionCode | null) => void,
};

type State = {
    promoCode: string,
    isApplied: boolean,
};

const DEFAULT_STATE = {
    promoCode: '',
    isApplied: false,
};

const LOCAL_STORAGE_KEY = 'home_user_promo_code';

const OrderSummaryCode: React.FC<Props> = ({ onApplyPromoCode }) => {
    const [state, setState] = useSetState<State>(DEFAULT_STATE);
    const [getValidPromotionCode, { data, loading, error }] = useLazyQuery(GET_VALID_PROMOTION_CODE);

    useEffect(
        () => {
            if (data && state.isApplied) {
                onApplyPromoCode(data.getValidPromotionCode);
            }
        },
        [data, onApplyPromoCode, state.isApplied]
    );

    useEffect(
        () => {
            if (!loading && data) {
                setState({ isApplied: true });
            }
        },
        [loading, data, setState]
    );

    /**
     * Checks local storage to see if there is a code in there and applies it.
     */
    useEffect(
        () => {
            const promoCode = localStorage.getItem(LOCAL_STORAGE_KEY);

            if (promoCode && promoCode.trim().length > 0 && state.promoCode.trim().length === 0) {
                setState({ promoCode });

                getValidPromotionCode({ variables: { promoCode } });
            }
        },
        [setState, getValidPromotionCode, state.promoCode]
    );

    const onChange = (name: string, value: any) => {
        setState({ isApplied: false });

        if (data && (state.promoCode !== value)) {
            onApplyPromoCode(null);
        }

        localStorage.setItem(LOCAL_STORAGE_KEY, value);

        setState({ promoCode: value });
    };

    const onKeyUp = (e: React.KeyboardEvent) => {
        if (e.keyCode === 13) {
            onButtonClick();
        }
    };

    const onButtonClick = () => {
        getValidPromotionCode({
            variables: {
                promoCode: state.promoCode
            },
        });
    };

    const isCodeValid = state.promoCode.trim().length > 0;

    const isError = error !== undefined;

    return (
        <div css={containerStyle}>
            <div css={headerStyle}>Code</div>
            <div css={rowStyle}>
                <Input
                    css={inputStyle}
                    name="promoCode"
                    onChange={onChange}
                    maxLength={30}
                    value={state.promoCode}
                    onKeyUp={onKeyUp}
                />
                <Button
                    disabled={!isCodeValid}
                    forceLoading={loading}
                    onClick={onButtonClick}
                    size="sm"
                    color={"secondary"}
                >
                    Apply
                </Button>
            </div>
            <div css={rowStyle}>
                {isError && (
                    <span css={messageStyle(isError)}>{ errorParser(error) }</span>
                )}
                {(data?.getValidPromotionCode?.Code === state.promoCode && state.isApplied) && (
                    <span css={messageStyle(false)}>This code has been applied to your order.</span>
                )}
            </div>
        </div>
    );
};

const GET_VALID_PROMOTION_CODE = gql`
    query getValidPromotionCode($promoCode: String!) {
        getValidPromotionCode(PromoCode: $promoCode) {
            id
            Code
            Coupon {
                id
                PercentOff
                Term
            }
        }
    }
`;

const containerStyle = css`
    line-height: 17px;
    padding: 15px 19px 21px 22px;
    display: flex;
    flex-wrap: wrap;
`;

const headerStyle = (theme: Theme) => css`
    font-size: 14px;
    height: 20px;
    line-height: 17px;
    font-weight: ${theme.fonts.weights.base};
    width: 100%;
`;

const inputStyle = css`
    width: 100%;
    margin-right: 5px;
    padding: 0;
    margin-bottom: 5px;

    input {
        text-align: center;
        height: 32px;
        line-height: 32px;
        font-size: 14px;
    }
`;

const rowStyle = css`
    display: flex;
    width: 100%;
`;

const messageStyle = (isError: boolean) => (theme: Theme) => css`
    font-size: 12px;
    line-height: 12px;
    height: 12px;
    font-weight: ${theme.fonts.weights.base};
    color: ${theme.colours.status.success};

    ${isError && css`
        color: ${theme.colours.status.error};
    `};
`;

export default OrderSummaryCode;
