import React from 'react';

import SmallerTm from "../components/smaller-tm/SmallerTm";

type Props = {
    text: string,
}

const TextWithTmConverted: React.FC<Props> = ({ text }) => {
    const splitText: Array<string> = text.split('™');
    const lastSegment = (splitText: Array<string>, index: number) => (splitText.length - 1 === index);

    return (
        <>
            {
                splitText.map((titleSegment, index) => (
                    !lastSegment(splitText, index) ?
                        <React.Fragment key={index}>{ titleSegment }<SmallerTm/></React.Fragment>
                        :
                        <React.Fragment key={index}>{ titleSegment }</React.Fragment>
                ))
            }
        </>
    );
};

export default TextWithTmConverted;
