const BASE_PATH_NZ = 'nz-offer';
const BASE_PATH_WEBBY = 'webby-offer';

const routes = {
    [`${BASE_PATH_NZ}`]: {
        link: `/${BASE_PATH_NZ}`
    },
    [`${BASE_PATH_NZ}/success`]: {
        link: `/${BASE_PATH_NZ}/success`
    },

    [`${BASE_PATH_WEBBY}`]: {
        link: `/${BASE_PATH_WEBBY}`
    },
    [`${BASE_PATH_WEBBY}/success`]: {
        link: `/${BASE_PATH_WEBBY}/success`
    },
}

export default routes;
