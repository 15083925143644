import { useAppState } from "../contexts/AppContext";
import { useEffect } from "react";
import { useHistory } from "react-router";

const useRedirectHomeIfOnRestrictedPage = () => {
    const [appState, setAppState] = useAppState();
    const history = useHistory();

    useEffect(() => {
        if (!appState.authorized && !appState.user) {
            window.location.href = `${window.location.origin}/`;
        }
    }, [appState, setAppState, history]);
}

export default useRedirectHomeIfOnRestrictedPage;
