import Countries from "../core/components/country-dropdown/Countries";

/**
 * Convert a hex number to an integer.
 *
 * @param {string} hex
 * @returns {number}
 */
export const hexToInt = (hex: string) => parseInt(parseInt(hex, 16).toString(10));

/**
 * Returns rgba string for a hex colour. Colour should include hash at front.
 *
 * @param {string} hex
 * @param {number} alpha
 * @returns {string}
 */
export const rgba = (hex: string, alpha: number) => {
    if (!hex || hex.length !== 7) {
        throw Error(`Hex colour must have length of 7, got ${hex}`);
    }

    const rgb = [hex.substring(1, 3), hex.substring(3, 5), hex.substring(5, 7)];

    return `rgba(${hexToInt(rgb[0])}, ${hexToInt(rgb[1])}, ${hexToInt(rgb[2])}, ${alpha})`;
};

/**
 * Returns country name using country code
 *
 * @param {string} countryCode
 * @returns {string}
 */
export const getCountryFromCode = (countryCode: string) => {
    const country = Countries.find(country => country?.code?.toUpperCase() === countryCode?.toUpperCase());
    return country?.label;
}

/**
 * Returns country code using country name
 *
 * @param {string} countryName
 * @returns {string}
 */
export const getCountryCodeFromName = (countryName: string) => {
    const country = Countries.find(country => country?.label?.toUpperCase() === countryName?.toUpperCase());
    return country?.code;
}

/**
 * Convert a country code to its phone area code.
 */
export const countryToPhoneCode = (isoCode?: String) => {
    const country = Countries.find(country => (
        country.code.toUpperCase() === isoCode?.toUpperCase()
    ));
    if (country?.phone !== 'none')
        return country?.phone;
}

/**
 * Convert a phone area code to a country code.
 */
export const phoneToCountryCode = (areaCode?: String) => {
    const country = Countries.find(country => (
        country.phone === areaCode
    ));
    if (country?.code !== 'none')
        return country?.code;
}

const utils = {
    oneLineEllipsis: `
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `,
    hexToInt,
    rgba
};

export const convertToHyphenated = (value: string) => {
    return value.replace(' ', '-').toLowerCase();
};

export default utils;
