import React from 'react';
import { css } from '@emotion/core';
import chevron from "../../../images/icons/chevron.svg";

import { Theme } from "../../../theme";

type Props = {
    dropped: boolean,
    className?: string,
    hasError?: boolean,
    onClick?: () => void,
};

const DropdownChevron: React.FC<Props> = ({ dropped = false, className, hasError = false, onClick,}) => (
    <div css={style(dropped, hasError)} className={className} onClick={onClick}></div>
)

const style = (dropped: boolean, hasError: boolean) => (theme: Theme) => css`
    position: absolute;
    top: 52%;
    right: 0;
    transform: translateY(-50%);
    mask: url(${chevron}) no-repeat center/11px;
    background-color: ${hasError ? theme.colours.crimson : theme.colours.grey[920]};
    width: 20px;
    height: 20px;

    ${dropped && css`
        transform: translateY(-50%) scaleY(-1);
    `}
`;

export default DropdownChevron;
