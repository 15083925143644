import React from "react";
import { css } from "@emotion/core";
import WizardLink from "./WizardLink";

import { Theme } from "../../../theme";

type Props = {
    progress: string,
    stageNumber: number,
    isLastStage: boolean,
    stageText: string,
    link?: string,
    totalLength: number,
};

const WizardOption: React.FC<Props> = ({progress, stageNumber, isLastStage, stageText, link, totalLength }) => (
    <div css={wizardStageStyle(progress, stageNumber === 1, isLastStage, totalLength)}>
        <WizardLink link={link}>
            <div css={stageInfoStyle}>
                <div css={stageNumberContainerStyle(progress)}>
                    <p css={stageNumberStyle}>
                        {stageNumber}
                    </p>
                </div>
                <div css={stageTextStyle}>
                    {stageText}
                </div>
            </div>
        </WizardLink>
    </div>
);

const wizardStageStyle = (progress: string, isFirstStage: boolean, isLastStage: boolean, totalLength:number) => (theme: Theme) => css`
    display: flex;
    justify-content: space-between;
    width: ${(100/totalLength).toFixed(2)}%;
    height: 100%;

    ${isFirstStage && css`
         border-top-left-radius: ${theme.borderAndShadow.smallRadius};
         border-bottom-left-radius: ${theme.borderAndShadow.smallRadius};
    `}

    ${isLastStage && css`
         border-top-right-radius: ${theme.borderAndShadow.smallRadius};
         border-bottom-right-radius: ${theme.borderAndShadow.smallRadius};
    `}

    ${!isLastStage && css`
        &:after {
            content: '';
            position: absolute;
            top: 0;
            height: 36px;
            width: 36px;
            left:100%;
            transform: translateX(-50%) scaleX(${1 / Math.sqrt(2)}) scaleY(${1 / Math.sqrt(2)}) rotate(45deg);
            background-color: ${progress === 'todo' ? theme.colours.curiousBlue : theme.colours.denim};
            border-top: 1px solid white;
            border-right: 1px solid white;
            border-left: 1px solid ${progress === 'todo' ? theme.colours.curiousBlue : theme.colours.denim};;
            border-bottom: 1px solid ${progress === 'todo' ? theme.colours.curiousBlue : theme.colours.denim};;
            box-sizing: border-box;
            z-index: ${theme.zIndex.zIndexOne};
        }
    `}

    background-color: ${progress === 'todo' ? theme.colours.curiousBlue : theme.colours.denim};
    position: relative;

    &:hover {
        cursor: pointer;
        background-color: ${theme.colours.lochmara3};

        &:after {
            background-color: ${theme.colours.lochmara3};
            border-left: 1px solid ${theme.colours.lochmara3};
            border-bottom: 1px solid ${theme.colours.lochmara3};
        }
    }

    &:first-of-type {
        > a > div {
            margin-left: 11px;
        }
    }
`;

const stageInfoStyle = css`
     display: flex;
     margin-left: 25px;
     color: white;
`;

const stageNumberContainerStyle = (progress: string) => (theme: Theme) => css`
    display: flex;
    text-align: center;
    margin: auto 0;
    height: 22px;
    width: 22px;
    background-color: ${progress === 'todo' ? theme.colours.denim : theme.colours.curiousBlue};
    border-radius: 11px;

    ${theme.breakpoints.down('md')} {
        display: none;
    }
`;

const stageTextStyle = (theme: Theme) => css`
    margin: auto 0 auto 15px;
    font-size: 14px;
    z-index: ${theme.zIndex.zIndexTwo};

    ${theme.breakpoints.down('md')} {
        margin-left: 0;
    }

    ${theme.breakpoints.down('xs')} {
        font-size: 12px;
    }
`;

const stageNumberStyle = (theme: Theme) => css`
    margin: auto;
    font-size: 18px;
    font-family: ${theme.fonts.frutiger};
`;

export default WizardOption;
