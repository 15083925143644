import React from 'react';
import { css } from '@emotion/core';

const SmallerTm: React.FC = () => (
    <sup css={tmTitleStyle}>&#8288;TM</sup>
)

const tmTitleStyle = css`
    position: relative;
    top: -0.5ch;
    height: 0;
    font-size: max(38%,6px);
    font-weight: 700;
`;

export default SmallerTm;
