import React, { forwardRef, useRef, useState, useCallback } from "react";
import { css } from "@emotion/core";

import useFocusedImage from "../hooks/useFocusedImage";
import passForwardRef from "../includes/forwardRef";

type Props = {
    image: string | { URL: string, FocusPoint?: [number, number] },
    borderRadius?: string|number,
    backgroundColour?: string,
    onClickHandler?: ({ link, src, backgroundColour, img }: any) => void,
    className?: string,
    useFocusPoint?: boolean,
    initFadeIn?: boolean,
};

const Image = forwardRef<HTMLInputElement, Props>(({
    image,
    borderRadius = 0,
    backgroundColour = "",
    onClickHandler,
    className = "",
    useFocusPoint = false,
    initFadeIn = false,
}, ref) => {
    const imgURL = typeof image === "object" ? image?.URL : image;
    const focus = useFocusPoint && typeof image === "object" && image?.FocusPoint ? image.FocusPoint : [0, 0];
    const imgRef = useRef<any>();

    const [ratio, setRatio] = useState(1);

    const focusedImageRefCallback = useFocusedImage(focus?.[0], focus?.[1] && -focus[1]);

    const onImageClick = useCallback(() => {
        if (onClickHandler) {
            onClickHandler({ imgURL, backgroundColour, img: image });
        }
    }, [onClickHandler, imgURL, backgroundColour, image]);

    const style = css`
        ${useFocusPoint ? css`
            opacity: 0;
            // Should not set width and height for image when using FocusPoint.
            width: unset !important;
            height: unset !important;
        ` : css`
            width: 100%;
            height: 100%;
        `};

        ${borderRadius > 0 && css`
            border-radius: ${borderRadius}px;
        `};

        ${onClickHandler && css`
            cursor: pointer;
        `};

        ${backgroundColour && css`
            background-color: ${backgroundColour};
        `};
    `;

    return (
        <img
            className={className}
            css={style}
            {...useFocusPoint && {
                ref: (theRef: any) => {
                    if (!theRef) return;
                    imgRef.current = theRef;
                    const newRatio = imgRef.current.naturalWidth / imgRef.current.naturalHeight || 1;
                    if (newRatio !== ratio) {
                        setRatio(imgRef.current.naturalWidth / imgRef.current.naturalHeight || 1);
                    }
                    focusedImageRefCallback(theRef);
                    passForwardRef(theRef, ref);

                    if (initFadeIn) {
                        theRef.style.opacity = 0;
                        theRef.removeAttribute("data-fade-in");
                    }

                    // fadein image so it isn"t janky (the official scientific term)
                    setTimeout(() => {
                        if (theRef.style.opacity <= 0 && !theRef.getAttribute("data-fade-in")) {
                            theRef.setAttribute("data-fade-in", "init");
                            theRef.style.transform = "translateZ(0)";
                            // fade in
                            let iterations = 0;
                            let interval: any = null;
                            interval = setInterval(
                                () => {
                                    if (theRef.style.opacity < 1) {
                                        theRef.style.opacity = ++iterations * 0.1;
                                    } else {
                                        clearInterval(interval);
                                        theRef.setAttribute("data-fade-in", "finished");
                                    }
                                },
                                5
                            );
                        }
                    }, Math.floor(Math.random() * 10));
                }
            }}
            src={imgURL}
            alt={imgURL}
            onClick={onImageClick}
            loading="lazy"
        />
    );
});

export default Image;
