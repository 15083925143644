import React, { memo, useMemo } from 'react';
import { css } from '@emotion/core';

import { Theme } from "../../../theme";
import { StripePlan, TRIAL_DAYS } from "../../../core/hooks/useStripePlans";
import { getNicePriceFromTier } from "../../../core/includes/stripePlansHelpers";
import OrderSummarySubscription from "./OrderSummarySubscription";
import OrderSummaryLine from "./OrderSummaryLine";
import OrderSummaryCharge from "./OrderSummaryCharge";
import OrderSummaryFooter from "./OrderSummaryFooter";
import OrderSummaryHeader from "./OrderSummaryHeader";
import moment from "moment";
import OrderSummaryCode from "./OrderSummaryCode";
import { PromotionCode } from "../../../config/graphQLTypes";

type Props = {
    numberOfUsers: string,
    onApplyPromoCode: (promotionCode: PromotionCode | null) => void,
    plan: StripePlan | null,
    planType: string,
    promotionCode: PromotionCode | null,
};

let billingStartDate: string = '';
let discountPriceByInterval: number | null = null;
let discountTotalPrice: string | null = null;
let period: 'monthly' | 'annual' = 'monthly' as 'monthly';
let priceByInterval: number = 0;
let totalPrice: string = '';

const OrderSummary: React.FC<Props> = ({
    numberOfUsers,
    onApplyPromoCode,
    plan,
    planType,
    promotionCode,
}) => {
    useMemo(
        () => {
            if (plan) {
                const price = getNicePriceFromTier(plan, numberOfUsers);

                period = plan.Interval === 'month' ? 'monthly' : 'annual';
                priceByInterval = (plan.Interval === 'month' ? price : price / 12);
                totalPrice = getTotalPrice(priceByInterval, numberOfUsers, plan.Interval);
                billingStartDate = moment().add(TRIAL_DAYS, "days").format("MMM D, YYYY");

                discountPriceByInterval = null;
                discountTotalPrice = null;

                if (promotionCode) {
                    discountPriceByInterval = priceByInterval * (1 - promotionCode.Coupon.PercentOff / 100);
                    discountTotalPrice = getTotalPrice(discountPriceByInterval, numberOfUsers, plan.Interval);
                }
            }
        },
        [promotionCode, numberOfUsers, plan]
    );

    return (
        <div css={containerStyle}>
            <OrderSummaryHeader />
            <OrderSummarySubscription
                billingStartDate={billingStartDate}
                discountPriceByInterval={discountPriceByInterval}
                discountTotalPrice={discountTotalPrice}
                numberOfUsers={numberOfUsers}
                period={period}
                planType={planType}
                priceByInterval={priceByInterval}
                promotionCode={promotionCode}
                totalPrice={totalPrice}
            />
            <OrderSummaryLine />
            <OrderSummaryCode onApplyPromoCode={onApplyPromoCode} />
            <OrderSummaryLine />
            <OrderSummaryCharge />
            <OrderSummaryLine />
            <OrderSummaryFooter currency={plan?.NiceCurrency} />
        </div>
    );
};

const getTotalPrice = (price: number, numberOfUsers: string, interval?: string) => {
    const users = parseInt(numberOfUsers);
    let totalPrice = 0;

    switch(true) {
        case !interval:
        default:
            totalPrice = 0;
            break;
        case interval === 'year':
            totalPrice = (price * 12) * users;
            break;
        case interval === 'month':
            totalPrice = price * users;
            break;
    }

    return totalPrice.toFixed(2);
};

const containerStyle = (theme: Theme) => css`
    width: 280px;
    border: 1px solid ${theme.colours.grey['450']};
    border-radius: 3px;

    ${theme.breakpoints.down('md')} {
        width: 100%;
        margin-top: 12px;
    }
`;

export default memo(OrderSummary);
