import React from "react";
import { css } from "@emotion/core";

import theme from "../../../../theme";

type Props = {
    children: React.ReactNode,
    removePaddingForVerticalMenu?: boolean,
};

const FooterRow: React.FC<Props> = ({
    children,
    removePaddingForVerticalMenu = false,
}) => {
    const style = css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        width: 100%;

        ${theme.breakpoints.up("5xl")} {
            padding: 0 262px;
            width: ${theme.breakpoints.sizes["5xl"]}px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            padding: 0 169px;
            width: ${theme.breakpoints.sizes["3xl"]}px;
        }

        ${theme.breakpoints.only("xxl")} {
            padding: 0 80px;
            width: ${theme.breakpoints.sizes["xxl"]}px;
        }

        ${theme.breakpoints.only("xl")} {
            padding: 0 50px;
            width: ${theme.breakpoints.sizes["xl"]}px;
        }

        ${theme.breakpoints.only("lg")} {
            padding: 0 45px;
            width: ${theme.breakpoints.sizes["lg"]}px;
        }

        ${!removePaddingForVerticalMenu && css`
            ${theme.breakpoints.between("sm", "md")} {
                padding: 0 45px;
            }

            ${theme.breakpoints.down("xs")} {
                padding: 0 20px;
            }
        `}
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default FooterRow;
