import React from 'react';
import Input from "../form/Input";
import SearchableList from "../form/SearchableList";
import {Theme} from "../../../theme";
import { css } from "@emotion/core";

import { OnFieldChange } from "../../hooks/useForm";

type GenericOption = {
    [valueOrLabel: string]: any,
}

type Props = {
    countries: GenericOption[],
    hasError?: boolean,
    onChange: OnFieldChange,
    phoneNumber: string,
    phoneCode: string,
    phoneInputRef?: React.RefCallback<HTMLInputElement> | React.Ref<HTMLInputElement>,
}

const PhoneNumberInput: React.FC<Props> = ({
    countries,
    hasError,
    onChange,
    phoneNumber,
    phoneCode,
    phoneInputRef
}) => {
    return (
        <div css={containerStyle}>
            <Input
                hasError={hasError}
                css={phoneNumberInput}
                placeholder="Phone number"
                name="phone"
                onChange={onChange}
                value={phoneNumber}
                variant='phone number'
                innerRef={phoneInputRef}
                autoComplete='off'
            />
            <SearchableList
                name="phoneCode"
                options={countries}
                value={phoneCode}
                onChange={onChange}
                labelField="Title"
                valueField="CountryCode"
                variant='phone'
                containsSearchBox
                externalInputRef={phoneInputRef}
                phoneCode={phoneCode}
            />
        </div>
    )
};

const containerStyle = css`
    position: relative;
    width: 100%;
    height:100%;
    min-height: 61px;
`;

const phoneNumberInput = (theme: Theme) => css`
    margin-top: 0;
    box-sizing: border-box;
    width: 100%;
    font-size: ${theme.forms.label.fontSize};
`;

export default PhoneNumberInput;
