import React from "react";
import { css } from "@emotion/core";

import theme from "../../../../theme";
import BurgerNavSmallSubMenuTitle from "./BurgerNavSmallSubMenuTitle";
import { sendCorporate } from "../../../includes/navigate";

type Props = {
    isHeaderMenu?: boolean,
    menu: StandardPage, // it can be header menu and submenu
    currentURLSegment?: string,
    selectedSegments?: string[],
    closeMenuHandler: Function,
};

const BurgerNavSmallSubMenuBox: React.FC<Props> = ({
    isHeaderMenu = false,
    menu,
    currentURLSegment = '',
    selectedSegments = undefined,
    closeMenuHandler,
}) => {
    const onClickMenuHandler = () => {
        if (menu.isClickable) {
            if (currentURLSegment !== menu.uRLSegment) {
                sendCorporate(menu.uRLSegment);
            }
            closeMenuHandler();
        }
    };

    const style = css`
        display: flex;
        align-items: center;
        padding: 0 65px;
        cursor: pointer;
        background-color: ${theme.colours.white};

        ${(currentURLSegment && currentURLSegment !== menu.uRLSegment) && css`
            background-color: ${theme.colours.gallery};
        `}

        &:not(:last-of-type) {
            border-bottom: 2px solid ${theme.colours.cornflower};
        }

        ${theme.breakpoints.only("md")} {
            height: 72px;
        }

        ${theme.breakpoints.only("sm")} {
            height: 75px;
        }

        ${theme.breakpoints.down("xs")} {
            height: 70px;
        }
    `;

    return (
        (!isHeaderMenu || (isHeaderMenu && menu.isClickable)) ? (
            <div css={style} onClick={onClickMenuHandler}>
                <BurgerNavSmallSubMenuTitle
                    isHeaderMenu={isHeaderMenu}
                    subMenu={menu}
                    selectedSegments={selectedSegments}
                >
                    {menu.title}
                </BurgerNavSmallSubMenuTitle>
            </div>
        ) : null
    );
};

export default BurgerNavSmallSubMenuBox;
