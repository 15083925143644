import React, { useEffect} from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from "react-router";

const ScrollToTop: React.FC<RouteComponentProps> = ({ history, children }) => {
    useEffect(() => {
        const listenAndScroll = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            listenAndScroll();
        }
    }, [history]);

    return <>{children}</>
};

export default withRouter(ScrollToTop);
